import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SettingsService {
  constructor(private http: HttpClient) {}
  getSettings(): Observable<any> {
    let params = {};
    return this.http.get(environment.apiBaseUrl + "/api/admin/settings", {
      params,
    });
  }
  getSettingsGroup(group: String): Observable<any> {
    let params = {};
    return this.http.get(
      environment.apiBaseUrl + "/api/admin/settings/group/" + group,
      { params }
    );
  }

  getSettingByName(name: String): Observable<any> {
    return this.http.get(
      environment.apiBaseUrl + "/api/admin/settings/" + name
    );
  }

  updateGroup(settings): Observable<any> {
    return this.http.put(
      environment.apiBaseUrl + "/api/admin/settings/bulkUpdate",
      { options: settings }
    );
  }

  getMargins(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + "/api/admin/margins");
  }
  updateMargins(margins): Observable<any> {
    return this.http.put(
      environment.apiBaseUrl + "/api/admin/margins/bulkUpdate",
      { margins }
    );
  }
  postMargin(margin): Observable<any> {
    return this.http.post(environment.apiBaseUrl + "/api/admin/margins/", {
      rate: margin.rate,
      min: parseFloat(margin.min),
      max: parseFloat(margin.max),
    });
  }
  deleteMargin(id: Number): Observable<any> {
    return this.http.delete(
      environment.apiBaseUrl + "/api/admin/margins/" + id
    );
  }

  getShippings(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + "/api/admin/shippings");
  }
  updateShippings(thresholds): Observable<any> {
    return this.http.put(
      environment.apiBaseUrl + "/api/admin/shippings/bulkUpdate",
      { thresholds }
    );
  }
  postShipping(shipping): Observable<any> {
    return this.http.post(environment.apiBaseUrl + "/api/admin/shippings/", {
      threshold: shipping.threshold,
      price: shipping.price,
    });
  }
  deleteShipping(id: Number): Observable<any> {
    return this.http.delete(
      environment.apiBaseUrl + "/api/admin/shippings/" + id
    );
  }

  getTemplates(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + "/api/admin/templates");
  }
  updateTemplate(template): Observable<any> {
    return this.http.put(
      environment.apiBaseUrl + "/api/admin/templates/" + template.id,
      {
        name: template.name,
        title: template.title,
        content: template.content,
      }
    );
  }
}
