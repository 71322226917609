import { Component, OnInit } from "@angular/core";
import { ProductsService } from "src/app/services/products.service";
import { SalesService } from "src/app/services/sales.service";

@Component({
  selector: "app-sales",
  templateUrl: "./sales.component.html",
  styleUrls: ["./sales.component.scss"],
})
export class SalesComponent implements OnInit {
  isSalesLoading = false;
  sales: any = [];
  products: any = [];

  constructor(
    private salesService: SalesService,
    private productsService: ProductsService
  ) {}

  ngOnInit(): void {
    this.isSalesLoading = true;
    this.salesService.getClientSales().subscribe((sales) => {
      this.sales = sales;
      this.isSalesLoading = false;
    });
  }
}
