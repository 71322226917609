import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, ErrorHandler } from '@angular/core';
import { registerLocaleData } from '@angular/common';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from "./material/material.module";
import { AdminLoginComponent } from './components/admin/admin-login/admin-login.component';
import { AdminPanelComponent } from './components/admin/admin-panel/admin-panel.component';
import { HomeComponent } from './components/home/home.component';
import { FormsModule } from '@angular/forms';
import { PageComponent } from './components/page/page.component';
import { RegisterComponent } from './components/page/register/register.component';
import { AuthService } from './services/auth.service';
import { AuthGuard } from './guards/auth.guard';
import { TokenInterceptorService } from './services/token-interceptor.service';
import { AdminGuard } from './guards/admin.guard';
import { AccountComponent, AddressDialog, DeleteDialog, FileDialog, OrderDetailsDialog, PaymentDialog, ReclamationDialog, DeleteUserModal } from './components/page/account/account.component';
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AdminDashboardComponent } from './components/admin/admin-panel/dashboard/dashboard.component';
import { AdminGroupsComponent, CreateGroupDialog } from './components/admin/admin-panel/groups/groups.component';
import { AdminProductsComponent } from './components/admin/admin-panel/products/products.component';
import { AdminSettingsComponent } from './components/admin/admin-panel/settings/settings.component';
import { AdminSalesComponent } from './components/admin/admin-panel/sales/sales.component';
import { AdminOrdersComponent, DeleteOrderModal } from './components/admin/admin-panel/orders/orders.component';
import { AdminClientsComponent } from './components/admin/admin-panel/clients/clients.component';
import { AdminPagesComponent } from './components/admin/admin-panel/pages/pages.component';
import { AdminInvoicesComponent } from './components/admin/admin-panel/invoices/invoices.component';
import { SitemapComponent } from './components/admin/admin-panel/sitemap/sitemap.component';
import { AdminsComponent, ChangePassModal, DeleteModal, AddAdminModal } from './components/admin/admin-panel/admins/admins.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MainComponent } from './components/home/main/main.component';
import { ProductsComponent } from './components/page/products/products.component';
import { GroupComponent } from './components/home/group/group.component';
import { ProductComponent } from './components/home/product/product.component';
import { ResetRequestComponent } from './components/page/reset-request/reset-request.component';
import { ResetComponent } from './components/home/reset/reset.component';
import { EditGroupComponent } from './components/admin/admin-panel/groups/edit-group/edit-group.component'
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { EditSaleComponent } from './components/admin/admin-panel/sales/edit-sale/edit-sale.component';
import { EditProductComponent } from './components/admin/admin-panel/products/edit-product/edit-product.component';
import { CreateSaleComponent } from './components/admin/admin-panel/sales/create-sale/create-sale.component';
import { EditClientComponent, DeleteClientDialog } from './components/admin/admin-panel/clients/edit-client/edit-client.component';
import { NewOrderComponent } from './components/home/new-order/new-order.component';
import * as $ from 'jquery';
import { SalesComponent } from './components/page/sales/sales.component';
import { GroupProductsComponent } from './components/admin/admin-panel/groups/group-products/group-products.component';
import { LeadWithZerosPipe } from './pipes/lead-with-zeros.pipe';
import { OrderDetailsComponent } from './components/admin/admin-panel/orders/order-details/order-details.component';
import localePl from '@angular/common/locales/pl';
import { AuthErrorHandler } from './authErrorHandler';
import { VariantsComponent } from './components/admin/admin-panel/products/variants/variants.component';
import { BannersComponent, EditBannerComponent, ImgBannerComponent, DeleteBannerDialog } from './components/admin/admin-panel/banners/banners.component';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { ReclamationsComponent, RecComment } from './components/admin/admin-panel/reclamations/reclamations.component';
import { ClientJSComponent } from './components/admin/admin-panel/orders/order-details/client-js/client-js.component';
import { SummaryComponent } from './components/admin/admin-panel/summary/summary.component';
registerLocaleData(localePl);
@NgModule({
    declarations: [
        AppComponent,
        AdminLoginComponent,
        AdminPanelComponent,
        AdminSalesComponent,
        HomeComponent,
        PageComponent,
        RegisterComponent,
        AccountComponent,
        SafeHtmlPipe,
        AdminDashboardComponent,
        AdminGroupsComponent,
        AdminProductsComponent,
        AdminSettingsComponent,
        AdminClientsComponent,
        AdminOrdersComponent,
        AdminPagesComponent,
        AdminInvoicesComponent,
        SitemapComponent,
        AdminsComponent,
        NotFoundComponent,
        MainComponent,
        ProductsComponent,
        GroupComponent,
        ProductComponent,
        ResetRequestComponent,
        ResetComponent,
        EditGroupComponent,
        EditSaleComponent,
        EditProductComponent,
        CreateSaleComponent,
        EditClientComponent,
        DeleteClientDialog,
        ChangePassModal,
        NewOrderComponent,
        AddressDialog,
        DeleteDialog,
        FileDialog,
        PaymentDialog,
        OrderDetailsDialog,
        CreateGroupDialog,
        ReclamationDialog,
        SalesComponent,
        GroupProductsComponent,
        LeadWithZerosPipe,
        OrderDetailsComponent,
        DeleteModal,
        AddAdminModal,
        VariantsComponent,
        BannersComponent,
        EditBannerComponent,
        ImgBannerComponent,
        DeleteBannerDialog,
        PurchaseComponent,
        ReclamationsComponent,
        RecComment,
        ClientJSComponent,
        DeleteUserModal,
        DeleteOrderModal,
        SummaryComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MaterialModule,
        FormsModule,
        CKEditorModule
    ],
    providers: [
        {provide: ErrorHandler, useClass: AuthErrorHandler},
        AuthService,
        AuthGuard,
        AdminGuard,
        {provide: LocationStrategy, useClass: PathLocationStrategy},
        {provide: LOCALE_ID, useValue: 'pl-PL' },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptorService, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
