<div style="padding: 1rem;">
  <h2 mat-dialog-title>Dodawanie admina</h2>
  <div mat-dialog-content>
    <mat-form-field style="width: 100%;">
      <mat-label>Imię</mat-label>
      <input  matInput [(ngModel)]="data.name">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>Email</mat-label>
      <input  matInput [(ngModel)]="data.email">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>Hasło</mat-label>
      <input type='password' matInput [(ngModel)]="data.pass">
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="onNoClick()">Anuluj</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="{name: data.name, email: data.email, pass: data.pass}" cdkFocusInitial>Zatwierdź</button>
  </div>
</div>

