<div style="padding: 1rem;">
  <h2 mat-dialog-title>Zmiana hasła admina {{data.name}}</h2>
  <div mat-dialog-content>
    <mat-form-field style="width: 100%;">
      <mat-label>Nowe hasło</mat-label>
      <input type='password' matInput [(ngModel)]="data.pass">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>Powtórz hasło</mat-label>
      <input type='password' matInput [(ngModel)]="data.pass2">
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="onNoClick()">Anuluj</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="{pass: data.pass, pass2: data.pass2}" cdkFocusInitial>Zatwierdź</button>
  </div>
</div>

