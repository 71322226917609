import { Component, OnInit, Inject } from '@angular/core';
import { GroupsService } from 'src/app/services/groups.service';
import { environment } from '../../../../../environments/environment';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  name: string
  description: string
  guid: string
}

@Component({
  selector: 'app-admin-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss']
})
export class AdminGroupsComponent implements OnInit {

  constructor(private groupsService: GroupsService, private _snackBar: MatSnackBar, public dialog: MatDialog) { }
  name: string
  description: string
  guid: string
  openDialog(): void {
    const dialogRef = this.dialog.open(CreateGroupDialog, {
      width: '75%',
      data: { name: this.name, description: this.description, guid: this.guid }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.groupsService.createGroup({ name: result.name, description: result.description, guid: result.guid }).subscribe(res => {
          this.openSnackBar("Grupa dodana pomyślnie", "OK", 3)
          this.ngOnInit()
        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
      }
    });
  }

  groups: any
  imgUrl = environment.apiBaseUrl;
  isDeleteButtonsActive: boolean = false
  waitingForResponse: boolean = false

  ngOnInit(): void {
    this.waitingForResponse = true
    this.groupsService.getAdminGroups().subscribe(res => {
      this.groups = res
      this.waitingForResponse = false
    }, err => {
      this.waitingForResponse = false
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.groups, event.previousIndex, event.currentIndex);
    this.groups.forEach((item, index) => {
      this.groups[index].position = index
    })
    this.update()
  }

  update() {
    this.groupsService.putBulkUpdate(this.groups).subscribe(res => {
      this.groups = res
      //  console.log(res)
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  toggleActive(id, active, position) {

    this.groups[position].active = active
    this.groupsService.toggleActiveGroup(id, active).subscribe(res => {
      this.openSnackBar("Zaktualizowano grupe pomyślnie", "OK", 3)
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  toggleDeleteButtons() {
    this.isDeleteButtonsActive = !this.isDeleteButtonsActive
  }

  edit() {
    console.log('edit')
  }
  delete(id) {
    this.groupsService.deleteGroup(id).subscribe(res => {
      this.openSnackBar("Usunięto grupę pomyślnie", "OK", 3)
      this.ngOnInit();
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'createGroupDialog.html',
})
export class CreateGroupDialog {

  constructor(
    public dialogRef: MatDialogRef<CreateGroupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
