<mat-tab-group [@.disabled]="true">

  <!-- Zamówienia -->

  <mat-tab label="Zamówienia">
    <ng-template matTabContent>
      <div class="tab-content">
        <mat-accordion>
          <!-- Oczekujące -->
          <mat-expansion-panel expanded="isPanelExpanded">
            <mat-expansion-panel-header>
              <mat-panel-title>Oczekujące ({{ordersArray.o}})</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-paginator style="flex: 1;" [length]="ordersArray.o" [pageSize]="pageSize" [pageIndex]="pageIndexO"
              [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = loadO($event)">
            </mat-paginator>
            <ng-container *ngFor="let order of ordersArray.oczekujace; let i = index;">

              <h2 *ngIf="order.custom_name">Zamówienie {{order.nr}}/{{order.client_id | leadWithZeros: 3}} -
                {{order.custom_name}}</h2>
              <h2 *ngIf="!order.custom_name">Zamówienie {{order.nr}}/{{order.client_id | leadWithZeros: 3}}</h2>

              <hr style="border: 1px solid orange">

              <div class="flexable">
                <!--  -->
                <div style="flex: 1;">
                  <p style="font-weight: 600;">{{order.main_product_name}}</p>
                  <p>Data: {{order.createdAt | date:'yyyy-MM-dd HH:mm'}}</p>
                  <p>Nakład: {{order.naklad}} szt.</p>
                  <p>Tryb: {{order.tryb=="s"?"standard":"ekspres"}}</p>
                  <p *ngIf="order.params">
                    <span *ngFor="let param of Object.keys(JSON.parse(order.params))">
                      {{param}}: {{JSON.parse(order.params)[param]}}<br /></span>
                  </p>
                </div>
                <!--  -->
                <div style="flex: 1;">
                  <p style="font-weight: 600;" *ngIf="order.bonusUsed>0">Zaoszczędzono rabatem</p>
                  <p *ngIf="order.bonusUsed>0">{{order.bonusUsed | currency:"PLN"}}</p>
                  <p style="font-weight: 600;">Do zapłaty netto</p>
                  <p>{{order.cena | currency:"PLN"}}</p>
                  <p style="font-weight: 600;">Do zapłaty brutto</p>
                  <p>{{(order.cena * (1 + (order.vat>0?(order.vat/100):(23/100)))) | currency:"PLN"}}</p>

                  <!-- Sprawdzanie czy są previewy -->
                  <div *ngIf="order.result?.previews.Strony">
                    <div *ngIf="order.result?.previews.length==undefined && order.result?.previews">
                      <div *ngFor="let key of Object.keys(order.result.previews.Strony)">
                        <a href="{{environment.apiBaseUrl}}/printApi{{order.result.previews.Strony[key].img}}"
                          target="_blank"><img
                            src="{{environment.apiBaseUrl}}/printApi{{order.result.previews.Strony[key].thumb}}" /></a>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="order.result?.previews['Okładka']">
                    <!-- Sprawdzanie czy są previewy -->
                    <div>
                      <div>
                        <a href="{{environment.apiBaseUrl}}/printApi{{order.result.previews['Okładka']['1'].img}}"
                          target="_blank"><img
                            src="{{environment.apiBaseUrl}}/printApi{{order.result.previews['Okładka']['1'].thumb}}" /></a>
                      </div>
                    </div>
                  </div>

                </div>

                <!--  -->
                <div style="flex: 1;">
                  <p style="font-weight: 600;">Status pliku</p>
                  <p>{{order.file_status}}</p>
                  <p style="font-weight: 600;">Akcje zamówienia</p>
                  <div>
                    <button mat-icon-button color="primary"
                      (click)="openFileDialog(order.id, order.jsUrl, order.jsId, order.main_product_id, order.group_id, order.naklad, order.file_status)">
                      <mat-icon>insert_drive_file</mat-icon>
                      Pliki zamówienia
                    </button> <br>
                    <button mat-icon-button color="primary" (click)="openDetailsDialog(order)">
                      <mat-icon>list_alt</mat-icon>
                      Szczegóły zamówienia
                    </button> <br>
                    <div *ngIf="order.result">
                      <button mat-icon-button color="primary"
                        *ngIf="order.status_platnosci!='opłacone' && order.status_platnosci!='nieopłacone' && order.file_status != 'czeka na plik' && order.file_status != 'Czeka na akceptacje'"
                        (click)="openPaymentDialog(ordersArray.oczekujace, order.id)">
                        <mat-icon>payment</mat-icon>
                        Płatność online
                      </button> <br
                        *ngIf="order.status_platnosci!='opłacone' && order.status_platnosci!='nieopłacone' && order.file_status != 'czeka na plik' && order.file_status != 'Czeka na akceptacje'">
                      <button mat-icon-button color="primary"
                        *ngIf="account.postponement && order.status_platnosci!='opłacone' && order.status_platnosci!='nieopłacone' && order.file_status != 'czeka na plik' && order.file_status != 'Czeka na akceptacje'"
                        (click)="postpone(order.id)">
                        <mat-icon>schedule</mat-icon>
                        Płatność z odroczeniem
                      </button> <br
                        *ngIf="account.postponement && order.status_platnosci!='opłacone' && order.status_platnosci!='nieopłacone' && order.file_status != 'czeka na plik' && order.file_status != 'Czeka na akceptacje'">
                    </div>
                    <button mat-icon-button style="color: #F44336" (click)="openDeleteOrderDialog(order.id)">
                      <mat-icon>delete</mat-icon>
                      Usuń zamówienie
                    </button>
                  </div>
                </div>
              </div>

            </ng-container>
          </mat-expansion-panel>
          <!-- W realizacji -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>W realizacji ({{ordersArray.w}})</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-paginator style="flex: 1;" [length]="ordersArray.w" [pageSize]="pageSize" [pageIndex]="pageIndexW"
              [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = loadW($event)">
            </mat-paginator>
            <ng-container *ngFor="let order of ordersArray.wRealizacji; let i = index;">

              <h2 *ngIf="order.custom_name">Zamówienie {{order.nr}}/{{order.client_id | leadWithZeros: 3}} -
                {{order.custom_name}}</h2>
              <h2 *ngIf="!order.custom_name">Zamówienie {{order.nr}}/{{order.client_id | leadWithZeros: 3}}</h2>
              <hr style="border: 1px solid orange">

              <div class="flexable">
                <!--  -->
                <div style="flex: 1;">
                  <p style="font-weight: 600;">{{order.main_product_name}}</p>
                  <p>Data: {{order.createdAt | date:'yyyy-MM-dd HH:mm'}}</p>
                  <p>Nakład: {{order.naklad}} szt.</p>
                  <p>Tryb: {{order.tryb=="s"?"standard":"ekspres"}}</p>
                  <p *ngIf="order.params">
                    <span *ngFor="let param of Object.keys(JSON.parse(order.params))">
                      {{param}}: {{JSON.parse(order.params)[param]}}<br /></span>
                  </p>
                </div>
                <!--  -->
                <div style="flex: 1;">
                  <p style="font-weight: 600;" *ngIf="order.bonusUsed>0">Zaoszczędzono rabatem</p>
                  <p *ngIf="order.bonusUsed>0">{{order.bonusUsed | currency:"PLN"}}</p>
                  <p style="font-weight: 600;">Do zapłaty netto</p>
                  <p>{{order.cena | currency:"PLN"}}</p>
                  <p style="font-weight: 600;">Do zapłaty brutto</p>
                  <p>{{(order.cena * (1 + (order.vat>0?(order.vat/100):(23/100)))) | currency:"PLN"}}</p>
                  <!-- Sprawdzanie czy są previewy -->
                  <div *ngIf="order.result?.previews.Strony">
                    <div *ngIf="order.result?.previews.length==undefined && order.result?.previews">
                      <div *ngFor="let key of Object.keys(order.result.previews.Strony)">
                        <a href="{{environment.apiBaseUrl}}/printApi{{order.result.previews.Strony[key].img}}"
                          target="_blank"><img
                            src="{{environment.apiBaseUrl}}/printApi{{order.result.previews.Strony[key].thumb}}" /></a>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="order.result?.previews['Okładka']">
                    <!-- Sprawdzanie czy są previewy -->
                    <div>
                      <div>
                        <a href="{{environment.apiBaseUrl}}/printApi{{order.result.previews['Okładka']['1'].img}}"
                          target="_blank"><img
                            src="{{environment.apiBaseUrl}}/printApi{{order.result.previews['Okładka']['1'].thumb}}" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div style="flex: 1;">
                  <p style="font-weight: 600;">Akcje zamówienia</p>
                  <button mat-icon-button color="primary" (click)="openDetailsDialog(order)">
                    <mat-icon>list_alt</mat-icon>
                    Szczegóły zamówienia
                  </button>
                </div>
              </div>
            </ng-container>
          </mat-expansion-panel>
          <!-- Zrealizowane -->
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>Zrealizowane ({{ordersArray.z}})</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-paginator style="flex: 1;" [length]="ordersArray.z" [pageSize]="pageSize" [pageIndex]="pageIndexZ"
              [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = loadZ($event)">
            </mat-paginator>
            <ng-container *ngFor="let order of ordersArray.zrealizowane; let i = index;">

              <h2 *ngIf="order.custom_name">Zamówienie {{order.nr}}/{{order.client_id | leadWithZeros: 3}} -
                {{order.custom_name}}</h2>
              <h2 *ngIf="!order.custom_name">Zamówienie {{order.nr}}/{{order.client_id | leadWithZeros: 3}}</h2>
              <hr style="border: 1px solid orange">

              <div class="flexable">
                <!--  -->
                <div style="flex: 1;">
                  <p style="font-weight: 600;">{{order.main_product_name}}</p>
                  <p>Data: {{order.createdAt | date:'yyyy-MM-dd HH:mm'}}</p>
                  <p>Nakład: {{order.naklad}} szt.</p>
                  <p>Tryb: {{order.tryb=="s"?"standard":"ekspres"}}</p>
                  <p *ngIf="order.params">
                    <span *ngFor="let param of Object.keys(JSON.parse(order.params))">
                      {{param}}: {{JSON.parse(order.params)[param]}}<br /></span>
                  </p>
                  <div *ngIf="order.result?.status.id == 9 && order.result">
                    <p style="font-weight: 500;">Status: {{order.result.adresy[0].status}}</p>
                    <p>
                      Data nadania: {{order.result.adresy[0].data_nadania | date:'yyyy-MM-d'}}
                    </p>
                    <p>Nr paczki: {{order.result.adresy[0].package_number}}</p>
                    <p *ngIf="order.sledzenie"><a href="{{order.sledzenie}}">Śledź paczkę</a></p>
                  </div>
                </div>
                <!--  -->
                <div style="flex: 1;">
                  <p style="font-weight: 600;" *ngIf="order.bonusUsed>0">Zaoszczędzono rabatem</p>
                  <p *ngIf="order.bonusUsed>0">{{order.bonusUsed | currency:"PLN"}}</p>
                  <p style="font-weight: 600;">Do zapłaty netto</p>
                  <p>{{order.cena | currency:"PLN"}}</p>
                  <p style="font-weight: 600;">Do zapłaty brutto</p>
                  <p>{{(order.cena * (1 + (order.vat>0?(order.vat/100):(23/100)))) | currency:"PLN"}}</p>
                  <!-- Sprawdzanie czy są previewy -->
                  <div *ngIf="order.result?.previews.Strony">
                    <div *ngIf="order.result?.previews.length==undefined && order.result?.previews">
                      <div *ngFor="let key of Object.keys(order.result.previews.Strony)">
                        <a href="{{environment.apiBaseUrl}}/printApi{{order.result.previews.Strony[key].img}}"
                          target="_blank"><img
                            src="{{environment.apiBaseUrl}}/printApi{{order.result.previews.Strony[key].thumb}}" /></a>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="order.result?.previews['Okładka']">
                    <!-- Sprawdzanie czy są previewy -->
                    <div>
                      <div>
                        <a href="{{environment.apiBaseUrl}}/printApi{{order.result.previews['Okładka']['1'].img}}"
                          target="_blank"><img
                            src="{{environment.apiBaseUrl}}/printApi{{order.result.previews['Okładka']['1'].thumb}}" /></a>
                      </div>
                    </div>
                  </div>
                </div>
                <!--  -->
                <div style="flex: 1;">
                  <p style="font-weight: 600;">Akcje zamówienia</p>
                  <button mat-icon-button color="primary" (click)="openDetailsDialog(order)">
                    <mat-icon>list_alt</mat-icon>
                    Szczegóły zamówienia
                  </button><br>
                  <!-- <button mat-icon-button color="primary" *ngIf="order.reclamation==null" (click)="openReclamationDialog(order.id, account.email)">
                    <mat-icon>mood_bad</mat-icon>
                    Złóż reklamację
                  </button> -->

                </div>
              </div>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </ng-template>
  </mat-tab>

  <!-- Faktury -->

  <mat-tab label="Faktury">
    <ng-template matTabContent>
      <div class="tab-content">
        <mat-paginator style="flex: 1;" [length]="invoicesLength" [pageSize]="pageSizeInv" [pageIndex]="pageIndexInv"
          [pageSizeOptions]="pageSizeOptionsInv" (page)="pageEvent = loadInvoices($event)">
        </mat-paginator>
        <table style="width: 100%;">
          <tr>
            <th align="left">Faktura</th>
            <th align="left">Numer zamówienia</th>
            <th align="left">Podgląd</th>
            <th>Data wystawienia</th>
            <th>Wartość Netto</th>

          </tr>
          <tr *ngFor="let invoice of invoices; let i = index" [attr.data-index]="i">
            <td>
              {{ invoice.sygnatura }}
            </td>
            <td>
              {{invoice.order_nr}}/{{invoice.client_id | leadWithZeros: 3}}
            </td>
            <td>
              <a href="{{apiBaseUrl}}/invoices/{{invoice.sygnatura}}.pdf">
                <mat-icon color="primary">description</mat-icon>
              </a>
            </td>
            <td align="center">
              {{ invoice.createdAt | date: "yyyy-MM-dd" }}
            </td>
            <td align="center">
              {{ invoice.pozycja_wartosc_netto | currency:"PLN"}}
            </td>
          </tr>
        </table>
      </div>
    </ng-template>
  </mat-tab>

  <!-- Konto / Profil -->

  <mat-tab label="Konto">
    <ng-template matTabContent>
      <div class="tab-content">

        <h3 *ngIf="account.bonus > 0">
          KONTO BONUSOWE: Posiadasz <span style="font-weight: 500;">{{account.bonus}}zł</span> netto, które możesz
          wykorzystać w dowolnym zamówieniu.
        </h3>

        <h1>{{account.first_name}} {{account.last_name}}</h1>

        <div class="flexable">
          <div style="flex: 1">

            <p>Numer klienta: {{account.id| leadWithZeros: 3}}</p>
            <p>Firma: {{account.company_name}}</p>
            <p>NIP: {{account.nip}}</p>
          </div>

          <div style="flex: 1">
            <p style="font-weight: 900;">Adres:</p>
            <p>{{account.address}}</p>
            <p>{{account.postal_code}} {{account.city}}</p>
          </div>

          <div style="flex: 1">
            <p style="font-weight:900">Kontakt:</p>
            <p>{{account.last_name}} {{account.first_name}}</p>
            <p>{{account.email}}</p>
            <p>tel.: {{account.phone}}</p>
          </div>
        </div>

        <div style="display: flex; justify-content: flex-end; margin-bottom: 1rem;">
          <div>
            <button mat-raised-button color="primary" style="margin-right: 4px" (click)="togglePanel(1)">Edytuj
              dane</button>
            <button mat-raised-button color="primary" style="margin-right: 4px" (click)="togglePanel(2)">Edytuj
              e-mail/hasło</button>
            <button mat-raised-button color="accent" (click)="openDeleteUserModal()">Wyłącz konto</button>
          </div>
        </div>
        <mat-accordion>

          <mat-expansion-panel [expanded]="panelOpenState==1">
            <form style="display: flex; flex-direction: column;" #contactForm="ngForm">
              <br>
              <h2>Dane do faktury</h2>

              <mat-form-field appearance="standard">
                <mat-label>Pełna nazwa firmy</mat-label>
                <input matInput placeholder="Pełna nazwa firmy" [(ngModel)]="account.company_name" name="company_name"
                  required>
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>Nip</mat-label>
                <input matInput placeholder="Nip" [(ngModel)]="account.nip" name="nip" required>
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>Kod pocztowy</mat-label>
                <input matInput placeholder="Kod pocztowy" [(ngModel)]="account.postal_code" name="postal_code"
                  required>
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>Miasto</mat-label>
                <input matInput placeholder="Miasto" [(ngModel)]="account.city" name="city" required>
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>Adres</mat-label>
                <input matInput placeholder="Adres" [(ngModel)]="account.address" name="address" required>
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>Dodatkowy adres e-mail na który zostaną wysłane faktury</mat-label>
                <input matInput placeholder="E-mail" [(ngModel)]="account.email_invoice" name="email_invoice">
              </mat-form-field>

              <br>
              <h2>Dane kontaktowe</h2>

              <mat-form-field appearance="standard">
                <mat-label>Imię</mat-label>
                <input matInput placeholder="Imię" [(ngModel)]="account.first_name" name="first_name" required
                  autocomplete="given-name">
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>Nazwisko</mat-label>
                <input matInput placeholder="Nazwisko" [(ngModel)]="account.last_name" name="last_name" required
                  autocomplete="new-password" autocomplete="family-name">
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>Telefon</mat-label>
                <input matInput placeholder="Telefon" [(ngModel)]="account.phone" name="phone" required
                  autocomplete="tel">
              </mat-form-field>

              <mat-checkbox color="primary" [(ngModel)]="account.marketing_consent" name="marketing_consent"> Zgoda na
                otrzymywanie e-mailem informacji o promocjach i ofertach specjalnych. </mat-checkbox>
              <br> <br>
              <button mat-raised-button [disabled]="!contactForm.valid" color="primary" (click)="updateAccountData()">
                <mat-spinner diameter=38 color='primary' *ngIf=waitingForResponse></mat-spinner>
                {{waitingForResponse ? '' : 'Zapisz'}}
              </button>



            </form>
          </mat-expansion-panel>

          <mat-expansion-panel [expanded]="panelOpenState==2">
            <form style="display: flex; flex-direction: column;" #accForm="ngForm">
              <br>
              <h2>Zmiana e-mail/hasła</h2>
              <mat-form-field appearance="standard">
                <mat-label>E-mail</mat-label>
                <input matInput email #email="ngModel" placeholder="E-mail" [(ngModel)]="account.email" name="email"
                  required autocomplete="email">
              </mat-form-field>
              <mat-error *ngIf="email.touched && email.errors?.email">
                Proszę podać poprawny adres e-mail.
              </mat-error>

              <mat-form-field appearance="standard">
                <mat-label>Hasło</mat-label>
                <input matInput type="password" placeholder="Hasło" [(ngModel)]="newPassword" name="password" required
                  autocomplete="new-password">
              </mat-form-field>

              <mat-form-field appearance="standard">
                <mat-label>Powtórz hasło</mat-label>
                <input matInput type="password" placeholder="Powtórz hasło" #cpassword="ngModel" [(ngModel)]="password2"
                  name="password2" required autocomplete="new-password">
              </mat-form-field>
              <mat-error *ngIf="cpassword.touched && password2 != newPassword">
                Hasła się nie zgadzają.
              </mat-error>

              <button mat-raised-button [disabled]="!accForm.valid || password2 != newPassword" color="primary"
                (click)="updateAccountLoginAndPass()">
                <mat-spinner diameter=38 color='primary' *ngIf=waitingForResponse></mat-spinner>
                {{waitingForResponse ? '' : 'Zapisz'}}
              </button>

            </form>
          </mat-expansion-panel>

        </mat-accordion>


      </div>
    </ng-template>
  </mat-tab>

  <!-- Adresy -->

  <mat-tab label="Książka adresowa">
    <ng-template matTabContent>
      <div class="tab-content flexable">
        <mat-card style="flex:1; margin: 1rem; min-width: 120px;"
          *ngFor="let address of account.addresses; let index=index">
          <mat-card-title>
            Adres {{index+1}}
          </mat-card-title>
          <mat-card-content>
            <hr>
            <div class="flexable" style=" margin-top: 1rem;">
              <div style="flex: 1; margin-right: 1rem;">
                <p>{{address.firstName}}</p>
                <p>{{address.lastName}}</p>
                <p>{{address.companyName}}</p>
              </div>
              <div style="flex: 1; margin-right: 1rem;">
                <p>{{address.address}}</p>
                <p>{{address.postalCode}} {{address.city}}</p>
                <p>{{address.phone}}</p>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <button mat-icon-button (click)="openDialog(address)" color="primary">
              <mat-icon>create</mat-icon>
            </button>
            <button mat-icon-button (click)="openDeleteDialog(address.id)" color="primary">
              <mat-icon>delete</mat-icon>
            </button>
          </mat-card-actions>
        </mat-card>
      </div>

      <button mat-raised-button (click)="togglePanel(3)" color="primary">
        Nowy adres
      </button>
      <br>
      <br>
      <mat-accordion>

        <mat-expansion-panel [expanded]="panelOpenState==3">
          <form style="display: flex; flex-direction: column;" #addressForm="ngForm">
            <br>
            <h2>Nowy Adres</h2>

            <mat-form-field appearance="standard">
              <mat-label>Imię</mat-label>
              <input matInput placeholder="Imię" [(ngModel)]="newAddress.firstName" name="firstName" required>
            </mat-form-field>

            <mat-form-field appearance="standard">
              <mat-label>Nazwisko</mat-label>
              <input matInput placeholder="Nazwisko" [(ngModel)]="newAddress.lastName" name="lastName" required>
            </mat-form-field>

            <mat-form-field appearance="standard">
              <mat-label>Firma</mat-label>
              <input matInput placeholder="Firma" [(ngModel)]="newAddress.companyName" name="companyName" required>
            </mat-form-field>

            <mat-form-field appearance="standard">
              <mat-label>Adres</mat-label>
              <input matInput placeholder="Adres" [(ngModel)]="newAddress.address" name="address" required>
            </mat-form-field>

            <mat-form-field appearance="standard">
              <mat-label>Miasto</mat-label>
              <input matInput placeholder="Miasto" [(ngModel)]="newAddress.city" name="city" required>
            </mat-form-field>

            <mat-form-field appearance="standard">
              <mat-label>Kod pocztowy</mat-label>
              <input matInput placeholder="Kod pocztowy" [(ngModel)]="newAddress.postalCode" name="postal_code"
                required>
            </mat-form-field>

            <mat-form-field class="full-width" appearance="standard">
              <mat-label>Numer telefonu</mat-label>
              <input matInput placeholder="Numer telefonu" [(ngModel)]="newAddress.phone" name="phone" required>
            </mat-form-field>

            <button mat-raised-button (click)="createAddress()" [disabled]="!addressForm.valid" color="primary">
              <mat-spinner diameter=38 color='primary' *ngIf=waitingForResponse></mat-spinner>
              {{waitingForResponse ? '' : 'Zapisz'}}
            </button>

          </form>
        </mat-expansion-panel>
      </mat-accordion>

    </ng-template>
  </mat-tab>
</mat-tab-group>
