import { Component, OnInit } from '@angular/core';
import { GroupsService } from 'src/app/services/groups.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-group',
  templateUrl: './group.component.html',
  styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit {

  constructor(private groupsService: GroupsService, private route:ActivatedRoute) { }

  group = {
    name: "",
    description: "",
    products: []
  }
  imgUrl = environment.apiBaseUrl;

  ngOnInit(): void {
    this.route.params.subscribe(params=>{
      this.groupsService.getSingleGroup(params['guid']).subscribe(res=>{
        this.group = res
      })
    })
    
  }

}
