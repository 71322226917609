<div *ngIf="isLoadingComments || isLoadingOrder">
  <mat-spinner style="margin: 0px auto;"></mat-spinner>
</div>
<div *ngIf="!isLoadingComments && !isLoadingOrder">
  <span *ngIf='!isEditingName'>
    <h2 *ngIf="!data.order.custom_name" mat-dialog-title>
      Szczegóły zamówienia numer: {{data.order.nr}}/{{data.order.client_id| leadWithZeros: 3}}
      <mat-icon class="clickable" (click)="toggleEditName()">edit</mat-icon>
    </h2>
    <h2 *ngIf="data.order.custom_name" mat-dialog-title>
      Szczegóły zamówienia: {{data.order.custom_name}}
      <mat-icon class="clickable" (click)="toggleEditName()">edit</mat-icon>
    </h2>
  </span>
  <span *ngIf='isEditingName'>
    <mat-form-field appearance="standard">
      <mat-label>Własna nazwa zamówienia</mat-label>
      <input matInput [(ngModel)]="data.order.custom_name">
    </mat-form-field>
    <mat-icon class="clickable" color="primary" (click)="saveCustomName()">check</mat-icon>
  </span>


  <div mat-dialog-content>
    <div class="flexable">
      <!--  -->
      <div style="flex: 1; ">
        <p style="font-weight: 500;">
          Produkt: {{data.order.main_product_name}}<br /> Nakład: {{data.order.naklad}} szt.
        </p>

        <p>
          <span *ngFor="let param of Object.keys(params)">
            {{param}}: {{params[param]}}<br /></span>
        </p>
        <!-- Sprawdzanie czy są previewy -->
        <div *ngIf="orderDk?.previews.Strony">
          <div *ngIf="orderDk?.previews.length==undefined && orderDk?.previews">
            <div *ngFor="let key of Object.keys(orderDk.previews.Strony)">
              <a href="{{environment.apiBaseUrl}}/printApi{{orderDk.previews.Strony[key].img}}"
                target="_blank"><img
                  src="{{environment.apiBaseUrl}}/printApi{{orderDk.previews.Strony[key].thumb}}" /></a>
            </div>
          </div>
        </div>
        <div *ngIf="orderDk?.previews['Okładka']">
          <!-- Sprawdzanie czy są previewy -->
          <a href="{{environment.apiBaseUrl}}/printApi{{orderDk.previews['Okładka']['1'].img}}"
            target="_blank"><img
              src="{{environment.apiBaseUrl}}/printApi{{orderDk.previews['Okładka']['1'].thumb}}" /></a>

        </div>

      </div>
      <!--  -->
      <div style="flex: 1;">

        <p>
          Adres dostawy:<br /> {{data.order.adresat_firma}}
          <br /> {{data.order.adresat_nazwisko}} {{data.order.adresat_imie}}<br /> {{data.order.adresat_ulica}},
          {{data.order.adresat_kod_pocztowy}} {{data.order.adresat_miasto}}
          <br /> Telefon: {{data.order.adresat_telefon}}
        </p>
        <p *ngIf="data.order.nadawca_firma || data.order.nadawca_nazwisko">
          Nadawca:<br /> {{data.order.nadawca_firma}}
          <br /> {{data.order.nadawca_nazwisko}} {{data.order.nadawca_imie}}<br /> {{data.order.nadawca_ulica}},
          {{data.order.nadawca_kod_pocztowy}} {{data.order.nadawca_miasto}}
          <br /> Telefon: {{data.order.nadawca_telefon}}
        </p>
      </div>
      <!--  -->
      <div style="flex: 1; ">
        <p style="font-weight: 500;">Daty:</p>
        <p>Zamówienia: {{orderDk.daty["zamowienia"] | date:'yyyy-MM-d HH:mm'}}</p>
        <p>Akecptacji: {{orderDk.daty["akceptacja pliku"] | date:'yyyy-MM-d HH:mm'}}</p>
        <p>Termin realizacji: {{orderDk.daty["termin realizacji"] | date:'yyyy-MM-d HH:mm'}}</p>

        <p style="font-weight: 500;"> Wysyłka:</p>
        <p>Status druku: {{orderDk.status.name}}</p>
        <p>Status wysyłki: {{orderDk.adresy[0].status}}</p>

        <p *ngIf="orderDk.adresy[0].data_nadania">
          Data nadania: {{orderDk.adresy[0].data_nadania | date:'yyyy-MM-d HH:mm'}}
        </p>
        <p *ngIf="!orderDk.adresy[0].data_nadania">
          Planowana data wysylki: {{orderDk.daty["Planowana data wysylki"] | date:'yyyy-MM-d HH:mm'}}
        </p>
        <p>Nr paczki: {{orderDk.adresy[0].package_number}}</p>
        <p *ngIf="orderDk.sledzenie"><a href="{{orderDk.sledzenie}}" target="_blank">Śledź paczkę</a></p>

        <p style="font-weight: 500;">Koszty:</p>
        <p>Wartość netto: {{data.order.cena | currency:"PLN"}}</p>
        <p>
          Wartość brutto:
          {{(data.order.cena * (1 + (data.order.vat>0?(data.order.vat/100):(23/100)))) | currency:"PLN"}}
        </p>
        <div *ngIf="invoice">
          <p style="font-weight: 500;">Faktura:</p>
          <a mat-icon-button color="primary" href="{{apiBaseUrl}}/invoices/{{invoice.sygnatura}}.pdf" target="_blank">
            <mat-icon>description</mat-icon>
            Faktura {{ invoice.sygnatura }}
          </a>
        </div>

      </div>
    </div>

    <div style="display: flex; flex-direction: row;">
      <div style="margin: 1rem; flex: 1">
        <h2>Komentarze do zamówienia ({{comments.length}})</h2>
        <hr />
        <div style="display: flex; flex-direction: column;">
          <div *ngFor="let comment of comments">
            <div>
              <p>
                {{comment.time | date:'yyyy-MM-d HH:mm'}}<br /> {{comment.comment}}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="comments.length > 0">
          <h2>Odpowiedz</h2>
          <button mat-stroked-button
            (click)="addComment('Proszę umożliwić wgranie nowego pliku. Wiadomość automatyczna proszę nie odpowiadać.')">
            Proszę umożliwić wgranie nowego pliku.
          </button><br><br>
          <button mat-stroked-button
            (click)="addComment('Akceptuję mimo uwag proszę drukować. Wiadomość automatyczna proszę nie odpowiadać.')">
            Akceptuję mimo uwag proszę drukować.
          </button>
        </div>
      </div>
      <div *ngIf="!hasReclamation" style="margin: 1rem; flex: 1">
        <button mat-icon-button *ngIf="!reclamationStarted && orderDk.status.id == 9" (click)="startReclamation()">
          <mat-icon>mood_bad</mat-icon>
          Złóż reklamację
        </button>
        <div *ngIf="reclamationStarted">
          <h2>Składanie reklamacji</h2>
          <hr />
          <mat-form-field style="margin-top: 1rem;" class="full-width">
            <mat-label>Powód reklamacji</mat-label>
            <mat-select [(ngModel)]="reason" name="sale.productId">
              <mat-option [value]="defect" *ngFor="let defect of defects">
                {{defect}}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="standard" class="full-width">
            <mat-label>Opis problemu</mat-label>
            <textarea rows="2" matInput placeholder="Opis" [(ngModel)]="description" name="description"></textarea>
          </mat-form-field>
          <button mat-raised-button style="float:right" color="primary" (click)="sendReclamation()">Wyślij
            Reklamacje</button>
        </div>

      </div>
      <div *ngIf="hasReclamation" style="margin: 1rem; flex: 1">
        <h2>Reklamacja</h2>
        <hr />
        <div style="display: flex; flex-direction: column;">
          <div>
            <div>
              <p>Złożona: {{reclamation.createdAt | date:'yyyy-MM-d HH:mm'}}</p>
              <p style="font-weight: 500;">Powód reklamacji: {{reclamation.reason}}</p>
              <p style="white-space: pre-wrap;">Opis: {{reclamation.description}}</p>


              <div *ngFor="let comment of reclamation.reclamation_comments">
                <hr *ngIf="comment.author=='Serwis'" style="border: 1px solid orange">
                <hr *ngIf="comment.author!='Serwis'" style="border: 1px solid #E5E5E5">
                <h3 *ngIf="comment.author=='Serwis'" style="font-weight: 500;">Odpowiedź od serwisu</h3>
                <h3 *ngIf="comment.author!='Serwis'" style="font-weight: 500;">Wystawiony komentarz</h3>
                <p>Data: {{comment.createdAt | date:'yyyy-MM-d HH:mm'}}</p>
                <p style="white-space: pre-wrap;">Treść: {{comment.comment}}</p>
              </div>
            </div>
            <div style="border: 3px dashed #E5E5E5; border-radius: 2px; padding: 1rem">
              <h3 style="font-weight: 500; margin: 0; padding: 0;">Wystaw komentarz</h3>
              <mat-form-field appearance="standard" class="full-width" style="margin: 0; padding: 0;">
                <mat-label>Treść</mat-label>
                <textarea rows="2" matInput placeholder="Treść" [(ngModel)]="newReclamationComment.comment"
                  name="comment" required></textarea>
              </mat-form-field>
              <button mat-raised-button color="primary" (click)="addReclamationComment()">Wyślij</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
