import { Injectable, Injector } from '@angular/core';
import { AuthService } from './auth.service';
import { HttpInterceptor } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class TokenInterceptorService implements HttpInterceptor {

    constructor(private injector: Injector) { }
    intercept(req, next) {
        let authService = this.injector.get(AuthService)
        if (req.url.includes("admin")) {
            if (authService.isAdminLoggedIn()) {
                let tokenizedReq = req.clone(
                    {
                        headers: req.headers.set('authorization', authService.getAdminToken())
                    }
                )
                return next.handle(tokenizedReq)
            }

        } else {
            if (authService.isClientLoggedIn()) {
                let tokenizedReq = req.clone(
                    {
                        headers: req.headers.set('authorization', authService.getClientToken())
                    }
                )
                return next.handle(tokenizedReq)
            }
        }

        return next.handle(req)
    }
}
