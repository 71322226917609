import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PagesRestService {

  constructor(private http: HttpClient) { }

  getSinglePage(guid: String): Observable<any> {
    return this.http.get(environment.apiBaseUrl + "/api/pages/" + guid);
  }
  getAllPages(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + "/api/pages/");
  }
  updatePage(page):Observable<any> {
    return this.http.put(environment.apiBaseUrl + "/api/admin/pages/"+page.id, page);
  }

}
