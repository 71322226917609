import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class CodesService {

  constructor(private http:HttpClient) { }

  getCodes():Observable<any> {
    return this.http.get(environment.apiBaseUrl+"/api/admin/codes");
  }

  createCode(code):Observable<any>{
    return this.http.post(environment.apiBaseUrl+'/api/admin/codes', code)
  }

  updateCode(code):Observable<any>{
    return this.http.put(environment.apiBaseUrl+'/api/admin/codes/'+code.id, code)
  }

  deleteCode(id):Observable<any>{
    return this.http.delete(environment.apiBaseUrl+"/api/admin/codes/"+id)
  }
}
