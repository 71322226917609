import { Component, OnInit } from '@angular/core';
import { SalesService } from 'src/app/services/sales.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CodesService } from 'src/app/services/codes.service';
import { AdminsService } from 'src/app/services/admins.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-admin-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.scss']
})
export class AdminSalesComponent implements OnInit {

  waitingForResponse:boolean = false

  sales: any
  codes: any

  mail: any = {title:"", html: ""}

  isDeleteButtonsActive = {
    sales:false,
    codes:false
  }

  isEditModeActive = false;
  creatingNewCode = false;
  editCode:any = {}
  public Editor = ClassicEditor;

  constructor(private _snackBar: MatSnackBar, private adminsService:AdminsService, private salesService:SalesService, private codesService:CodesService) { }

  ngOnInit(): void {
    this.waitingForResponse = true
    this.salesService.getAdminSales().subscribe(res => {
      this.sales = res
      this.codesService.getCodes().subscribe(codes=>{
        this.codes = codes
        this.waitingForResponse = false
      })
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }

  deleteSale(id){
    this.salesService.deleteSale(id).subscribe(res => {
      if(res==1){
        this.openSnackBar("Usunięto promocję pomyślnie", "OK", 3)
      } else {
        this.openSnackBar(res.message, "OK", 3)
      }

      this.ngOnInit();
    },err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  toggleDeleteButtons(key){
    this.isDeleteButtonsActive[key] = !this.isDeleteButtonsActive[key];
  }

  activateEditMode(code){
    if(this.creatingNewCode){
      this.deactivateEditMode()
    }

    this.editCode = {...code};
    this.editCode.discount = String(this.editCode.discount).replace(".",",")
    this.isEditModeActive = true;
  }

  deactivateEditMode(){
    if(this.creatingNewCode){
      this.codes.splice((this.codes.length-1), 1);
      this.creatingNewCode = false;
    }
    this.isEditModeActive = false;
  }

  updateCode(index){
    this.deactivateEditMode()
    if(typeof(this.editCode._to)=="object"){
      this.editCode._to = new Date( this.editCode._to.getTime() + (60 * 60 * 1000 * 24) - 1000 )
    }
    this.editCode.discount = this.editCode.discount.replace(",",".")
    this.codesService.updateCode(this.editCode).subscribe(res => {
      this.codes[index]=this.editCode
      this.openSnackBar(res.message, "OK", 3)
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  createCode(){
    if(typeof(this.editCode._to)=="object"){
      this.editCode._to = new Date( this.editCode._to.getTime() + (60 * 60 * 1000 * 24) - 1000 )
    }
    this.editCode.discount = this.editCode.discount.replace(",",".")
    this.codesService.createCode(this.editCode).subscribe(res => {
      if(res.message){
        this.openSnackBar(res.message, "OK", 3)
      }else{
        this.codes[this.codes.length-1] = res
        this.creatingNewCode = false;
        this.isEditModeActive = false;
        this.openSnackBar("Utworzono kod", "OK", 3)
      }
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  newCode(){
    this.codes.push({
      code: this.generateCode(7),
      discount: "0"
    })
    this.activateEditMode(this.codes[this.codes.length-1])
    this.creatingNewCode = true;
  }

  generateCode(length) {
    let from = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890"
    let text = "";
    for (let i = 0; i < length; i++) {
      text += from.charAt(Math.floor(Math.random() * from.length));
    }
      return text;
  }

  deleteCode(id){
    this.codesService.deleteCode(id).subscribe(res => {
      if(res==1){
        this.openSnackBar("Usunięto kod pomyślnie", "OK", 3)
      } else {
        this.openSnackBar(res.message, "OK", 3)
      }

      this.ngOnInit();
    },err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  sendMail(){
    this.adminsService.sendMail(this.mail).subscribe(res=>{
      this.openSnackBar(res.message, "OK", 3)
     },err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
}
