import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(private http:HttpClient) { }

  getAllCF(limit?, page?):Observable<any> {
    let params = {}
    if(limit){
      params['limit'] = limit
    }
    if(page){
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl+"/api/admin/invoicescf/", {params, observe: 'response'});
  }
  getPaidCF(limit?, page?):Observable<any> {
    let params = {}
    if(limit){
      params['limit'] = limit
    }
    if(page){
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl+"/api/admin/invoicescf/paid", {params, observe: 'response'});
  }
  getUnPaidCF(limit?, page?):Observable<any> {
    let params = {}
    if(limit){
      params['limit'] = limit
    }
    if(page){
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl+"/api/admin/invoicescf/unpaid", {params, observe: 'response'});
  }
  toggleSeen(id):Observable<any>{
    return this.http.put(environment.apiBaseUrl+"/api/admin/invoicescf/"+id+"/toggleSeen", {});
  }

  getAllInvoices(limit?, page?, skip?):Observable<any> {
    let params = {}
    if(limit){
      params['limit'] = limit
    }
    if(page){
      params['page'] = page
    }
    if(skip){
      params['skip'] = skip
    }
    return this.http.get(environment.apiBaseUrl+"/api/admin/invoices/", {params, observe: 'response'});
  }

  toggleSeenInvoice(id):Observable<any>{
    return this.http.put(environment.apiBaseUrl+"/api/admin/invoices/"+id+"/toggleSeen", {});
  }
}
