<div id="space">

    <form #resetForm="ngForm" class="resetPassword">
        <h1> Resetowanie hasła </h1>
        <h2 >Proszę podać nowe hasło:</h2>
        <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Hasło</mat-label>
            <input matInput type="password" placeholder="Hasło" [(ngModel)]="resetData.password" name="password" required
                autocomplete="new-password">
        </mat-form-field>
        <mat-form-field style="width: 100%" appearance="fill">
            <mat-label>Powtórz hasło</mat-label>
            <input matInput type="password" placeholder="Powtórz hasło" #cpassword="ngModel" [(ngModel)]="password2" name="password2" required autocomplete="new-password">
        </mat-form-field>
        <mat-error *ngIf="cpassword.touched && password2 != resetData.password">
            Hasła się nie zgadzają.
        </mat-error>
        <br>
        <br>
        <button mat-raised-button (click)="passwordReset()" [disabled]="!resetForm.valid || password2 != resetData.password" color="primary">
            <mat-spinner diameter=38 color='primary' *ngIf=waitingForResponse></mat-spinner>
            {{waitingForResponse ? '' : 'Resetuj'}}
        </button>
    </form>
</div>
