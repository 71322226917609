import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GroupsService } from 'src/app/services/groups.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-edit-group',
  templateUrl: './edit-group.component.html',
  styleUrls: ['./edit-group.component.scss']
})
export class EditGroupComponent implements OnInit {

  constructor(private route: ActivatedRoute, private groupsService: GroupsService, private _snackBar: MatSnackBar) { }
  public Editor = ClassicEditor;
  group: any
  images: any
  loadingGroup: boolean = false
  loadingImages: boolean = false
  imgUrl = environment.apiBaseUrl;
  uploadUrl:any
  selectedFile:File = null
  ngOnInit(): void {
    this.loadingGroup = true
    this.route.params.subscribe(params => {
      this.groupsService.getSingleGroup(params['guid']).subscribe(res => {
        this.group = res
        this.loadingGroup = false
      }, err => {
        this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        this.loadingGroup = false
      })
      this.loadImages()
    })
  }
  loadImages(){
    this.groupsService.getGroupImages().subscribe(res => {
      this.images = res
      this.loadingImages = false
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
      this.loadingImages = false
    })
  }

  onFileSelected(event){
    this.selectedFile = event.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);

      reader.onload = event => {
        this.uploadUrl = reader.result;
      };
  }
  uploadPhoto(){
    const fd = new FormData()
    fd.append('img', this.selectedFile, this.selectedFile.name)
    this.groupsService.uploadPhoto(fd).subscribe(res => {
      this.group.icon = "/"+res
      this.loadImages()
    },err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  selectIcon(icon){
    this.group.icon = icon
  }
  update() {
    this.groupsService.updateGroup(this.group).subscribe(res => {
      this.openSnackBar("Zaktualizowano grupę pomyślnie", "OK", 3)
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
}
