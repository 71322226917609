import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProductsService } from "src/app/services/products.service";
import { Title, Meta } from "@angular/platform-browser";
import { SettingsService } from "src/app/services/settings.service";
import { AccountService } from "src/app/services/account.service";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-product",
  templateUrl: "./product.component.html",
  styleUrls: ["./product.component.scss"],
})
export class ProductComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private _router: Router,
    private titleService: Title,
    private metaTagService: Meta,
    private settingsService: SettingsService,
    private productsService: ProductsService,
    private accountService: AccountService,
    private authService: AuthService
  ) { }
  Object = Object;
  product: any;
  products: any = [];
  selectedProductGuid = "";

  selects: any = {};
  waitingForResponse = false;
  foundVariant = false;
  productUnavaliable: boolean = false;

  // Zmienne do utworzenia tabeli
  isTableLoading = false;
  variantId: any;
  variantObject: any = {};
  dataSource: any;
  displayedColumns: string[] = ["naklad", "cena_standard", "cena_ekspres"];

  //Wybrany nakład
  selectedRow: any;

  expressAvaliable: boolean = false;
  expressSelected: boolean = false;

  customWidth: 0;
  customHeight: 0;
  isCustomSelected = false;
  custom = "";
  formats: any = [];
  client;
  isLoggedIn: boolean = false;
  environment = environment;

  productError: any = null;

  ngOnInit(): void {
    this.waitingForResponse = true;
    // pobranie danych klienta
    if (this.authService.isClientLoggedIn()) {
      this.accountService.getClient().subscribe((res) => {
        this.client = res;
        this.isLoggedIn = true;
        // console.log(this.client)
      });
    }

    // pobieranie dane produktu
    this.route.params.subscribe((params) => {
      this.selectedProductGuid = params["guid"];
      this.productsService.getProducts().subscribe((products) => {
        this.products = products.body;

        this.productsService
          .getSingleProduct(params["guid"])
          .subscribe((res) => {
            this.product = res;

            this.settingsService
              .getSettingsGroup("seo")
              .subscribe((response) => {
                let title = this.product.seo_title;
                title = title.replace(
                  "##SEO_NAME_PRODUCT##",
                  this.product.name
                );
                title = title.replace("##SEO_TITLE##", response[0].value);

                let keywords = this.product.seo_keywords;
                keywords = keywords.replace(
                  "##SEO_NAME_PRODUCT##",
                  this.product.name
                );
                keywords = keywords.replace(
                  "##SEO_KEYWORDS##",
                  response[1].value
                );

                let description = this.product.seo_description;
                description = description.replace(
                  "##SEO_NAME_PRODUCT##",
                  this.product.name
                );
                description = description.replace(
                  "##SEO_DESCRIPTION##",
                  response[1].value
                );

                this.titleService.setTitle(title);
                this.metaTagService.updateTag({
                  name: "keywords",
                  content: keywords,
                });
                this.metaTagService.updateTag({
                  name: "description",
                  content: description,
                });
              });

            if (this.product.error) {
              this.productUnavaliable = true;
            } else {
              if (this.product.formaty) {
                this.customHeight = this.product.format.height.min;
                this.customWidth = this.product.format.width.min;
                Object.keys(this.product.formaty).forEach((id) => {
                  this.formats.push(id);
                });
              }
              // console.log(this.product.configs.grupa[this.product.defaultSettings])

              if (this.product.defaultSettings != "") {
                // Object.keys(this.product.configs.rodzic).forEach((parent) => {
                //   Object.keys(this.product.configs.grupa[this.product.defaultSettings]).forEach((defSet) => {
                //     if (Object.keys(this.product.configs.rodzic[parent]).includes(defSet)) {
                //       this.selects[parent] = defSet
                //     }
                //   });
                //   // console.log(this.selects[parent])
                // });
              } else {
                // Object.keys(this.product.configs.rodzic).forEach((parent) => {
                //   this.selects[parent] = Object.entries(
                //     this.product.configs.rodzic[parent]
                //   )[0][0];
                // });
              }

              // console.log(this.selects);
              // this.onChange();
            }

            this.waitingForResponse = false;
          }, err => {
            this.productError = err.error.message
          });
      });
    });
  }

  changeProduct() {
    window.location.href = "./produkt/" + this.selectedProductGuid;
  }

  onChange() {
    console.warn('API disabled')
    // // czary mary
    // let selectedVariants = {};
    // this.custom = this.customWidth + "x" + this.customHeight;
    // this.isCustomSelected = false
    // Object.keys(this.selects).forEach((item) => {
    //   if (this.selects[item] == -1) {
    //     selectedVariants[
    //       Object.keys(this.product.configs.rodzic[item])[0]
    //     ] = Object.keys(this.product.configs.rodzic[item])[0];
    //     this.isCustomSelected = true;
    //   } else {
    //     selectedVariants[this.selects[item]] = this.selects[item];
    //   }
    // });
    // // console.log(selectedVariants)
    // this.foundVariant = false;
    // Object.keys(this.product.configs.grupa).forEach((group) => {
    //   if (
    //     JSON.stringify(this.product.configs.grupa[group]) ===
    //     JSON.stringify(selectedVariants)
    //   ) {
    //     this.variantId = group;
    //     this.dataSource = null;
    //     this.isTableLoading = true;
    //     this.foundVariant = true;
    //   }
    // });
    // if (this.foundVariant) {
    //   // Pobranie nakładu na wariant
    //   this.productsService
    //     .getAmountForVariant(
    //       this.product.id_dk,
    //       this.variantId,
    //       this.isCustomSelected ? this.custom : null
    //     )
    //     .subscribe((res) => {
    //       this.Object.keys(res).forEach((key) => {
    //         let profit = res[key].marza / 100
    //         let profit_dscnt_only = -1
    //         if (this.isLoggedIn) {
    //           if (this.client.discount) {
    //             profit = profit * (1 - this.client.discount / 100);
    //             profit_dscnt_only = profit
    //           }
    //         }

    //         profit = res[key].promocja
    //           ? profit * (1 - res[key].promocjaWartosc / 100)
    //           : profit;

    //         // console.log(res[key].cena_standard * (1+profit))
    //         if (res[key].promocja) {

    //           res[key]["cena_standard_promocja"] =
    //             res[key].cena_standard * (1 + profit);
    //           res[key]["cena_ekspres_promocja"] =
    //             res[key].cena_ekspres * (1 + profit);
    //           res[key]["cena_standard_brutto_promocja"] =
    //             res[key]["cena_standard_promocja"] * (1 + res[key].vat / 100);
    //           res[key]["cena_ekspres_brutto_promocja"] =
    //             res[key]["cena_ekspres_promocja"] * (1 + res[key].vat / 100);

    //           res[key].cena_standard = profit_dscnt_only !== -1 ? res[key].cena_standard * (1 + profit_dscnt_only) : res[key].cena_standard * (1 + (res[key].marza / 100));
    //           res[key].cena_ekspres = profit_dscnt_only !== -1 ? res[key].cena_ekspres * (1 + profit_dscnt_only) : res[key].cena_ekspres * (1 + (res[key].marza / 100));
    //           // console.log(res[key].cena_standard, res[key]["cena_standard_promocja"])
    //         } else {
    //           res[key].cena_standard = res[key].cena_standard * (1 + profit);
    //           res[key].cena_ekspres = res[key].cena_ekspres * (1 + profit);
    //         }

    //         res[key].cena_standard_brutto =
    //           res[key].cena_standard * (1 + res[key].vat / 100);
    //         res[key].cena_ekspres_brutto =
    //           res[key].cena_ekspres * (1 + res[key].vat / 100);
    //       });
    //       this.dataSource = Object.entries(res);
    //       this.selectCell(this.dataSource[0], false);
    //       this.isTableLoading = false;
    //       if (this.dataSource[0][1]["cena_ekspres"] > 0) {
    //         this.expressAvaliable = true;
    //       }
    //       //console.log(this.dataSource)
    //     });
    // }
  }

  selectCell(row, state) {
    if (row[1]["cena_ekspres"] > 0) {
      this.expressAvaliable = true;
      this.selectExpress(state);
    } else {
      this.selectExpress(false);
      this.expressAvaliable = false;
    }
    this.selectedRow = row;
  }

  selectExpress(state) {
    if (this.expressAvaliable) {
      this.expressSelected = state;
    }
  }
}
