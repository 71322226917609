<mat-spinner style="margin: 0px auto;" *ngIf="isLoading"></mat-spinner>

<div style="display: flex; flex-direction: row; flex-wrap: wrap;" *ngIf="!isLoading">
  <div style="flex: 1; margin: 1rem; border-right: 1px solid #E5E5E5; min-width: 200px;">
    <h1>Zamawiany produkt: {{product.name}}</h1>
    <p>Nakład: {{product.variant.naklad}} szt.</p>
    <p *ngFor="let key of Object.keys(product.params)">{{key}}: {{product.params[key]}}</p>
  </div>
  <div style="flex: 3" *ngIf="!isClientLoggedIn">
    <h2>Zaloguj się aby kontynuować składanie zamówienia</h2>
    <mat-form-field class="full-width" appearance="standard">

      <input matInput placeholder="Email" [(ngModel)]="loginData.email" name="email" required>
    </mat-form-field>

    <mat-form-field class="full-width" appearance="standard">

      <input matInput type="password" placeholder="Password" [(ngModel)]="loginData.password" name="password" required>
    </mat-form-field>
    <br>
    <button mat-raised-button color="primary" (click)="login()"> Zaloguj </button>
    <br><br>
    <p>lub utwórz konto <a routerLink="/strona/rejestracja"> tutaj </a></p>
  </div>
  <mat-horizontal-stepper *ngIf="isClientLoggedIn" style="flex: 3" (selectionChange)="onStepChange($event)">

    <mat-step label="Dostawa">

      <div class="tab-content flexable">
        <mat-form-field class="full-width">
          <mat-label>Wybierz adresata z książki adresowej</mat-label>
          <mat-select [(ngModel)]="selectedAddress" (selectionChange)="selectAddress()">
            <mat-option *ngFor="let address of client.addresses; let index = index" [value]="index">
              {{address.firstName}} {{address.lastName}} - {{address.companyName}} - {{address.address}} -
              {{address.city}} {{address.postalCode}} - {{address.phone}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div style="display: flex; flex-direction: row; flex-wrap: wrap;">
        <div style="flex: 1; min-width: 200px ;">
          <h2>Adresat</h2>
          <mat-form-field class="full-width">
            <mat-label>Imię</mat-label>
            <input matInput [(ngModel)]="client.first_name" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Nazwisko</mat-label>
            <input matInput [(ngModel)]="client.last_name" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Firma</mat-label>
            <input matInput [(ngModel)]="client.company_name" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Kod-pocztowy</mat-label>
            <input matInput  [(ngModel)]="client.postal_code" (ngModelChange)="getDeliveryTypes()" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Miasto</mat-label>
            <input matInput [(ngModel)]="client.city" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Ulica</mat-label>
            <input matInput [(ngModel)]="client.address" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Telefon</mat-label>
            <input matInput [(ngModel)]="client.phone" />
          </mat-form-field>
        </div>
        <div style="flex: 1; margin: 1rem; min-width: 200px ;">
          <h2>Nadawca:</h2>
          <mat-checkbox color="primary" [(ngModel)]="customSender" style="margin-right: 1rem">Własny nadawca
          </mat-checkbox>
          <mat-form-field class="full-width">
            <mat-label>Imię</mat-label>
            <input matInput [(ngModel)]="nadawca.imie" [disabled]="!customSender" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Nazwisko</mat-label>
            <input matInput [(ngModel)]="nadawca.nazwisko" [disabled]="!customSender" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Firma</mat-label>
            <input matInput [(ngModel)]="nadawca.firma" [disabled]="!customSender" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Kod-pocztowy</mat-label>
            <input matInput [(ngModel)]="nadawca.kod_pocztowy" [disabled]="!customSender" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Miasto</mat-label>
            <input matInput [(ngModel)]="nadawca.miasto" [disabled]="!customSender" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Ulica</mat-label>
            <input matInput [(ngModel)]="nadawca.ulica" [disabled]="!customSender" />
          </mat-form-field>
          <mat-form-field class="full-width">
            <mat-label>Telefon</mat-label>
            <input matInput [(ngModel)]="nadawca.telefon" [disabled]="!customSender" />
          </mat-form-field>
        </div>
      </div>

      <div *ngIf="!isLoadingDeliveryTypes && !errorDeliveryType && deliveryTypes!=null">
        <mat-form-field class="full-width">
          <mat-label>Sposób dostawy</mat-label>
          <mat-select [(ngModel)]="selectedDeliveryType" (selectionChange)="onSelectionChange($event)">
            <mat-option *ngFor="let deliveryType of deliveryTypes; let index = index" [value]="index">
              {{deliveryType.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <div *ngIf="selectedDeliveryType != null">
          <p>Koszt przesyłki {{deliveryTypes[selectedDeliveryType].price | currency:"PLN"}}</p>
        </div>
      </div>
      <div *ngIf="isLoadingDeliveryTypes && errorDeliveryType">
        <p>{{errorDeliveryType}}</p>
      </div>
      <div *ngIf="isLoadingDeliveryTypes && !errorDeliveryType">
        <mat-spinner style="margin: 0px auto;" ></mat-spinner>
      </div>
      <div>
        <button mat-raised-button style="margin-right: 1rem" color="accent" (click)="_location.back();"> Anuluj </button>
        <button mat-raised-button matStepperNext color="primary" [disabled]="selectedDeliveryType == null"> Kontynuuj </button>
      </div>
    </mat-step>
    <mat-step label="Podsumowanie">
      <div style="text-align: center;" *ngIf="registeringOrder && !niePyklo">
        <mat-spinner style="margin: 5px auto;"></mat-spinner>
        Przygotowujemy środowisko zamówienia. Za chwię będzie możliwość dodania plików.
      </div>

      <div style="text-align: center; color: red" *ngIf="niePyklo">
        Wystąpił problem przy składaniu zamówienia. Proszę spróbować ponownie.<br>
        <button mat-raised-button color="primary" (click)="ngOnInit()">Spróbuj ponownie</button>
      </div>

      <div *ngIf="!registeringOrder && !niePyklo">
        <div *ngIf="selectedDeliveryType != null">
          <!-- <p>Cena produktu netto: {{product.variant.promocja?product.variant.cena_standard_promocja.toFixed(2):product.variant.cena_standard.toFixed(2)}} zł</p>-->
          <!-- <p>Cena produktu brutto: {{product.variant.promocja?product.variant.cena_standard_brutto_promocja.toFixed(2):product.variant.cena_standard_brutto.toFixed(2)}} zł</p> -->

          <!-- <p *ngIf="product.variant.promocja"> Cena produktu netto: {{order.tryb=="s"?product.variant.cena_standard_promocja.toFixed(2):product.variant.cena_ekspres_promocja.toFixed(2)}}</p>
     <p *ngIf="product.variant.promocja"> Cena produktu brutto: {{order.tryb=="s"?product.variant.cena_standard_brutto_promocja.toFixed(2):product.variant.cena_ekspres_brutto_promocja.toFixed(2)}}</p>

     <p *ngIf="!product.variant.promocja"> Cena produktu netto: {{order.tryb=="s"?product.variant.cena_standard.toFixed(2):product.variant.cena_ekspres.toFixed(2)}}</p>
     <p *ngIf="!product.variant.promocja"> Cena produktu brutto: {{order.tryb=="s"?product.variant.cena_standard_brutto.toFixed(2):product.variant.cena_ekspres_brutto.toFixed(2)}}</p> -->

          <p>Sposób dostawy: {{deliveryTypes[selectedDeliveryType].name}}</p>
          <p>Koszt dostawy netto: {{deliveryTypes[selectedDeliveryType].price | currency:"PLN"}}</p>
          <mat-form-field style="margin-right: 5px" appearance="standard">
            <mat-label>Kod promocyjny</mat-label>
            <input matInput [(ngModel)]="kodPromocyjny">
          </mat-form-field>
          <button (click)="isCodeApplicable()" style="margin-right: 1rem;" mat-raised-button>Użyj</button>
          <button (click)="resetCode()" *ngIf="isCodeUsed" mat-raised-button>Anuluj</button>
          <br> <br>
          <div *ngIf="isClientLoggedIn">
            <p>Posiadasz {{this.clientBonus}} punktów bonusowych</p>

            <mat-slider [max]="this.clientBonus < this.priceBeforeBonus ? this.clientBonus : this.priceBeforeBonus"
              [min]=0 [(ngModel)]="bonusPoints" [thumbLabel]=true color="primary">
            </mat-slider>
            <button (click)="applyBonusPoints()" mat-raised-button>Użyj</button>
            <P> Użyto: {{bonusPointsUsed}} punktów</P>
          </div>
          <br>
          <p> Łączny koszt netto: {{doZaplaty_netto | currency:"PLN"}}</p>
          <p> Łączny koszt brutto: {{doZaplaty_brutto | currency:"PLN"}}</p>
          <button mat-raised-button style="margin-right: 1rem" color="accent" matStepperPrevious> Cofnij </button>
          <button (click)="registerOrder()" color="primary" mat-raised-button>Dodaj zamówienie</button>
        </div>

        <div *ngIf="selectedDeliveryType == null">

          <p>Nie wybrano sposobu dostawy</p>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>
</div>
