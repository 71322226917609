<div style="padding: 0 2rem;">
  <div style="display: flex;">
    <mat-paginator style="flex: 1;" [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
      [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = loadReclamations($event)">
    </mat-paginator>
  </div>
</div>
<table style="width: 97%; margin: 1rem;">
  <tr>
    <th>Zamówienie</th>
    <th>Klient</th>
    <th>Powód</th>
    <th>Opis</th>
    <th>Komentarze</th>
  </tr>
  <tr *ngFor="let reclamation of reclamations; let index=index">
    <td>
      <a style="color: black;" routerLink="/admin-panel/orders/{{ reclamation.order_id }}">
        {{ reclamation.order?.nr }}/{{ reclamation.client.id | leadWithZeros: 3 }}
      </a>
    </td>
    <td>
      <a style="color: black;" routerLink="/admin-panel/clients/{{ reclamation.client_id }}">
        {{reclamation.client.first_name}} {{reclamation.client.last_name}}
      </a>
    </td>
    <td>
      <p>{{reclamation.reason}}</p>
    </td>
    <td (click)="openRecComment(index)" class="clickable">
      <p>{{reclamation.description}}</p>
    </td>
    <td style="width: 40px; text-align: center;">
      <span *ngIf="reclamation.recCommentsLength==0">
        <button mat-icon-button [ngStyle]="{'color': 'darkorange'}" (click)="openRecComment(index)">
          <mat-icon>question_answer</mat-icon> {{reclamation.recCommentsLength}}
        </button>
      </span>
      <span *ngIf="reclamation.recCommentsLength!=0">
        <button mat-icon-button
          [ngStyle]="{'color': reclamation.reclamation_comments[reclamation.recCommentsLength-1].author=='Klient'?'orange':'black'}"
          (click)="openRecComment(index)">
          <mat-icon>question_answer</mat-icon> {{reclamation.recCommentsLength}}
        </button>
      </span>
    </td>
  </tr>
</table>
