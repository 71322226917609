<div>
  <div style="display: flex; flex-direction: row;">
    <div>
      <h2 mat-dialog-title>Zapłać on-line za zamówienia.</h2>
    </div>
    <div style="flex-grow: 1;"></div>
    <div>
      <button mat-icon-button mat-dialog-close color="warn">
        <mat-icon>highlight_off</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <h1>Wybierz zamówienia które chcesz opłacić</h1>
    <table style="width: 100%;">
      <tr>
        <th>Numer zamówienia</th>
        <th>Nazwa towaru</th>
        <th>Wartość brutto</th>
      </tr>
      <tr *ngFor="let order of data.orders">
        <td align="center">
          <mat-checkbox (change)="onChange()" [(ngModel)]="order.selected" color="primary">
            {{order.nr}}/{{order.client_id | leadWithZeros: 3}}</mat-checkbox>
        </td>
        <td align="center">
          {{order.main_product_name}}
        <td align="center">
          {{(order.cena * (1 + (order.vat>0?(order.vat/100):(23/100)))) | currency:"PLN"}}
        </td>
      </tr>
      <tr>
        <td>

        </td>
        <td align="right" style="font-weight: 500;">
          Łącznie do zapłaty
        </td>
        <td align="center" style="font-weight: 500;">
          {{finalPrice | currency:"PLN"}}
        </td>
      </tr>
    </table>
    <br>
    <button mat-raised-button color="primary" (click)="pay()">Zapłać</button>
    <div *ngIf="hasError">
      <h2>Wystąpił błąd</h2>
    </div>
  </div>
</div>
