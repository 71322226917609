import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { environment } from 'src/environments/environment';
import { ProductsService } from 'src/app/services/products.service';
import { GroupsService } from 'src/app/services/groups.service';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss']
})
export class EditProductComponent implements OnInit {

  constructor(private _snackBar: MatSnackBar, private productsService: ProductsService, private route: ActivatedRoute, private groupsService: GroupsService) { }
  public Editor = ClassicEditor;
  serverUrl = environment.apiBaseUrl;
  product: any
  images: any
  uploadUrl: any
  selectedFile: File = null
  initailGuid:any
  loadingProduct: boolean = false
  loadingImages: boolean = false
  errorLoading: boolean = false
  ngOnInit(): void {
    this.loadingProduct = true
    this.route.params.subscribe(params => {
      this.productsService.getSingleProductAsAdmin(params['guid']).subscribe(res => {
        this.product = res
        // console.log(this.product)
        this.initailGuid = res.guid
        this.loadingProduct = false
        this.loadImages()
      }, err => {
        this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        this.errorLoading = true
        this.loadingProduct = false
      })

    })
  }
  loadImages(){
    this.groupsService.getGroupImages().subscribe(res => {
      this.images = res
      this.loadingImages = false
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
      this.loadingImages = false
    })
  }
  selectIcon(icon){
    this.product.icon = icon
  }
  onFileSelected(event){
    this.selectedFile = event.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);

      reader.onload = event => {
        this.uploadUrl = reader.result;
      };
  }
  uploadPhoto(){
    const fd = new FormData()
    fd.append('img', this.selectedFile, this.selectedFile.name)
    this.groupsService.uploadPhoto(fd).subscribe(res => {
      this.product.icon = "/"+res
      this.loadImages()
    },err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  update(){
    delete this.product.id
    delete this.product.configs
    this.productsService.updateProduct(this.initailGuid, this.product).subscribe(res => {
      this.openSnackBar("Zaktualizowano produkt pomyślnie", "OK", 3)
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }

}
