<div id="{{data.id}}" class="jsPreflightScript">
  <div style="display: flex; flex-direction: row;">
    <div>
      <h2 mat-dialog-title>Wgrywanie plików</h2>
    </div>
    <div style="flex-grow: 1;"></div>
    <div>
      <button mat-icon-button mat-dialog-close color="warn">
        <mat-icon>highlight_off</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-content>
    <div class="pc-preload">
      <mat-spinner style="margin: 0px auto;"></mat-spinner>
    </div>

    <div class="pc-paternsContainer"></div>
    <h3 style="display: none;">Dodawanie plików:</h3>

    <div class="pc-uploadContainer">
      <p style="margin: 1rem;">
        Pliki prosimy przygotować zgodnie z
        <a href="{{environment.apiBaseUrl+'/printApi/'+amountForVariant.ins}}" target="_blank">instrukcją.</a>
      </p>
      <div>
        <form class="pc-link" #urlForm="ngForm">
          <div style="display: flex; flex-direction: row; margin: 1rem;">
            <mat-form-field style="flex: 6;" apperance="standard">
              <mat-label>URL</mat-label>
              <input matInput required name="url" [(ngModel)]="url"
                placeholder="System obsługuje prywatne adresy URL" />
            </mat-form-field>
            <div style="margin: 5px; flex: 1;">
              <button [disabled]="!urlForm.valid" type="submit" mat-stroked-button>
                Wczytaj plik z url
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="pc-uploader" #jsUploader></div>
      <div class="pc-filesContainer js-UploadedFiles">
        <div class="pc-content"></div>
        <span style="margin: 1rem;"><strong>Wskazówka: </strong>Przenieś pliki do poszczególnych stron
          zachowując ich właściwą ilość i kolejność.</span>
      </div>
    </div>

    <button mat-raised-button color="primary" style="margin: 1rem;" class="pc-auto-assign">
      Przenieś pliki automatycznie
    </button>
    <button mat-raised-button color="salmon" style="margin: 1rem;" class="pc-clear-assign">
      Usuń wszystkie
    </button>

    <div class="pc-moduleContainer clearfix" style="display: none;"></div>


    <div class="pc-preflight-progress">
      <div class="pc-content"></div>
    </div>
    <div *ngIf="isFileAccepted"
      style="border: 2px solid #66BB6A; text-align: center; margin: 1rem; padding: 1rem; border-radius: 2px;">
      <h2>Możesz teraz przejść do płatności</h2>
      <button mat-raised-button style="background-color: #66BB6A; color: white;" mat-dialog-close>Dalej</button>
    </div>
    <div class="pc-global-errors" style="display: none;">
      <h2>Błędy globalne</h2>
      <p>Obsługa została powiadomiona o błędzie i pracuje nad nią.</p>
    </div>
    <br />
    <div class="pc-verification-errors" style="display: none;">
      <table>
        <tr class="errors">
          <th>Błędy:</th>
          <th>Dotyczy:</th>
          <th></th>
        </tr>
        <tbody class="pc-content"></tbody>
      </table>
    </div>
    <br />
    <div class="pc-verification-warnings" style="display: none;">
      <table>
        <tr class="warnings">
          <th>Ostrzeżenia:</th>
          <th>Dotyczy:</th>
          <th></th>
        </tr>

        <tbody class="pc-content"></tbody>
      </table>
    </div>
    <br />
    <div class="pc-verification-info" style="display: none;">
      <table>
        <tr class="info">
          <th>Informacje:</th>
          <th>Dotyczy:</th>
          <th></th>
        </tr>

        <tbody class="pc-content"></tbody>
      </table>
    </div>
    <br />
    <div class="pc-verification-report" style="display: none;">
      <div class="pc-content"></div>
    </div>
    <br />
    <div class="pc-verification-preview " style="display: none;">
      <div class="pc-content"></div>
    </div>
    <div style="margin-top: 1rem; padding-bottom: 1rem">
      <button *ngIf="!isFileAccepted" mat-raised-button class="pc-accept-project"
        style="display: none; background-color: #66BB6A; margin-right: 1rem" (click)="accepted()">
        Akceptuj projekt
      </button>
      <button *ngIf="!isFileAccepted" mat-raised-button color="primary" (click)="checked()" class="pc-reject-project"
        style="display: none;">
        Spróbuj ponownie
      </button>
    </div>
  </div>

  <div mat-dialog-actions>
    <button mat-raised-button class="pc-verify" style="display: none;">
      Prześlij do weryfikacji
    </button>

  </div>
</div>

<div hidden id="qq-template" type="text/template">
  <div class="qq-uploader-selector qq-uploader qq-gallery" qq-drop-area-text="Upuść tutaj pliki">
    <div class="qq-total-progress-bar-container-selector qq-total-progress-bar-container">
      <div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
        class="qq-total-progress-bar-selector qq-progress-bar qq-total-progress-bar"></div>
    </div>
    <div class="drop-area qq-upload-drop-area-selector qq-upload-drop-area">
      <div class="preflight__drop-area-message">
        Upuść tutaj pliki aby wysłać! (ai, bmp, cdr, eps, jpeg, jpg, pdf,
        ps, psd, tif, tiff)
      </div>
      <div class="qq-upload-button-selector qq-upload-button qq-upload-button-hover" title="">
        <div>
          <mat-icon style="font-size: 40px;">backup</mat-icon>
        </div>
      </div>
    </div>
    <span class="qq-drop-processing-selector qq-drop-processing">
      <span class="qq-drop-processing-spinner-selector qq-drop-processing-spinner"></span>
    </span>
    <ul style="list-style: none;" class="qq-upload-list-selector qq-upload-list" role="region" aria-live="polite"
      aria-relevant="additions removals">
      <li>
        <span role="status" class="qq-upload-status-text-selector qq-upload-status-text"></span>
        <div class="qq-progress-bar-container-selector qq-progress-bar-container">
          <div role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"
            class="qq-progress-bar-selector qq-progress-bar"></div>
        </div>
        <span class="qq-upload-spinner-selector qq-upload-spinner"></span>
        <div class="qq-thumbnail-wrapper">
          <img class="qq-thumbnail-selector" qq-max-size="120" qq-server-scale />
        </div>
        <button mat-stroked-button type="button" class="qq-upload-cancel-selector qq-upload-cancel">
          Przerwij
        </button>
        <button mat-stroked-button type="button" class="qq-upload-retry-selector qq-upload-retry">
          <span class="qq-btn qq-retry-icon" aria-label="Retry"></span>
          Ponów
        </button>
        <button mat-stroked-button type="button" class="qq-upload-delete-selector qq-upload-delete">
          Usuń
        </button>

        <div class="qq-file-info">
          <div class="qq-file-name">
            <mat-spinner style="margin: 0px auto;"></mat-spinner>
            <span class="qq-edit-filename-icon-selector qq-edit-filename-icon" aria-label="Edit filename"></span>
          </div>
          <p class="qq-upload-size-selector qq-upload-size"></p>
        </div>
      </li>
    </ul>

    <dialog class="qq-alert-dialog-selector">
      <div class="qq-dialog-message-selector"></div>
      <div class="qq-dialog-buttons">
        <button type="button" class="qq-cancel-button-selector">Close</button>
      </div>
    </dialog>

    <dialog class="qq-confirm-dialog-selector">
      <div class="qq-dialog-message-selector"></div>
      <div class="qq-dialog-buttons">
        <button type="button" class="qq-cancel-button-selector">No</button>
        <button type="button" class="qq-ok-button-selector">Yes</button>
      </div>
    </dialog>

    <dialog class="qq-prompt-dialog-selector">
      <div class="qq-dialog-message-selector"></div>
      <input type="text" />
      <div class="qq-dialog-buttons">
        <button type="button" class="qq-cancel-button-selector">Cancel</button>
        <button type="button" class="qq-ok-button-selector">Ok</button>
      </div>
    </dialog>
  </div>
</div>

<div hidden ngNonBindable id="pc-verification-preview" type="x-tmpl-mustache">
  <div class="jsPreflightPreview">
    <a href="{{image}}" target="_blank" title="{{nr}}{{#errors}}{{Title}}{{/errors}}">
      <img src="{{thumb}}" />
    </a>
  </div>
</div>

<div hidden id="pc-file" type="x-tmpl-mustache">
  <div class="jsPreflightPreview">
    <div ngNonBindable style="height: 100px; text-align: center; margin-bottom: 1rem;">
      <img src="{{preview}}" alt="" class="previewImage" />
    </div>
    <div ngNonBindable class="previewInfo">
      <p>{{name}}</p>
      <p>Stron: {{page}}</p>
    </div>
    <div class="previewActions">
      <button title="Rozdziel" mat-icon-button class="pc-split" color="primary">
        <mat-icon>call_split</mat-icon>
      </button>
      <button mat-icon-button class="pc-delete" color="primary">
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</div>

<div hidden ngNonBindable id="pc-pdf-report" type="x-tmpl-mustache">
  <a href="{{link}}" target="_blank"> Pobierz warstwowy raport w formacie PDF (<strong>Strony</strong>)</a>
</div>

<div hidden id="pc-module" type="x-tmpl-mustache">
  <div>
    <h2 ngNonBindable>{{name}}</h2>
    <div class="pc-pages"></div>
  </div>
</div>

<div hidden id="pc-page" type="x-tmpl-mustache">
  <div class="jsPreflightPreview">
    <div>
      <div style="text-align: center; margin-bottom: 1rem;">
        {{ '{' + '{' + "number" + '}' + '}'}}
      </div>
    </div>
  </div>
</div>

<div hidden id="pc-assigned-file" type="x-tmpl-mustache">
  <div>
    <div ngNonBindable style="height: 100px; text-align: center; margin-bottom: 1rem;">
      <img src="{{preview}}" alt="" class="previewImage" />
    </div>
  </div>
</div>

<div hidden id="pc-verification-problem" type="x-tmpl-mustache">
  <tr ngNonBindable>
    <td>
      <span data-css-tooltip="{{Description}}">{{Title}}</span>
    </td>
    <td>{{Element}}: {{PageLocation}}</td>
    <td>
      {{#KlientCanAccept}} {{#KlientAccept}} Zaakceptowane {{KlientAcceptDate}}
      {{/KlientAccept}} {{^KlientAccept}}
      <button>Akceptuje</button>
      <!-- dowolny input -->
      {{/KlientAccept}} {{/KlientCanAccept}}
    </td>
  </tr>
</div>

<div hidden id="pc-file-options" type="x-tmpl-mustache">
  <div class="options">
    <div class="previewActions">
      <button mat-icon-button title="Obróć w lewo" class="jsPreflightPageRotateLeft" color="primary">
        <mat-icon>rotate_left</mat-icon>
      </button>
      <button mat-icon-button class="jsPreflightPageRotateRight" title="Obróć w prawo" color="primary">
        <mat-icon>rotate_right</mat-icon>
      </button>
      <button mat-icon-button title="Dodanie spadu pracy" class="jsBleed">
        <mat-icon>add_box</mat-icon>
      </button>
      <button mat-icon-button title="Skalowanie do formatu" class="jsScale">
        <mat-icon>launch</mat-icon>
      </button>
      <form hidden ngNonBindable>
        <div>
          {{#rotate}}
          <input type="radio" name="rotate" value="{{.}}" />
          {{#.}} Obrót o {{.}} stopni {{/.}} {{^.}} Brak obrotu {{/.}}
          {{/rotate}}
        </div>
        {{#bleed}}
        <label data-css-tooltip="Dodanie spadu pracy">
          <input type="checkbox" name="bleed" value="1" />
          <span><svg class="dmt-icon">
              <use xlink:href="#ico-plus-square"></use>
            </svg></span>
        </label>
        {{/bleed}} {{#scale}}
        <label data-css-tooltip="Skalowanie do formatu">
          <input type="checkbox" name="scale" value="1" />
          <span><svg class="dmt-icon">
              <use xlink:href="#ico-window-restore"></use>
            </svg></span>
        </label>
        {{/scale}} {{#grey}}
        <label>
          <input type="checkbox" name="grey" value="1" />
          Zamień na skalę szarości.
        </label>
        {{/grey}}
      </form>
    </div>
  </div>
</div>

<div hidden  id="pc-preflight-progress" type="x-tmpl-mustache">
  <mat-progress-bar mode="indeterminate" style="background: #FFDF87"></mat-progress-bar>
  <div ngNonBindable>
    {{progress}} %
  </div>
</div>

<div hidden ngNonBindable id="pc-patern" type="x-tmpl-mustache">
  <a class="multi-pattern state {{code}} {{#active}}active disabled{{/active}}">
    <span class="pattern-main--name">Status</span>
    <span class="pattern-main--status">{{name}}</span>
  </a>
</div>
