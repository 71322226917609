import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-admin-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class AdminProductsComponent implements OnInit {
  // // MatPaginator Inputs
  // length = 100;
  // pageSize = 10;
  // pageSizeOptions: number[] = [5, 10, 15, 20, 30];
  // pageIndex: number = 0
  // // MatPaginator Output
  // pageEvent: PageEvent;

  constructor(private productsService: ProductsService, private _snackBar: MatSnackBar) { }
  products: any
  loadingProducts: boolean = false
  isDeleteButtonsActive: boolean = false

  ngOnInit(): void {
    this.loadProducts()
  }

  toggleActive(guid, active, index){
    this.products[index].active = active
    this.productsService.toggleActiveProduct(guid, active).subscribe(res => {
      this.openSnackBar("Zaktualizowano produkt pomyślnie", "OK", 3)
    },err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  loadProducts(event?) {
    this.loadingProducts = true

    this.productsService.getProductsAsAdmin().subscribe(res => {
      this.products = res.body

      // this.length = res.headers.get('X-Total-Count')
      this.loadingProducts = false
    },
      err => {
        console.log(err)
      })
    return event;
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
}
