<div id="space">
  <form #resetForm="ngForm" class="resetEmail">
    <h1>Resetowanie hasła</h1>
    <mat-form-field style="width: 100%;" appearance="fill">
      <mat-label>Email</mat-label>
      <input
        matInput
        placeholder="E-mail"
        [(ngModel)]="resetData.email"
        name="email"
        required
        autocomplete="email"
      />
    </mat-form-field>
    <br />
    <br />
    <button
      mat-raised-button
      (click)="requestPasswordReset()"
      [disabled]="!resetForm.valid"
      color="primary"
    >
      <mat-spinner
        diameter="38"
        color="accent"
        *ngIf="waitingForResponse"
      ></mat-spinner>
      {{ waitingForResponse ? "" : "Resetuj" }}
    </button>
  </form>
</div>
