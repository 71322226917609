<h1 mat-dialog-title>Edycja</h1>
<div mat-dialog-content *ngIf="!loadingImages">
  <p>Wybrane zdjęcie</p>
      <img
        style=" max-width: 90%;"
        [src]="serverUrl + '/' + this.data.image"
      />

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Wybór ze zdjęć na serwerze
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="productIcons">
            <div
              *ngFor="let image of images"
              (click)="selectIcon('assets/media/' + image)"
              [ngStyle]="{
                border:
                  this.data.image == '/assets/media/' + image
                    ? '2px solid orange'
                    : '2px solid white'
              }"
            >
              <img
                [src]="serverUrl + '/assets/media/' + image"
                style="max-width: 80%; max-height: 128px"
              />
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Anuluj</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Zapisz</button>
</div>
