<h1 mat-dialog-title>Edycja</h1>
<div mat-dialog-content>
  <mat-form-field class="full-width" appearance="standard">
    <mat-label>Link</mat-label>
    <input matInput [(ngModel)]="newLink" required>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Przerwij</button>
  <button mat-button [mat-dialog-close]="newLink" cdkFocusInitial>Zapisz</button>
</div>
