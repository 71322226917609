import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()
export class AuthErrorHandler implements ErrorHandler {

  constructor(private _router: Router) { }

  handleError(error) {
    if (error.status === 401 || error.status === 403) {
      localStorage.removeItem('clientToken')
      window.location.replace("./")
    }
    throw error;
  }
}
