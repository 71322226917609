<div style="display: flex; flex-direction: row; flex-wrap: wrap;">

  <!-- OSTATNIE ZAMOWIENIA -->
  <mat-list class="nz-list" style="flex: 1; max-width: 50%;">
    <mat-list-item class="nz-list-item-head">
      <p>Ostatnie zamówienia</p>
    </mat-list-item>
    <mat-list-item class="nz-list-item" *ngFor="let order of orders">
      <h3 matLine><a routerLink="/admin-panel/orders/{{order.id}}">
          {{order.nr}}/{{order.client_id | leadWithZeros: 3}}</a> - {{order.status}} - {{order.main_product_name }} - {{order.naklad}}szt.
      </h3>
      <p matLine class="company">
        <a routerLink="/admin-panel/clients/{{order.client.id}}">
          {{order.client.first_name}} {{order.client.last_name}} - {{order.client.company_name}}
        </a>

      </p>
      <p matLine>
        {{order.createdAt | date:'yyyy-MM-d HH:mm'}} ({{moment(order.createdAt).fromNow()}})
      </p>
    </mat-list-item>
  </mat-list>

    <!-- OSTATNIE KOMENTARZE -->
    <mat-list class="nz-list" style="flex: 1; max-width: 50%;">
      <mat-list-item class="nz-list-item-head">
        <p>Ostatnie komentarze</p>
      </mat-list-item>
      <mat-list-item class="nz-list-item" *ngFor="let comment of comments">
        <h3 *ngIf="comment.order"  matLine><a routerLink="/admin-panel/orders/{{comment.order_id}}">
            {{comment.order.nr}}/{{comment.order.client_id | leadWithZeros: 3}}</a> {{comment.order.main_product_name }} -
          {{comment.order.naklad}}szt.
        </h3>
        <h3 *ngIf="!comment.order" matLine style="color: red;">ZAMÓWIENIE USUNIĘTE</h3>
        <p matLine class="company">
          {{comment.comment}}
        </p>
        <p matLine>
          {{comment.createdAt | date:'yyyy-MM-d HH:mm'}} ({{moment(comment.createdAt).fromNow()}})
        </p>
      </mat-list-item>
    </mat-list>


</div>

<div style="display: flex; flex-direction: row; flex-wrap: wrap;">

  <!-- OSTATNI KLIENCI -->
  <mat-list class="nz-list" style="flex: 1; max-width: 50%;">
    <mat-list-item class="nz-list-item-head">
      <p>Ostatnio zarejestrowani klienci</p>
    </mat-list-item>
    <mat-list-item class="nz-list-item" *ngFor="let client of clients">
      <h3 matLine> {{client.first_name}} {{client.last_name}} </h3>
      <p matLine class="company">
        {{client.company_name}}
      </p>
      <p matLine>
        {{client.createdAt | date:'yyyy-MM-d HH:mm'}} ({{moment(client.createdAt).fromNow()}})
      </p>
    </mat-list-item>
  </mat-list>

  <!-- OSTATNIe REKLAMACJE -->
  <mat-list class="nz-list" style="flex: 1; max-width: 50%;">
    <mat-list-item class="nz-list-item-head">
      <p>Ostatnie aktualizacje w reklamacjach</p>
    </mat-list-item>
    <mat-list-item class="nz-list-item" *ngFor="let recl of reclamations">
      <h3 matLine>{{recl.client.first_name}} {{recl.client.last_name}} - {{recl.client.company_name}} - <a
          routerLink="/admin-panel/orders/{{recl.order_id}}">Zamówienie: {{recl.order_id}}</a></h3>
      <p matLine >
        <span style="font-weight: 500;">{{recl.reason}} </span>Liczba odpowiedzi: {{recl.reclamation_comments.length}}
      </p>
      <p matLine>
        <span style="font-weight: 500;">Reklamacja zgłoszona:</span> {{recl.createdAt | date:'yyyy-MM-d HH:mm'}}
        ({{moment(recl.createdAt).fromNow()}})
      </p>
      <p matLine>
        <span style="font-weight: 500;">Ostatnia aktualizacja:</span> {{recl.updatedAt | date:'yyyy-MM-d HH:mm'}}
        ({{moment(recl.updatedAt).fromNow()}})
      </p>
    </mat-list-item>
  </mat-list>

</div>

