import { Component, OnInit } from '@angular/core';
import { GroupsService } from 'src/app/services/groups.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  constructor(private groupsService: GroupsService, private _snackBar: MatSnackBar) { }
  groups = []
  imgUrl = environment.apiBaseUrl;
  loadingGroups: boolean
  errorLoading: boolean = false
  ngOnInit(): void {
    this.loadingGroups = true
    this.groupsService.getGroups().subscribe(res => {
      this.groups = res
      this.loadingGroups = false
    },
    err=>{
      this.errorLoading = true
      //this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action,{
      duration: sbDuration * 1000,
    });
  }

}
