<div style="padding: 0 2rem;">
  <div style="display: flex;">
    <mat-paginator
      style="flex: 1;"
      [length]="length"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent = loadOrders($event)"
    >
    </mat-paginator>
  </div>
</div>

<mat-spinner style="margin: 0px auto;" *ngIf="isLoadingOrders"></mat-spinner>
<div *ngIf="!isLoadingOrders" style="padding: 2rem;">
  <table style="width: 100%;">
    <tr>
      <th>Miniaturka</th>
      <th>Data zamówienia</th>
      <th>Zamówienie</th>
      <th>Zamówienie dk</th>
      <th>Klient</th>
      <th>Produkt</th>
      <th>Wartość</th>
      <th>Status</th>
      <th>Status płatności</th>
      <th>Usuń</th>
    </tr>
    <tr *ngFor="let order of orders; let i = index" [attr.data-index]="i">
      <td align="center">
        <img style="width: 100px" src="{{order.thumbnail}}"/>
      </td>
      <td align="center">
        {{ order.createdAt | date: "yyyy-MM-d HH:mm" }}
      </td>
      <td align="center" class="cell">
        <a
          style="color: black;"
          routerLink="/admin-panel/orders/{{ order.id }}"
        >
          {{ order.nr }}/{{ order.client_id | leadWithZeros: 3 }}
        </a>
      </td>
      <td align="center">
        {{ order.order_dk_id }}
      </td>
      <td>
        <a
          style="color: black;"
          routerLink="/admin-panel/clients/{{ order.client_id }}"
        >
          <span style="font-weight: 500;"
            >{{ order.client.company_name }}
          </span>
          {{ order.client.first_name }}
          {{ order.client.last_name }}
        </a>
      </td>
      <td>
        <mat-icon
          color="primary"
          [matTooltipClass]="'my-tooltip'"
          matTooltip="{{ this.parseParams(order.params) }}"
        >
          info
        </mat-icon>
        <span class="product_span">
          {{ order.main_product_name }} {{ order.naklad}} szt.
        </span>
      </td>
      <td align="center">{{ order.cena | currency:"PLN"}}</td>
      <td align="center">
        {{ order.status }}
      </td>
      <td align="center">
        {{ order.status_platnosci }}
      </td>
      <td align="center">
        <button *ngIf="order.status=='oczekujące'" mat-icon-button color="warn" (click)="openDeleteModal(order.id)">
          <mat-icon>delete</mat-icon>
        </button>
      </td>
    </tr>
  </table>
</div>
