<!-- TOOLBAR -->
<mat-toolbar id="navbar" color="accent">
  <mat-toolbar-row>
    <a routerLink="/"><img id="logo" *ngIf="!isSmallLogoShown" src="assets/img/logo.png">
      <img id="logo" *ngIf="isSmallLogoShown" src="assets/img/logoSmall.png"></a>
    <span class="spacer"></span>

    <!-- zalogowany -->
    <button mat-raised-button color="primary" style="min-width: 102px; margin: 0px 10px" *ngIf="auth.isClientLoggedIn()"
      routerLink="/strona/moje_konto">Moje konto</button>
    <button *ngIf="auth.isClientLoggedIn()" style="min-width: 82px;" mat-raised-button color="primary"
      (click)="auth.logoutClient()"> Wyloguj </button>
  </mat-toolbar-row>

</mat-toolbar>
<!-- TOOLBAR END -->

<!-- CONTENT -->
<div class="scrollable">
  <div class="scrollable-limiter">
    <!-- BANER -->
    <div *ngIf="!areBannersLoading">
      <ngu-carousel [inputs]="carouselBanner" [dataSource]="banners">
        <div *nguCarouselDef="let banner;" class="item">

          <a style="width: 100%; overflow: hidden;" href="{{banner.link}}">
            <img class="ban-img" src="{{environment.apiBaseUrl}}/{{banner.image}}" />
          </a>

        </div>
      </ngu-carousel>
    </div>

    <!-- NAVBAR -->
    <mat-toolbar>
      <!-- Mobile  -->
      <mat-toolbar-row *ngIf="isMobile">
        <span class="spacer"></span>
        <button mat-icon-button [matMenuTriggerFor]="navMenu" aria-label="Example icon-button with a menu"
          *ngIf="isMobile">
          <mat-icon>menu</mat-icon>
        </button>

        <mat-menu #navMenu="matMenu" class="myMenu">
          <button *ngIf="auth.isClientLoggedIn()" mat-menu-item routerLink="/strona/moje_konto">Moje Konto</button>
          <button *ngIf="!auth.isClientLoggedIn()" mat-menu-item routerLink="/strona/rejestracja">Rejestracja</button>
          <button *ngFor="let page of pages" mat-menu-item routerLink="{{page.guid}}">{{page.title}}</button>
        </mat-menu>
      </mat-toolbar-row>

      <!-- Desktop  -->
      <mat-toolbar-row *ngIf="!isMobile">
        <span class="spacer"></span>
        <a mat-button *ngIf="auth.isClientLoggedIn()" class="nav-link" routerLink="/strona/moje_konto"
          routerLinkActive="active-link">Moje Konto</a>
        <a mat-button *ngIf="!auth.isClientLoggedIn()" class="nav-link" routerLink="/strona/rejestracja"
          routerLinkActive="active-link">Rejestracja</a>
        <a mat-button *ngFor="let page of pages" class="nav-link" routerLink="{{page.guid}}"
          routerLinkActive="active-link">{{page.title}}</a>
        <span class="spacer"></span>

      </mat-toolbar-row>
    </mat-toolbar>
    <!-- NAVBAR END -->

    <router-outlet></router-outlet>
    <!-- <div class="push"></div> -->
  </div>
  <div class="footer">
    <div style="flex: 2; text-align: center; padding: 4px;">
      <a mat-button class="nav-link" routerLink="/strona/regulamin" routerLinkActive="active-link">REGULAMIN</a>
      <a mat-button class="nav-link" routerLink="/strona/pomoc" routerLinkActive="active-link">POMOC</a>
      <a mat-button class="nav-link" routerLink="/strona/reklamacje" routerLinkActive="active-link">REKLAMACJE</a>
      <a mat-button class="nav-link" routerLink="/strona/kontakt" routerLinkActive="active-link">KONTAKT</a>
      <a mat-button class="nav-link" routerLink="/strona/polityka_prywatnosci" routerLinkActive="active-link">POLITYKA
        PRYWATNOŚCI</a>
    </div>
    <div style="flex: 1; text-align: center; padding: 12px;">
      © Copyright 2020 <a href="https://poligrafiadlafirm.pl/">poligrafiadlafirm.pl</a>
    </div>
  </div>
</div>

<!-- CONENT END  -->
