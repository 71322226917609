<mat-spinner style="margin: 0px auto;" *ngIf="loadingProduct || loadingImages"></mat-spinner>
<div style="display: flex; flex-direction: row; flex-wrap: wrap;" *ngIf="!errorLoading">

  <div *ngIf="!loadingProduct && !loadingImages" style="flex: 1; padding: 1rem;">
    <mat-form-field class="full-width">
      <mat-label>Nazwa</mat-label>
      <input matInput [(ngModel)]="product.name" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>guid (Uproszczona nazwa używana w URL)</mat-label>
      <input matInput [(ngModel)]="product.guid" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>SEO tytuł</mat-label>
      <input matInput [(ngModel)]="product.seo_title" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>SEO słowa kluczowe</mat-label>
      <input matInput [(ngModel)]="product.seo_keywords" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>SEO opis</mat-label>
      <input matInput [(ngModel)]="product.seo_description" />
    </mat-form-field>
    <p>Opis</p>
    <ckeditor [editor]="Editor" [(ngModel)]="product.description"></ckeditor>
    <br />
    <p>Pakowanie</p>
    <div style="width: 99%;">
      <ckeditor [editor]="Editor" [(ngModel)]="product.packing"></ckeditor>
    </div>

    <br />
    <button mat-raised-button color="primary" (click)="update()">Zapisz</button>
    &nbsp;
    <button mat-raised-button color="accent" routerLink="/admin-panel/products">
      Powrót do produktów
    </button>
  </div>

  <!-- DANE PRODUKTU -->
  <div style="flex-direction: column; ">
    <div *ngIf="!loadingProduct && !loadingImages" style="flex: 1; padding: 1rem;">
      <mat-list>
        <div mat-subheader>Dane produktu</div>
        <mat-list-item >
          <mat-icon mat-list-icon>event</mat-icon>
          <div mat-line>Data utworzenia</div>
          <div mat-line> {{product.createdAt | date:'yyyy-MM-d HH:mm'}} </div>
        </mat-list-item>
        <mat-list-item >
          <mat-icon mat-list-icon>update</mat-icon>
          <div mat-line>Data modyfikacji</div>
          <div mat-line> {{product.updatedAt | date:'yyyy-MM-d HH:mm'}} </div>
        </mat-list-item>
        <mat-list-item >
          <mat-icon mat-list-icon>{{product.id_dk ? "check_box" : "check_box_outline_blank"}}</mat-icon>
          <div mat-line>Z drukomatu</div>
          <div mat-line> {{product.id_dk ? "Tak" : "Nie"}} </div>
        </mat-list-item>
        <mat-list-item >
          <mat-icon mat-list-icon>{{product.availableDk ? "check_box" : "check_box_outline_blank"}}</mat-icon>
          <div mat-line>Dostępny w drukomacie</div>
          <div mat-line> {{product.availableDk ? "Tak" : "Nie"}} </div>
        </mat-list-item>
        <mat-list-item >
          <mat-icon mat-list-icon>{{product.active ? "check_box" : "check_box_outline_blank"}}</mat-icon>
          <div mat-line>Włączony</div>
          <div mat-line> {{product.active ? "Tak" : "Nie"}} </div>
        </mat-list-item>
      </mat-list>
    </div>
    <br />

     <!-- ZDJECIA -->
    <div
      *ngIf="!loadingImages && !loadingProduct"
      style="flex: 1; padding: 1rem;"
    >
      <p>Zdjęcie produktu</p>
      <img
        style="width: 128px; height: 128px;"
        [src]="serverUrl + this.product.icon"
      />

      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Zdjęcia na serwerze
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="productIcons">
            <div
              *ngFor="let image of images"
              (click)="selectIcon('/assets/media/glowne_grupy/' + image)"
              [ngStyle]="{
                border:
                  this.product.icon == '/assets/media/glowne_grupy/' + image
                    ? '2px solid orange'
                    : '2px solid white'
              }"
            >
              <img
                [src]="serverUrl + '/assets/media/glowne_grupy/' + image"
                style="width: 96px; height: 96px;"
              /><br>
              <div style="width: 96px; font-size: 15px; line-height: 14px; overflow-wrap: break-word;">{{image}}</div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <br />
      <button color="primary" mat-raised-button (click)="fileInput.click()">
        Dodaj zdjęcie
      </button>
      <br />
      <div *ngIf="uploadUrl">
        <img
          class="preview"
          style="width: 128px; height: 128px;"
          [src]="uploadUrl"
        />
        <br />
        <button color="primary" mat-raised-button (click)="uploadPhoto()">
          Prześlij
        </button>
      </div>
      <input
        hidden
        (change)="onFileSelected($event)"
        #fileInput
        type="file"
        id="file"
      />
    </div>
  </div>
</div>
