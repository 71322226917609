import { Component, OnInit } from "@angular/core";
import { ClientsService } from "src/app/services/clients.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { PageEvent } from "@angular/material/paginator";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-admin-clients",
  templateUrl: "./clients.component.html",
  styleUrls: ["./clients.component.scss"],
})
export class AdminClientsComponent implements OnInit {
  // MatPaginator Inputs
  length = 100;
  pageSize = 15;
  pageSizeOptions: number[] = [5, 10, 15, 20, 30];
  pageIndex: number = 0;
  // MatPaginator Output
  pageEvent: any;
  constructor(
    private router: Router,
    private clientsService: ClientsService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) {}
  clients: any;
  loadingClients: any;

  searchTerm: String = "";

  headers: any = [
    {
      text: "Firma",
      sortingName: "company_name",
      sortingState: 0,
    },
    {
      text: "Klient",
      sortingName: "last_name",
      sortingState: 0,
    },
    {
      text: "Nip",
      sortingName: "nip",
      sortingState: 0,
    },
    {
      text: "Data rejestracji",
      sortingName: "createdAt",
      sortingState: 0,
    },
    {
      text: "Aktywny",
      sortingName: "active",
      sortingState: 0,
    },
    {
      text: "Rabat %",
      sortingName: "discount",
      sortingState: 0,
    },
    {
      text: "Zamówienia",
      sortingName: "ordersAmount",
      sortingState: 0,
    },
    {
      text: "Zgoda na wysyłkę",
      sortingName: "marketing_consent",
      sortingState: 0,
    },
  ];

  sortClick(index) {
    switch (this.headers[index].sortingState) {
      case 1:
        this.headers[index].sortingState = 2;
        break;
      case 2:
        this.headers[index].sortingState = 0;
        break;
      default:
        this.headers[index].sortingState = 1;
        break;
    }
    this.headers.forEach((el, i)=>{
      if(index != i){
        el.sortingState = 0
      }
    })
    this.loadClients();
  }

  onSearch() {
    this.loadingClients = true;
    if (this.searchTerm != "") {
      this.clientsService.search(this.searchTerm).subscribe(
        (res) => {
          this.clients = res.body;
          this.length = res.headers.get("X-Total-Count");
          this.loadingClients = false;
        },
        (err) => {
          this.loadingClients = false;
          this.openSnackBar(
            err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
            "OK",
            10
          );
        }
      );
    } else {
      this.loadClients();
    }
  }
  goToClient(id) {
    this.router.navigate(["/admin-panel/clients/" + id]);
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.pageSize = params["pageSize"] || this.pageSize;
      this.pageIndex = params["pageIndex"] || this.pageIndex;
    });
    this.loadClients();
  }
  loadClients(event?) {
    this.loadingClients = true;
    this.pageSize = event ? event.pageSize : this.pageSize;
    this.pageIndex = event ? event.pageIndex : this.pageIndex;

    let sort = []

    this.headers.forEach(el=>{
      if(el.sortingState!=0){
        sort.push([el.sortingName, el.sortingState==1?"ASC":"DESC"])
      }
    })

    if(sort.length==0){
      sort = null;
    }

    this.clientsService.getClients(this.pageSize, this.pageIndex, sort).subscribe(
      (res) => {

        this.clients = res.body;
        this.length = res.headers.get("X-Total-Count");
        this.loadingClients = false;
      },
      (err) => {
        this.loadingClients = false;
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      }
    );

    this.router.navigate([], {
      queryParams: { pageSize: this.pageSize, pageIndex: this.pageIndex },
    });
  }
  toggleActive(event, id, active, index) {
    event.stopPropagation();
    this.clients[index].active = active;
    this.clientsService.toggleActiveClient(id, active).subscribe(
      (res) => {
        this.openSnackBar("Zaktualizowano klienta pomyślnie", "OK", 3);
      },
      (err) => {
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      }
    );
  }
  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
}
