import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  registerData = {
    company_name: "",
    nip: "",
    postal_code: "",
    city: "",
    address: "",
    email_invoice: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    marketing_consent: false
  }

  password2 = "";
  waitingForResponse = false;
  regulamin = false;


  constructor(private auth: AuthService, private router: Router, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }

  register() {
    this.waitingForResponse = true;
    this.auth.registerUser(this.registerData).subscribe(res => {
      this.openSnackBar("Konto utworzone pomyślnie, możesz się teraz zalogować", "OK", 5)
      this.router.navigate(["/"])
      this.waitingForResponse = false;
    }, err => {
      this.waitingForResponse = false;
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

}
