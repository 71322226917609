<mat-spinner style="margin: 0px auto" *ngIf="isTableLoading || productUnavaliable || !product"></mat-spinner>
<div style="display: flex; flex-direction: row;" *ngIf="!isTableLoading && !productUnavaliable && product">
  <!-- LEWA -->
  <div style="flex: 1; padding: 1rem;">
    <mat-form-field class="full-width">
      <mat-label class="capitalize">Produkt</mat-label>
      <mat-select [(ngModel)]="selectedProductGuid" name="selects[parent]" (selectionChange)="changeProduct()">
        <mat-option [value]="product.guid" *ngFor="let product of products">
          {{product.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <div *ngFor="let parent of Object.keys(product?.configs.rodzic)">
      <mat-form-field class="full-width">
        <!-- Sprawdzanie wybranej opcji: <p>Selected option: {{ selects[parent] }}</p> -->
        <mat-label class="capitalize">{{product?.names[parent]}}</mat-label>
        <mat-select [(value)]="selects[parent]" (selectionChange)="onChange()">
          <mat-option [value]="child" *ngFor="let child of Object.keys(product?.configs.rodzic[parent])">
            {{product?.names[child]}}
          </mat-option>
          <mat-option [value]="-1" *ngIf="formats.includes(parent)">
            własny format
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div *ngIf="selects[parent]=='-1'">

        <mat-form-field class="full-width">
          <p>Szerokość od {{product.format.width.min}} do {{product.format.width.max}} cm</p>
          <input matInput name="customWidth" [(ngModel)]="customWidth" />
        </mat-form-field>
        <mat-form-field class="full-width">
          <p>Wysokość od {{product.format.height.min}} do {{product.format.height.max}} cm</p>
          <input matInput name="customHeight" [(ngModel)]="customHeight" />
        </mat-form-field>
        <div class="full-width"
          style="display: flex; align-items: center; justify-items: center; align-content: center; justify-content: center;">
          <button style="margin-bottom: 1rem; " mat-raised-button color="primary" (click)="onChange()">Akceptuj</button>
        </div>
      </div>
    </div>
    <button mat-raised-button (click)="saveDefault()" color="primary">Zapisz jako domyślne ustawienia cech</button>
  </div>
  <!-- PRAWA -->
  <div style="flex: 2; padding: 1rem;">
    <table>
      <tr>
        <th rowspan="2">nakład</th>
        <th style="background-color: sandybrown;" colspan="4">Standard</th>
        <th colspan="4">Ekspres</th>
      </tr>
      <tr>
        <th style="background-color: sandybrown;">cena netto</th>
        <th style="background-color: sandybrown;">marża %</th>
        <th style="background-color: sandybrown;">cena po marży</th>
        <th style="background-color: sandybrown;">zysk</th>
        <th>cena netto</th>
        <th>marża %</th>
        <th>cena po marży</th>
        <th>zysk</th>
      </tr>
      <tr *ngFor="let element of dataSource">
        <td>{{element[1].naklad}}</td>
        <td>{{element[1].cena_standard | currency: "PLN"}}</td>
        <td>{{element[1].marza}}</td>
        <td>{{element[1].cena_standard_marzowane | currency: "PLN"}}</td>
        <td>{{element[1].cena_standard_marzowane - element[1].cena_standard | currency: "PLN"}}</td>
        <td>{{element[1].cena_ekspres | currency: "PLN"}}</td>
        <td>{{element[1].marza}}</td>
        <td>{{element[1].cena_ekspres_marzowane | currency: "PLN"}}</td>
        <td>{{element[1].cena_ekspres_marzowane - element[1].cena_ekspres | currency: "PLN"}}</td>
      </tr>
    </table>
  </div>
</div>
