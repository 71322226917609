import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http:HttpClient) { }

  getClient():Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/clients/");
  }

  getClientInvoices(limit?, page?):Observable<any>{
    let params = {}
    if (limit) {
      params['limit'] = limit
    }
    if (page) {
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl+"/api/clients/invoices", { params, observe: 'response' });
  }

  postAddress(address):Observable<any>{
    return this.http.post(environment.apiBaseUrl+"/api/clients/addresses/", address);
  }

  putAddress(address):Observable<any>{
    return this.http.put(environment.apiBaseUrl+"/api/clients/addresses/"+address.id, address);
  }

  updateClient(clientData):Observable<any>{
    return this.http.put(environment.apiBaseUrl+'/api/clients/', clientData)
  }

  deleteAddress(id):Observable<any>{
    return this.http.delete(environment.apiBaseUrl+"/api/clients/addresses/"+id);
  }

  disableClient():Observable<any>{
    return this.http.delete(environment.apiBaseUrl+'/api/clients/')
  }

}
