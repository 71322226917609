import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class BannersService {

  constructor(private http: HttpClient) { }

  getBanners(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + '/api/banners')
  }
  getBannersAsAdmin(): Observable<any> {
    return this.http.get(environment.apiBaseUrl + '/api/admin/banners')
  }
  addBanner(banner): Observable<any> {
    return this.http.post(environment.apiBaseUrl + '/api/admin/banners',banner)
  }
  updateBanner(banner): Observable<any> {
    return this.http.put(environment.apiBaseUrl + '/api/admin/banners/'+banner.id, banner)
  }
  deleteBanner(id): Observable<any> {
    return this.http.delete(environment.apiBaseUrl + '/api/admin/banners/'+id)
  }
  getImages():Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/admin/banners/uploads")
  }
  uploadPhoto(fd:any):Observable<any>{
    return this.http.post<any>(environment.apiBaseUrl+'/api/admin/banners/upload', fd)
  }
}
