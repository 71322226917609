import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  constructor(private http:HttpClient) { }

  getGroups(limit?, page?):Observable<any> {
    let params = {}
    if(limit){
      params['limit'] = limit
    }
    if(page){
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl+"/api/groups", {params});
  }

  getAdminGroups(limit?, page?):Observable<any> {
    let params = {}
    if(limit){
      params['limit'] = limit
    }
    if(page){
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl+"/api/admin/groups", {params});
  }
  getGroupImages():Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/admin/groups/uploads")
  }

  putBulkUpdate(groups):Observable<any> {
      return this.http.put(environment.apiBaseUrl+"/api/admin/groups/bulkUpdate", groups, httpOptions)
  }

  toggleActiveGroup(id, active):Observable<any>{
    return this.http.put(environment.apiBaseUrl+'/api/admin/groups/'+id, {
      active
    })
  }
  createGroup(group):Observable<any>{
    return this.http.post(environment.apiBaseUrl+'/api/admin/groups/', group)
  }
  updateGroup(group):Observable<any>{
    return this.http.put(environment.apiBaseUrl+'/api/admin/groups/'+group.id, group)
  }
  uploadPhoto(fd:any):Observable<any>{
    return this.http.post<any>(environment.apiBaseUrl+'/api/admin/groups/upload', fd)
  }

  getSingleGroup(guid):Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/groups/"+guid)
  }

  getSingleGroupAsAdmin(guid):Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/admin/groups/"+guid)
  }

  deleteGroup(id):Observable<any>{
    return this.http.delete(environment.apiBaseUrl+"/api/admin/groups/"+id)
  }

}
