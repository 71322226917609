<mat-spinner style="margin: 0px auto" *ngIf="loadingGroups && !errorLoading"></mat-spinner>
<mat-card *ngFor="let group of groups" class="nz-card clickable" routerLink="grupa/{{group.guid}}">
    <mat-card-content class="card-content">
        <div class="image-container">
            <img class="group-icon" [src]="group?.icon ? imgUrl + group.icon : 'assets/img/no_photo.png'">
        </div>

        <div class="text-container">
            <h2>{{group.name}}</h2>
            <div [innerHTML]="group.description | safeHtml"></div>
        </div>
    </mat-card-content>
</mat-card>
<div *ngIf="errorLoading" style="text-align: center;"><h2>Usługa chwilowo niedostępna</h2></div>
