import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { OrdersService } from 'src/app/services/orders.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { RecComment } from '../../reclamations/reclamations.component';
import { ReclamationsService } from 'src/app/services/reclamations.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss']
})
export class OrderDetailsComponent implements OnInit {

  constructor(private _snackBar: MatSnackBar,
    private router: Router,
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    public _location: Location,
    public dialog: MatDialog,
    private reclamationService: ReclamationsService
    ) { }
  order: any
  comments: any
  parmezan: any
  paramsArray = []
  isLoadingOrder: boolean = true
  hasError: boolean = false
  isLoadingComments: boolean = true
  reclamation:any
  Object = Object;
  JSON = JSON;
  environment = environment
  couriers = {
    GLS: "https://gls-group.eu/PL/pl/sledzenie-paczek?match=",
    DPD: "https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=",
  };

  ngOnInit(): void {
    this.isLoadingOrder = true
    this.isLoadingComments = true
    this.route.params.subscribe(params => {
      //load order
      this.ordersService.getOrderAsAdmin(params['id']).subscribe(res => {
        this.order = res
        if (this.couriers[res.result.adresy[0].send_by]) {
          this.order.sledzenie =
            this.couriers[res.result.adresy[0].send_by] +
            this.order.result.adresy[0].package_number;
        }
        if (this.order.params) {
          this.parmezan = JSON.parse(this.order.params);
          Object.keys(this.parmezan).forEach(item => {
            this.paramsArray.push(item)
          })
        }
        this.isLoadingOrder = false
      }, err => {
        this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        this.hasError = true
        this.isLoadingOrder = false
      })
      //load comments
      this.ordersService.getOrderCommentsAsAdmin(params['id']).subscribe(res => {
        this.comments = res.result
        this.isLoadingComments = false
      }, err => {
        this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        this.isLoadingComments = false
      })
      //load reclamation
      this.ordersService.getOrderReclamation(params['id']).subscribe(res => {
        this.reclamation = res

      }, err => {
        this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)

      })
    })
  }

  openRecComment() {
    const dialogRef = this.dialog.open(RecComment, {
      width: "75%",
      data: { reclamation: this.reclamation },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let newReclamationComment = {};
        newReclamationComment["author"] = "Serwis";
        newReclamationComment["reclamationId"] = this.reclamation.id
        newReclamationComment["comment"] = result
        this.reclamationService.addAdminComment(newReclamationComment).subscribe(res => {
          this.openSnackBar("Zapisano", "OK", 10)
          this.ngOnInit()
        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        });
      }
    });
  }

  goToClientJS() {

    this.router.navigate(["files"], { relativeTo: this.route })

  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
}
