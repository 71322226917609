<mat-tab-group>
    <mat-tab label="Ogólne">
        <mat-accordion class="settings-accordion">
            <!---------Ustawienia ogólne---------->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Ustawienia ogólne
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-form-field *ngFor="let option of settings.ogolne" class="full-width" appearance="outline">
                    <mat-label>{{option.name}}</mat-label>
                    <input matInput [(ngModel)]=option.value>
                </mat-form-field>
                <button mat-raised-button (click)="updateGroup('ogolne')" color="primary">
                    Aktualizuj
                </button>
            </mat-expansion-panel>
            <!---------SEO---------->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        SEO
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-form-field *ngFor="let option of settings.seo" class="full-width" appearance="outline">
                    <mat-label>{{option.name}}</mat-label>
                    <textarea rows="2" matInput [(ngModel)]=option.value></textarea>
                </mat-form-field>
                <button mat-raised-button (click)="updateGroup('seo')" color="primary">
                    Aktualizuj
                </button>
            </mat-expansion-panel>
            <!---------Nadawca---------->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Nadawca
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-form-field *ngFor="let option of settings.nadawca" class="full-width" appearance="outline">
                    <mat-label>{{option.name}}</mat-label>
                    <input matInput [(ngModel)]=option.value>
                </mat-form-field>
                <button mat-raised-button (click)="updateGroup('nadawca')" color="primary">
                    Aktualizuj
                </button>
            </mat-expansion-panel>
            <!---------Dane sprzedającego na fakturze---------->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Dane sprzedającego na fakturze
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-form-field *ngFor="let option of settings.faktura" class="full-width" appearance="outline">
                    <mat-label>{{option.name}}</mat-label>
                    <textarea rows="1" matInput [(ngModel)]=option.value></textarea>
                </mat-form-field>
                <button mat-raised-button (click)="updateGroup('faktura')" color="primary">
                    Aktualizuj
                </button>
            </mat-expansion-panel>
            <!---------Mail---------->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Konfiguracja skrzynki pocztowej
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <mat-form-field *ngFor="let option of settings.mail" class="full-width" appearance="outline">
                    <mat-label>{{option.name}}</mat-label>
                    <input matInput [(ngModel)]=option.value>
                </mat-form-field>
                <button mat-raised-button (click)="updateGroup('mail')" color="primary">
                    Aktualizuj
                </button>
            </mat-expansion-panel>
        </mat-accordion>
    </mat-tab>
    <!-- ---------------------- -->
    <mat-tab label="Marże">
        <mat-accordion class="settings-accordion">
            <!---------Marża---------->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Marża
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <p>0 w maksymalnej cenie oznacza nieskończoność</p>
                <div *ngFor="let option of margins">
                    <mat-form-field class="quarter-width" appearance="outline">
                        <mat-label>Stawka %</mat-label>
                        <input matInput [(ngModel)]=option.rate>
                    </mat-form-field>
                    <mat-form-field class="quarter-width" appearance="outline">
                        <mat-label>Minimalna cena</mat-label>
                        <input matInput [(ngModel)]=option.min>
                    </mat-form-field>
                    <mat-form-field class="quarter-width" appearance="outline">
                        <mat-label>Maksymalna cena</mat-label>
                        <input matInput [(ngModel)]=option.max>
                    </mat-form-field>
                    <button *ngIf="deleteButtonsActive" mat-stroked-button (click)="removeMargin(option.id)"
                        color="warn">
                        X
                    </button>
                </div>
                <div *ngIf="newMarginActive">
                    <mat-form-field class="third-width" appearance="outline">
                        <mat-label>Stawka %</mat-label>
                        <input matInput [(ngModel)]=this.newMargin.rate>
                    </mat-form-field>
                    <mat-form-field class="third-width" appearance="outline">
                        <mat-label>Minimalny nakład</mat-label>
                        <input matInput [(ngModel)]=this.newMargin.min>
                    </mat-form-field>
                    <mat-form-field class="third-width" appearance="outline">
                        <mat-label>Maksymalny nakład</mat-label>
                        <input matInput [(ngModel)]=this.newMargin.max>
                    </mat-form-field>
                </div>
                <button mat-raised-button (click)="toggleNewMargin()" color="accent">
                    {{newMarginActive ? "Anuluj" : "Nowy próg"}}
                </button>
                <br><br>
                <button mat-raised-button (click)="updateMargins()" color="primary">
                    Aktualizuj
                </button>
                &nbsp;
                <button mat-raised-button (click)="toggleDeleteButtons()" color="accent">
                    {{deleteButtonsActive ? "Ukryj" : "Pokaż"}} przyciski usuwania
                </button>
            </mat-expansion-panel>
            <!---------Koszty wysyłki---------->
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Koszty wysyłki
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div *ngFor="let option of shippings">
                    <mat-form-field class="third-width" appearance="outline">
                        <mat-label>Do wartości</mat-label>
                        <input matInput [(ngModel)]=option.threshold>
                    </mat-form-field>
                    <mat-form-field class="third-width" appearance="outline">
                        <mat-label>Koszt</mat-label>
                        <input matInput [(ngModel)]=option.price>
                    </mat-form-field>
                    <button *ngIf="deleteButtonsActive" mat-stroked-button (click)="removeShipping(option.id)"
                        color="warn">
                        X
                    </button>
                </div>
                <div *ngIf="newShippingActive">
                    <mat-form-field class="half-width" appearance="outline">
                        <mat-label>Do wartości</mat-label>
                        <input matInput [(ngModel)]=this.newShipping.threshold>
                    </mat-form-field>
                    <mat-form-field class="half-width" appearance="outline">
                        <mat-label>Koszt</mat-label>
                        <input matInput [(ngModel)]=this.newShipping.price>
                    </mat-form-field>
                </div>
                <button mat-raised-button (click)="toggleNewShipping()" color="accent">
                    {{newShippingActive ? "Anuluj" : "Nowy próg"}}
                </button>
                <br><br>
                <button mat-raised-button (click)="updateShippings()" color="primary">
                    Aktualizuj
                </button>
                &nbsp;
                <button mat-raised-button (click)="toggleDeleteButtons()" color="accent">
                    {{deleteButtonsActive ? "Ukryj" : "Pokaż"}} przyciski usuwania
                </button>
            </mat-expansion-panel>
            <!------------------->
        </mat-accordion>
    </mat-tab>
    <!-- ---------------------- -->
    <mat-tab label="Szablony maili">
        <mat-accordion class="settings-accordion">
            <mat-expansion-panel *ngFor="let template of templates">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{template.name}}
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Temat</mat-label>
                        <input matInput [(ngModel)]=template.title>
                    </mat-form-field>
                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Treść</mat-label>
                        <textarea rows="12" matInput [(ngModel)]=template.content></textarea>
                    </mat-form-field>
                </div>
                <button mat-raised-button (click)="updateTemplate(template)"
                    color="primary">
                    Zapisz
                </button>
            </mat-expansion-panel>
        </mat-accordion>

    </mat-tab>
        <!-- ---------------------- -->
        <mat-tab label="Banery">
          <app-banners></app-banners>
        </mat-tab>
        <mat-tab label="Sitemap">
          <app-sitemap></app-sitemap>
        </mat-tab>
        <mat-tab label="Użytkownicy">
          <app-admins></app-admins>
        </mat-tab>
</mat-tab-group>
