<mat-spinner style="margin: 0px auto" *ngIf="waitingForResponse"></mat-spinner>
<div *ngIf="!waitingForResponse">
  <mat-tab-group>

    <!-- Promocje -->

    <mat-tab label="Promocje">
      <div *ngFor="let sale of sales">
        <mat-card class="nz-card">
          <mat-card-content class="card-content">
            <div class="text">
              {{sale.name}}
            </div>
            <div class="text">
              Od: {{sale._from | date:'yyyy-MM-d HH:mm'}} <br> Do: {{sale._to | date:'yyyy-MM-d HH:mm'}}
            </div>
            <div class="text">
              <span [ngStyle]="{color: sale.isActive==true?'#74D774':'#F8665E'}"> Aktywna:
                {{sale.isActive==true?"Tak":"Nie"}} </span>
            </div>
            <div class="icons">
              <button routerLink="/admin-panel/sale/{{sale.id}}" mat-button>
                <mat-icon>create</mat-icon>
              </button>
              <button *ngIf="isDeleteButtonsActive['sales']" (click)="deleteSale(sale.id)" mat-button>
                <mat-icon>delete</mat-icon>
              </button>
            </div>

          </mat-card-content>
        </mat-card>
      </div>

      <div class="buttons">
        <button mat-raised-button color="primary" routerLink="/admin-panel/create-sale">
          Nowa promocja
        </button>
        &nbsp;
        <button (click)="toggleDeleteButtons('sales')" mat-raised-button color="accent">
          {{isDeleteButtonsActive['sales'] ? 'Ukryj' : 'Pokaż'}} przyciski usuwania
        </button>
      </div>
    </mat-tab>

    <!-- Kody -->

    <mat-tab label="Kody promocyjne">

      <!-- Tabela z kodami-->

      <table style="width: 100%;">
        <tr>
          <th>Nazwa</th>
          <th>Kod</th>
          <th>Wartość</th>
          <th>Typ</th>
          <th>Od</th>
          <th>Do</th>
          <th>Minimum</th>
          <th>Maksimum</th>
          <th>Opis</th>
          <th>Akcje</th>
        </tr>
        <!-- Wypisywanie kodów w tabeli -->
        <tr *ngFor="let code of codes; index as i">

          <td class="cell">
            <span *ngIf="!isEditModeActive || editCode.id != code.id">{{code.name}}</span>
            <mat-form-field *ngIf="isEditModeActive && editCode.id == code.id">
              <input matInput [(ngModel)]="editCode.name" />
            </mat-form-field>
          </td>

          <td class="cell">
            <span *ngIf="!isEditModeActive || editCode.id != code.id">{{code.code}}</span>
            <mat-form-field *ngIf="isEditModeActive && editCode.id == code.id" class="full-width">
              <input matInput [(ngModel)]="editCode.code" />
              <button mat-button matSuffix mat-icon-button (click)="editCode.code=generateCode(7)">
                <mat-icon>refresh</mat-icon>
              </button>
            </mat-form-field>

          </td>

          <td class="cell">
            <span *ngIf="!isEditModeActive || editCode.id != code.id">{{code.discount | number}}</span>
            <mat-form-field *ngIf="isEditModeActive && editCode.id == code.id" class="full-width">
              <input matInput [(ngModel)]="editCode.discount" />
            </mat-form-field>
          </td>

          <td class="cell">
            <span *ngIf="!isEditModeActive || editCode.id != code.id">{{code.type}}</span>
            <mat-form-field *ngIf="isEditModeActive && editCode.id == code.id" class="full-width">
              <mat-select color="primary" [(ngModel)]="editCode.type">
                <mat-option value="procent">procent</mat-option>
                <mat-option value="kwota">kwota</mat-option>
              </mat-select>
            </mat-form-field>
          </td>

          <td class="cell">
            <span *ngIf="!isEditModeActive || editCode.id != code.id">{{code._from | date:'yyyy-MM-d HH:mm'}}</span>
            <mat-form-field *ngIf="isEditModeActive && editCode.id == code.id" class="full-width">
              <input matInput [matDatepicker]="_fromPicker" [(ngModel)]="editCode._from" name="_from" />
              <mat-datepicker-toggle matSuffix [for]="_fromPicker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #_fromPicker></mat-datepicker>
            </mat-form-field>
          </td>

          <td class="cell">
            <span *ngIf="!isEditModeActive || editCode.id != code.id">{{code._to | date:'yyyy-MM-d HH:mm'}}</span>
            <mat-form-field *ngIf="isEditModeActive && editCode.id == code.id" class="full-width">
              <input matInput [matDatepicker]="_toPicker" [(ngModel)]="editCode._to" name="_to" />
              <mat-datepicker-toggle matSuffix [for]="_toPicker"></mat-datepicker-toggle>
              <mat-datepicker touchUi #_toPicker></mat-datepicker>
            </mat-form-field>
          </td>

          <td class="cell">
            <span *ngIf="!isEditModeActive || editCode.id != code.id">{{code.minimum}}</span>
            <mat-form-field *ngIf="isEditModeActive && editCode.id == code.id" class="full-width">
              <input matInput [(ngModel)]="editCode.minimum" />
            </mat-form-field>
          </td>

          <td class="cell">
            <span *ngIf="!isEditModeActive || editCode.id != code.id">{{code.maximum}}</span>
            <mat-form-field *ngIf="isEditModeActive && editCode.id == code.id" class="full-width">
              <input matInput [(ngModel)]="editCode.maximum" />
            </mat-form-field>
          </td>

          <td class="cell">
            <span *ngIf="!isEditModeActive || editCode.id != code.id">{{code.description}}</span>
            <mat-form-field *ngIf="isEditModeActive && editCode.id == code.id" class="full-width">
              <input matInput [(ngModel)]="editCode.description" />
            </mat-form-field>
          </td>

          <td class="cell">
            <button *ngIf="!isEditModeActive || editCode.id != code.id" (click)="activateEditMode(code)" mat-button>
              <mat-icon>create</mat-icon>
            </button>
            <button *ngIf="isEditModeActive && editCode.id == code.id"
              (click)="creatingNewCode?createCode():updateCode(i)" mat-button>
              <mat-icon>done</mat-icon>
            </button>
            <button *ngIf="isEditModeActive && editCode.id == code.id" (click)="deactivateEditMode()" mat-button>
              <mat-icon>clear</mat-icon>
            </button>
            <button *ngIf="isDeleteButtonsActive['codes']" (click)="deleteCode(code.id)" mat-button>
              <mat-icon color="warn">delete</mat-icon>
            </button>
          </td>

        </tr>
      </table>

      <div class="buttons">
        <button mat-raised-button color="primary" (click)="newCode()">
          Nowy kod
        </button>
        &nbsp;
        <button (click)="toggleDeleteButtons('codes')" mat-raised-button color="accent">
          {{isDeleteButtonsActive['codes'] ? 'Ukryj' : 'Pokaż'}} przyciski usuwania
        </button>
      </div>

    </mat-tab>

    <mat-tab label="Emaile promocyjne">
      <div style="margin: 1rem">
        <mat-form-field class="full-width">
          <mat-label>Tytuł</mat-label>
          <input matInput [(ngModel)]="mail.title" />
        </mat-form-field>
        <ckeditor [editor]="Editor" [(ngModel)]="mail.html"></ckeditor>
        <button [disabled]="mail.html == '' || mail.title == ''" (click)="sendMail()" style="margin-top: 1rem" mat-raised-button color="primary">
          Wyślij
        </button>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
