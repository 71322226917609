<h1 mat-dialog-title>Reklamacja</h1>
<div mat-dialog-content>
  <h2>Zgłoszenie</h2>
  <div>
    <p>Powód: {{data.reclamation.reason}}</p>
    <p style="white-space: pre-wrap;"><span style="font-weight: 500;">Opis:</span><br>{{data.reclamation.description}}
    </p>
  </div>
  <h2>Odpowiedź</h2>
  <div *ngIf="isThereAComment">
    <div style="padding: 1rem 0px;" *ngFor="let comment of data.reclamation.reclamation_comments">
      <p  *ngIf="comment.author=='Serwis'" style="font-size: 20px; font-weight: 500;">Nasza odpowiedź</p>
      <p  *ngIf="comment.author!='Serwis'" style="font-size: 20px; font-weight: 500;">Komentarz klienta</p>
      <hr *ngIf="comment.author=='Serwis'" style="border: 1px solid orange">
      <hr *ngIf="comment.author!='Serwis'" style="border: 1px solid black">
      <p>Data: {{comment.createdAt | date:'yyyy-MM-d HH:mm'}}</p>
      <p style="white-space: pre-wrap;">Treść: {{comment.comment}}</p>
    </div>
  </div>
  <div *ngIf="!isThereAComment">
    <p style="color: red">Nie wystawiono jeszcze komentarza dla tej reklamacji</p>
  </div>
  <mat-form-field appearance="standard" class="full-width">
    <mat-label>Wystawianie odpowiedzi</mat-label>
    <textarea rows="2" matInput placeholder="Odpowiedź" [(ngModel)]="newComment" name="comment"
      required></textarea>
  </mat-form-field>

</div>
<div mat-dialog-actions>
  <button mat-raised-button color="accent" (click)="onNoClick()">Anuluj</button>
  <button mat-raised-button color="primary" [mat-dialog-close]="newComment" cdkFocusInitial>Zapisz odpowiedź</button>
</div>
