import { Component, OnInit, Inject } from '@angular/core';
import { BannersService } from 'src/app/services/banners.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit {

  constructor(private bannersService: BannersService, private _snackBar: MatSnackBar, public dialog: MatDialog, ) { }
  banners
  environment=environment
  isLoading: boolean = true
  addingNewBanner: boolean = false
  newBanner:any={
    link: "/"
  }
  selectedFile
  uploadUrl
  serverUrl = environment.apiBaseUrl;
  ngOnInit(): void {
    this.bannersService.getBannersAsAdmin().subscribe(res => {
      this.banners = res
      this.isLoading = false
    }, err => {
      this.isLoading = false
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
    this.loadImages()
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }

  toggleActive(i, isActive) {
    this.banners[i].isActive = isActive
    this.bannersService.updateBanner(this.banners[i]).subscribe(res => {
      this.openSnackBar("Baner zaktualizowany", "OK", 3)
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  addBanner(){
    this.bannersService.addBanner(this.newBanner).subscribe(res => {
      this.openSnackBar("Baner dodany", "OK", 3)
      this.newBanner = {
        link: "/"
      }
      this.selectedFile = null
      this.uploadUrl = null
      this.addingNewBanner = false
      this.ngOnInit()
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  selectIcon(image){
    this.newBanner.image = image
  }
  onFileSelected(event){
    this.selectedFile = event.target.files[0]
    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);

      reader.onload = event => {
        this.uploadUrl = reader.result;
      };
  }
  uploadPhoto(){
    const fd = new FormData()
    fd.append('img', this.selectedFile, this.selectedFile.name)
    this.bannersService.uploadPhoto(fd).subscribe(res => {
      this.newBanner.image = res
      this.loadImages()
    },err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  loadingImages
  images
  loadImages() {
    this.loadingImages = true
    this.bannersService.getImages().subscribe(res => {
      // console.log(res)
      this.images = res
      this.loadingImages = false
    }, err => {
      this.loadingImages = false
    })
  }
  openLinkDialog(baner): void {
    const dialogRef = this.dialog.open(EditBannerComponent, {
      width: "75%",
      data: baner,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        baner.link = result
        this.bannersService.updateBanner(baner).subscribe(res => {
          this.openSnackBar("Baner zaktualizowany", "OK", 3)
        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
      }
    });
  }

  openImgDialog(baner): void {
    const dialogRef = this.dialog.open(ImgBannerComponent, {
      width: "75%",
      data: baner,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.bannersService.updateBanner(result).subscribe(res => {
          this.openSnackBar("Baner zaktualizowany", "OK", 3)
        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
      }
    });
  }

  openDeleteDialog(baner): void {
    const dialogRef = this.dialog.open(DeleteBannerDialog, {
      width: "55%",
      data: baner,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.bannersService.deleteBanner(result.id).subscribe(res => {
          this.openSnackBar("Baner usunięty", "OK", 3)
          this.ngOnInit()
        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
      }
    });
  }
}

@Component({
  selector: 'edit-banners',
  templateUrl: './edit-banner.component.html',
})
export class EditBannerComponent {
  constructor(
    public dialogRef: MatDialogRef<EditBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { link: string }
  ) { }

  newLink = this.data.link

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'img-banners',
  templateUrl: './img-banner.component.html',
})
export class ImgBannerComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EditBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private bannersService: BannersService
  ) {}
  ngOnInit(): void {
    this.loadImages()
  }
  images
  loadingImages
  serverUrl = environment.apiBaseUrl;
  loadImages() {
    this.loadingImages = true
    this.bannersService.getImages().subscribe(res => {
      console.log(res)
      this.images = res
      this.loadingImages = false
    }, err => {
      this.loadingImages = false
    })
  }
  selectIcon(image){
    this.data.image = image
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'delete-banner',
  templateUrl: './delete-banner.component.html',
})
export class DeleteBannerDialog {
  constructor(
    public dialogRef: MatDialogRef<EditBannerComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
