import { Component, OnInit } from '@angular/core';
import { ClientsService } from 'src/app/services/clients.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as moment from 'moment';
import { OrdersService } from 'src/app/services/orders.service';
import { ReclamationsService } from 'src/app/services/reclamations.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
  moment:any = moment;
  constructor(private clientsService:ClientsService,
     private ordersService:OrdersService,
     private reclamationsService:ReclamationsService,
      private _snackBar: MatSnackBar) { }
  loadingClients = true
  loadingOrders = true
  loagingReclamations = true
  loadingComments = true
  clients = []
  orders = []
  reclamations = []
  comments = []

  ngOnInit(): void {
    moment.locale('pl')
    this.clientsService.getClients(5).subscribe(res => {
      this.clients = res.body
      this.loadingClients = false
    },err => {
      this.openSnackBar(err.status != 0 ? err.error?.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
    this.ordersService.getAllOrders(5).subscribe(res => {
      this.orders = res.body
      this.loadingOrders = false
    })
    this.reclamationsService.getAll(5).subscribe(res => {
      this.reclamations = res.body
      this.loagingReclamations = false
    })
    this.ordersService.getRecentComments(5).subscribe(res => {
      this.comments = res.body
      this.loadingComments=false
    })

  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action,{
      duration: sbDuration * 1000,
    });
  }

}
