<mat-toolbar color="primary">
    <mat-toolbar-row>
        <span>Poligrafia</span>
    </mat-toolbar-row>
</mat-toolbar>
<mat-toolbar>
    <mat-toolbar-row>
     Admin Login
    </mat-toolbar-row>
</mat-toolbar>

  <mat-card id="my-card">

    <mat-card-content>
            
        <form id="my-form">
            <mat-form-field class="full-width">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Email" [(ngModel)]="loginData.email" name="email"  required>
            </mat-form-field>

            <mat-form-field class="full-width">
                <mat-label>Password</mat-label>
                <input matInput type="password" placeholder="Password" [(ngModel)]="loginData.password" name="password"  required>
            </mat-form-field>

        </form>
            
    </mat-card-content>
    <mat-card-actions>
        <button (click)="login()" id="card-form-button" mat-raised-button  color="primary">Login</button>
    </mat-card-actions>
    </mat-card>