import { Component, OnInit } from '@angular/core';
import { ResetService } from 'src/app/services/reset.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-reset',
    templateUrl: './reset.component.html',
    styleUrls: ['./reset.component.scss']
})
export class ResetComponent implements OnInit {

    resetData = {
        resetToken: "",
        password: ""
    }

    password2: any

    waitingForResponse = false;

    constructor(private route: ActivatedRoute, private reset: ResetService, private router: Router, private _snackBar: MatSnackBar) { }

    ngOnInit(): void {
    }

    openSnackBar(message: string, action: string, sbDuration?: number) {
        this._snackBar.open(message, action, {
            duration: sbDuration * 1000,
        });
    }

    passwordReset() {
        this.route.params.subscribe(params => {
            this.resetData.resetToken = params["token"]
            this.waitingForResponse = true;
            this.reset.passwordReset(this.resetData).subscribe(res => {
                this.router.navigate(["/"])
                this.waitingForResponse = false;
            }, err => {
                this.waitingForResponse = false;
                //TODO: zmienic na polski komunikat
                this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
            })
        })
    }

}
