import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http"
import { Router } from '@angular/router'
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    private _registerUrl = environment.apiBaseUrl+"/api/clients/"
    private _loginUrl = ""

  constructor(private http: HttpClient, private _router: Router) { }

  registerUser(registerData:any):Observable<any> {
    return this.http.post<any>(this._registerUrl, registerData, httpOptions)
  }

  loginUser(loginAddress:any, loginData:any):Observable<any> {
      this._loginUrl = environment.apiBaseUrl+loginAddress;
    return this.http.post(this._loginUrl, loginData, httpOptions)
  }

  logoutClient() {
    localStorage.removeItem('clientToken')
    this._router.navigate(['/'])
  }

  logoutAdmin() {
    localStorage.removeItem('adminToken')
    this._router.navigate(['/admin'])
  }

  getClientToken() {
    return localStorage.getItem('clientToken')
  }

  getAdminToken() {
    return localStorage.getItem('adminToken')
  }

  isClientLoggedIn() {
    return !!localStorage.getItem('clientToken')    
  }

  isAdminLoggedIn() {
    return !!localStorage.getItem('adminToken')    
  }
}
