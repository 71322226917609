import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(private http:HttpClient) { }

  getClientSales():Observable<any> {
    return this.http.get(environment.apiBaseUrl+"/api/sales");
  }

  getAdminSales():Observable<any> {
    return this.http.get(environment.apiBaseUrl+"/api/admin/sales");
  }

  getSingleSale(id):Observable<any> {
    return this.http.get(environment.apiBaseUrl+"/api/admin/sales/"+id);
  }

  createSale(sale):Observable<any>{
    return this.http.post(environment.apiBaseUrl+'/api/admin/sales', sale)
  }

  updateSale(sale):Observable<any>{
    return this.http.put(environment.apiBaseUrl+'/api/admin/sales/'+sale.id, sale)
  }

  deleteSale(id):Observable<any>{
    return this.http.delete(environment.apiBaseUrl+"/api/admin/sales/"+id)
  }
}
