import { Component, OnInit } from '@angular/core';
import { ResetService } from 'src/app/services/reset.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-reset-request',
    templateUrl: './reset-request.component.html',
    styleUrls: ['./reset-request.component.scss']
})
export class ResetRequestComponent implements OnInit {

    resetData = {
        email: ""
    }

    waitingForResponse = false;
    emailSent = false

    constructor(private reset: ResetService, private router: Router, private _snackBar: MatSnackBar) { }

    ngOnInit(): void {
    }

    openSnackBar(message: string, action: string, sbDuration?: number) {
        this._snackBar.open(message, action, {
            duration: sbDuration * 1000,
        });
    }

    requestPasswordReset() {
        this.waitingForResponse = true;
        this.reset.requestPasswordReset(this.resetData).subscribe(res => {
            // this.router.navigate(["/"])
            this.waitingForResponse = false;
            this.openSnackBar("Instrukcje przywracania dostępu do konta zostały wysłane na podany adres", "OK", 10)
        }, err => {
            this.waitingForResponse = false;
            //TODO: zmienic na polski komunikat
            this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
    }

}
