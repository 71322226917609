<mat-spinner style="margin: 0px auto" *ngIf="isLoading"></mat-spinner>
<div *ngIf="!isLoading" style="padding: 1rem;">
  <table>
    <tr>
      <th style="width: 60%;"></th>
      <th>Link</th>
      <th>Aktywny</th>
      <th></th>
    </tr>
    <tr *ngFor="let baner of banners; let i = index" [attr.data-index]="i">
      <td style="width: 60%;">
        <img style="width: 100%;" src="{{environment.apiBaseUrl}}/{{baner.image}}" (click)="openImgDialog(baner)"
          class="clickable">
      </td>
      <td>
        <button mat-icon-button matTooltip="Edytuj" (click)="openLinkDialog(baner)">
          <mat-icon>edit</mat-icon>
        </button>
        {{baner.link}}
      </td>
      <td>
        <button mat-stroked-button (click)="toggleActive(i, !baner.isActive)">
          {{baner.isActive ? "TAK" : "NIE"}}
        </button>
      </td>
      <td>
        <button mat-icon-button >
          <mat-icon (click)="openDeleteDialog(baner)">delete</mat-icon>
        </button>
      </td>
    </tr>
  </table>
  <br>
  <button mat-raised-button
    (click)="addingNewBanner = !addingNewBanner">{{addingNewBanner?"Ukryj":"Dodaj nowy baner"}}</button>
  <br>
  <div *ngIf="addingNewBanner">
    <mat-form-field class="full-width" appearance="standard">
      <mat-label>Link</mat-label>
      <input matInput placeholder="/" [(ngModel)]="newBanner.link" required>
    </mat-form-field>
    <p>Wybrane zdjęcie</p>
    <img style=" max-width: 90%; max-height: 128px" [src]="serverUrl + '/' + this.newBanner.image" />

    <div style="padding: 1rem;">
      <button mat-stroked-button (click)="fileInput.click()">
        Prześlij zdjęcie na serwer
      </button>
      <br />
      <div *ngIf="uploadUrl">
        <img class="preview" style="max-width: 80%; max-height: 128px" [src]="uploadUrl" />
        <br />
        <button color="accent" mat-raised-button (click)="uploadPhoto()">
          Prześlij
        </button>
      </div>
      <input hidden (change)="onFileSelected($event)" #fileInput type="file" id="file" />
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Wybór ze zdjęć na serwerze
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="productIcons">
            <div *ngFor="let image of images" (click)="selectIcon('assets/media/' + image)" [ngStyle]="{
                border:
                  this.newBanner.image == '/assets/media/' + image
                    ? '2px solid orange'
                    : '2px solid white'
              }">
              <img [src]="serverUrl + '/assets/media/' + image" style="max-width: 80%; max-height: 128px" />
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  <br>
  <button *ngIf="newBanner.image" color="primary" mat-raised-button (click)="addBanner()">
    Dodaj baner
  </button>
  <br><br>
</div>
