import { Component, OnInit, Inject } from '@angular/core';
import { OrdersService } from 'src/app/services/orders.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-admin-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class AdminOrdersComponent implements OnInit {
  // MatPaginator Inputs
  length = 100;
  pageSize = 15;
  pageSizeOptions: number[] = [5, 10, 15, 20, 30];
  pageIndex: number = 0
  // MatPaginator Output
  pageEvent: any;
  constructor(public dialog: MatDialog, private router: Router, private ordersService: OrdersService, private _snackBar: MatSnackBar,private route:ActivatedRoute) { }

  orders: any
  isLoadingOrders: boolean
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.pageSize = params["pageSize"] || this.pageSize
      this.pageIndex = params["pageIndex"] || this.pageIndex
    })
    this.loadOrders()
  }
  loadOrders(event?) {
    this.isLoadingOrders = true

    // load stuff
    this.pageSize = event ? event.pageSize : this.pageSize
    this.pageIndex = event ? event.pageIndex : this.pageIndex
    this.ordersService.getAllOrders(this.pageSize, this.pageIndex).subscribe(res => {
      this.orders = res.body
      this.length = res.headers.get('X-Total-Count')
      this.isLoadingOrders = false
    }, err => {
      this.isLoadingOrders = false
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })

    // set query params
    this.router.navigate(
      [],
      {
        queryParams: { pageSize: this.pageSize, pageIndex: this.pageIndex },
      });
  }

  parseParams(params: string) {
    if (params) {
      let parsedString = ''
      let specs = JSON.parse(params)
      Object.keys(specs).forEach(key => {
        parsedString += key + ": " + specs[key] + "\n"
      })
      return parsedString
    }
    return "Brak parametrów"
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
  openDeleteModal(id): void {
    const dialogRef = this.dialog.open(DeleteOrderModal, {
      width: '75%',
      data: { id: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(result)
        this.ordersService.deleteOrderAsAdmin(id).subscribe(res => {
          if(res.message){
            this.openSnackBar(res.message, "OK", 10)
          } else {
            this.openSnackBar("Usunięto zamówienie pomyślnie", "OK", 3)
            this.ngOnInit()
          }
        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
      }
    });
  }
}

@Component({
  selector: 'delete-modal',
  templateUrl: 'deleteModal.html',
})
export class DeleteOrderModal {

  constructor(
    public dialogRef: MatDialogRef<DeleteOrderModal>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
