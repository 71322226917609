<mat-spinner style="margin: 0px auto" *ngIf="waitingForResponse && !productError"></mat-spinner>
<h2 style="text-align: center; color: #FF6863" *ngIf="productError">{{productError}}</h2>
<div class="flex" *ngIf="!waitingForResponse">

  <!-- Druga kolumna -->
  <div class="column" id="second-column">
    <h1>{{product.name}}</h1>
    <mat-spinner style="margin: 0px auto" *ngIf="isTableLoading"></mat-spinner>
    <!-- Opis -->
    <div class="bloczek" *ngIf="product?.description != '' && product?.description != null">
      <div class="bloczek-head"><span class="title capitalize">{{product.name}}</span></div>
      <div class="bloczek-content">
        <div [innerHtml]="product?.description | safeHtml"></div>
      </div>
    </div>
        <!-- Pakowanie -->
        <div class="bloczek" *ngIf="product?.packing != ''">
          <div class="bloczek-head"><span class="title">Pakowanie</span></div>
          <div class="bloczek-content">
            <div [innerHtml]="product?.packing | safeHtml"></div>
          </div>
        </div>
    <div *ngIf="productUnavaliable" class="unavaliable">Produkt czasowo niedostępny</div>
    <div class="scrollable" *ngIf="!isTableLoading && foundVariant">
      <table mat-table class="full-width" [dataSource]="dataSource">
        <!-- Nakład -->
        <ng-container matColumnDef="naklad">
          <th mat-header-cell *matHeaderCellDef>
            <div class="nic-bloczek">
              <br>
            </div>
            Nakład
          </th>
          <td mat-cell *matCellDef="let element"> {{element[1].naklad}} </td>
        </ng-container>
        <!-- Standard -->
        <ng-container matColumnDef="cena_standard">
          <th mat-header-cell *matHeaderCellDef>
            <div [ngClass]="expressSelected ? 'other-bloczek clickable' : 'selected-bloczek clickable'"
              (click)="selectExpress(false)">
              <mat-icon>access_time</mat-icon>
              <br>
              <h2> Standardowy </h2>
              <p>
                Akceptując zamówienie do godziny {{selectedRow[1].godzina_standard}}, termin realizacji
                wynosi
                {{selectedRow[1].termin_standard}} dni robocze
              </p>
            </div>
            Cena netto <br> (Cena brutto)
          </th>
          <td mat-cell *matCellDef="let element" ngClass="clickable"
            [class.selected]="!expressSelected && selectedRow === element" (click)="selectCell(element, false)">
            <span *ngIf="element[1].promocja">{{element[1].cena_standard_promocja | currency:"PLN"}}*</span>
            <span *ngIf="!element[1].promocja">{{element[1].cena_standard | currency:"PLN"}}</span>
            <br>
            <span *ngIf="element[1].promocja">({{element[1].cena_standard_brutto_promocja | currency:"PLN"}})*</span>
            <span *ngIf="!element[1].promocja">({{element[1].cena_standard_brutto | currency:"PLN"}})</span>
          </td>
        </ng-container>
        <!-- Ekspres -->
        <ng-container matColumnDef="cena_ekspres">
          <th mat-header-cell *matHeaderCellDef>
            <div
              [ngClass]="{'selected-bloczek' : expressSelected, 'other-bloczek' : !expressSelected, 'clickable' : expressAvaliable}"
              (click)="selectExpress(true)">
              <mat-icon>timer</mat-icon>
              <br>
              <h2> Ekspresowy </h2>
              <p *ngIf="!expressAvaliable">Niedostępny w tym trybie produkcji.</p>
              <p *ngIf="expressAvaliable">
                Akceptując zamówienie do godziny {{selectedRow[1].godzina_ekspres}}, termin realizacji
                wynosi
                {{selectedRow[1].termin_ekspres}} dni robocze
              </p>
            </div>
            Cena netto <br> (Cena brutto)
          </th>
          <td mat-cell *matCellDef="let element" [ngClass]="element[1].cena_ekspres ? 'clickable' : ''"
            [class.selected]="expressSelected && selectedRow === element" (click)="selectCell(element, true)">
            <span *ngIf="element[1].cena_ekspres && element[1].promocja">{{element[1].cena_ekspres_promocja |
              currency:"PLN"}}*</span>
            <span *ngIf="element[1].cena_ekspres && !element[1].promocja">{{element[1].cena_ekspres |
              currency:"PLN"}}</span>
            <span *ngIf="!element[1].cena_ekspres">-</span>
            <br>
            <span *ngIf="element[1].cena_ekspres && element[1].promocja">({{element[1].cena_ekspres_brutto_promocja |
              currency:"PLN"}})*</span>
            <span *ngIf="element[1].cena_ekspres && !element[1].promocja">({{element[1].cena_ekspres_brutto |
              currency:"PLN"}})</span>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <p>* - Cena promocyjna, ograniczona czasowo. Sprawdź w zakładce PROMOCJE</p>
    </div>
  </div>

</div>