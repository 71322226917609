import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  constructor(private productsService: ProductsService) { }
  products = []
  imgUrl = environment.apiBaseUrl;
  loadingProducts:boolean

  ngOnInit(): void {
    this.loadingProducts = true
    this.productsService.getProducts().subscribe(res => {
      this.products = res.body
      this.loadingProducts = false
    })
  }

}
