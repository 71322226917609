import { Component, OnInit } from '@angular/core';
import { ProductsService } from 'src/app/services/products.service';
import { GroupsService } from 'src/app/services/groups.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-group-products',
  templateUrl: './group-products.component.html',
  styleUrls: ['./group-products.component.scss']
})
export class GroupProductsComponent implements OnInit {

  constructor(private groupsService: GroupsService,
    private _snackBar: MatSnackBar,
    private productsService: ProductsService,
    private route: ActivatedRoute) { }
  productsHere: any
  productsInGroup = []
  allProducts: any
  productsNotHere: any
  isLoading: boolean
  groupId: number
  ngOnInit(): void {
    this.isLoading = true
    this.productsService.getProductsAsAdmin().subscribe(res => {
      this.allProducts = res.body

      this.route.params.subscribe(params => {
        this.groupsService.getSingleGroupAsAdmin(params['guid']).subscribe(res => {
          this.productsHere = res.products
          //filter products that are already here out of all-products array
          this.productsNotHere = this.allProducts.filter(prod => {
            let isHere = false
            this.productsHere.forEach(p => {
              if (p.name === prod.name) {
                isHere = true
              }
            })
            if (isHere) {
              return false
            } else {
              return true
            }
          })

          this.groupId = res.id
          this.productsInGroup = []
          //creating array matching structure of group_product table
          this.productsHere.forEach(item => {
            //console.log(item)
            this.productsInGroup.push({
              groupId: this.groupId,
              productId: item.id,
              position: item.group_product.position
            })
          })
          this.isLoading = false
        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
          this.isLoading = false
        })
      })
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.productsHere, event.previousIndex, event.currentIndex);
    this.productsHere.forEach((item, index) => {
      this.productsHere[index].group_product.position = index
    })
    this.productsInGroup = []
    this.productsHere.forEach(item => {
      this.productsInGroup.push({
        groupId: this.groupId,
        productId: item.id,
        position: item.group_product.position
      })
    })
    this.update()
  }

  update() {
    // console.log(this.productsInGroup)
    this.productsService.updatePositions(this.groupId, this.productsInGroup).subscribe(res => {
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  remove(position) {
     //console.log(this.productsInGroup[position])
    this.productsService.removeProductFromGroup(this.productsInGroup[position]).subscribe(res => {
      this.openSnackBar("Usunięto produkt", "OK", 3)
      this.productsInGroup.splice(position, 1)
      let counter = 0;
      this.productsInGroup.forEach((prod, index)=>{
        this.productsInGroup[index].position = counter
        counter+=1;
      })
      this.update()
      this.ngOnInit()
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  add(id) {
    console.log(this.productsInGroup)
    let newObj = {
      groupId: this.groupId,
      productId: id,
      position: this.productsInGroup.length
    }
    console.log(newObj)
    this.productsService.addProductToGroup(newObj).subscribe(res => {
      this.openSnackBar("Dodano produkt", "OK", 3)
      this.ngOnInit()
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

}
