import { Component, OnInit } from '@angular/core';
import { SalesService } from 'src/app/services/sales.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductsService } from 'src/app/services/products.service';

@Component({
  selector: 'app-create-sale',
  templateUrl: './create-sale.component.html',
  styleUrls: ['./create-sale.component.scss']
})
export class CreateSaleComponent implements OnInit {

  isLoading = false
  isLoadingVariants = false
  sale:any = {}
  products:any
  selectedProduct:any
  Object = Object
  checkedBoxes = {}

  public Editor = ClassicEditor;

  constructor(private salesService:SalesService, private productsService:ProductsService, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.sale["description"]=" "
    this.isLoading = true
    this.productsService.getProducts().subscribe(response => {
      this.isLoading = false
      this.products = response.body
    })
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }

  resetVariants(){
    this.sale.sale_variants=[]
  }

  selectAll(param){
    Object.keys(this.selectedProduct.configs.rodzic[param]).forEach(el=>{
      this.checkedBoxes[el]=true;
    })
  }

  getVariants(product){
    this.isLoadingVariants = true;

    //Pobieranie produktu

    this.productsService.getSingleProduct(product.guid).subscribe(res=>{
      this.selectedProduct = res
      //Utworzenie zmiennej dla checkboxów

      Object.keys(this.selectedProduct.configs.cecha_rodzic).forEach(key=>{
        this.checkedBoxes[key] = false;
      })

      //Zaznaczenie parametrów na podstawie istniejących w zapisanej promocji wariantów

      this.sale.sale_variants.forEach(element => {
        if(this.selectedProduct.configs.grupa[element.variantId]){
          Object.keys(this.selectedProduct.configs.grupa[element.variantId]).forEach(feature=>{
            this.checkedBoxes[feature]=true
          })
        }
      });

      this.isLoadingVariants = false;
    })
  }

  fetch(){
    let tempSV = [];

    Object.keys(this.checkedBoxes).forEach(key=>{
      if(this.checkedBoxes[key]){
        tempSV.push(key)
      }
    })

    let grupy = []
    grupy = Object.keys(this.selectedProduct.configs.grupa).map(i=>[i,Object.keys(this.selectedProduct.configs.grupa[i])])

    //Zastąpienie istniejących w promocji wariantów na wybrane

    this.resetVariants()

    grupy.forEach(arr=>{
      if(arr[1].every(el=>tempSV.includes(el))){
        this.sale.sale_variants.push({variantId:arr[0]})
      }
    })

  }

  create(){

    //Wywołanie funkcji przekładającej parametry na warianty

    this.fetch()

    //Zaaktualizowanie promocji
    this.sale["productGuid"] = this.selectedProduct.guid
    if(typeof(this.sale._to)=="object"){
      this.sale._to = new Date( this.sale._to.getTime() + (60 * 60 * 1000 * 24) - 1000 )
    }
    this.salesService.createSale(this.sale).subscribe(res => {
      if(res.message){
        this.openSnackBar(res.message, "OK", 3)
      }else{
        this.openSnackBar("Utworzono promocje", "OK", 3)
      }
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

}
