import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
}
@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http:HttpClient) { }
  getAllOrders(limit?, page?): Observable<any> {
    let params = {}
    if (limit) {
      params['limit'] = limit
    }
    if (page) {
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl + "/api/admin/orders", { params, observe: 'response' });
  }
  getListOfDeliveryTypes(code):Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/clients/orders/ListOfDeliveryTypes/"+code);
  }

  getRecentComments(limit?, page?):Observable<any>{
    let params = {}
    if (limit) {
      params['limit'] = limit
    }
    if (page) {
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl + "/api/admin/orders/comments", { params, observe: 'response' });
  }

  getClientOrders(limit?, page?): Observable<any> {
    let params = {}
    if (limit) {
      params['limit'] = limit
    }
    if (page) {
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl + "/api/clients/orders", { params, observe: 'response' });
  }

  getClientOrdersByStatus(status, limit?, page?): Observable<any> {
    let params = {}
    if (limit) {
      params['limit'] = limit
    }
    if (page) {
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl + "/api/clients/orders/status/" + status, { params, observe: 'response' });
  }

  postponement(id): Observable<any> {
    return this.http.post(environment.apiBaseUrl + "/api/clients/orders/postponement/" + id, {});
  }

  getOrderComments(id_dk):Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/clients/orders/"+id_dk+"/comments");
  }
  getOrderCommentsAsAdmin(id_dk):Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/admin/orders/"+id_dk+"/comments");
  }

  getOrder(id_dk):Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/clients/orders/"+id_dk+"");
  }

  getOrderAsAdmin(id):Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/admin/orders/"+id+"");
  }
  getOrderReclamation(id):Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/admin/orders/"+id+"/reclamation");
  }

  getCode(code):Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/codes/"+code);
  }

  createReclamation(orderId, data):Observable<any>{
    return this.http.post(environment.apiBaseUrl+"/api/clients/orders/"+orderId+"/reclamation", data);
  }

  getReclamation(orderId):Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/clients/orders/"+orderId+"/reclamation");
  }

  getInvoice(orderId):Observable<any>{
    return this.http.get(environment.apiBaseUrl+"/api/clients/orders/"+orderId+"/invoice");
  }

  registerOrder(order):Observable<any>{
    return this.http.post(environment.apiBaseUrl+"/api/clients/orders", order);
  }

  editOrderName(order):Observable<any>{
    return this.http.put(environment.apiBaseUrl+"/api/clients/orders/" + order.id, order);
  }

  deleteOrder(id):Observable<any>{
    return this.http.delete(environment.apiBaseUrl+"/api/clients/orders/" + id);
  }

  deleteOrderAsAdmin(id):Observable<any>{
    return this.http.delete(environment.apiBaseUrl+"/api/admin/orders/" + id);
  }

  addComment(id_dk, content: String):Observable<any>{
    return this.http.post(environment.apiBaseUrl+"/api/clients/orders/" + id_dk + "/addComment", {content})
  }
}
