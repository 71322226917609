import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { SettingsService } from './services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private titleService: Title, private metaTagService: Meta, private settingsService:SettingsService) {}

  ngOnInit(){
    this.settingsService.getSettingsGroup("seo").subscribe(res=>{
      this.titleService.setTitle(res[0].value);
      this.metaTagService.addTags([
        { name: 'keywords', content: res[1].value },
        { name: 'description', content: res[2].value },
        { name: 'robots', content: 'index, follow' },
        { name: 'author', content: 'Nikodem Zawirski, Adrian Sitko' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'date', content: '2020-04-07', scheme: 'YYYY-MM-DD' },
        { charset: 'UTF-8' }
      ]);
    })

  }

}
