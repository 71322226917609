<div style="padding: 1rem;">
  <h1>{{page?.title}}</h1>
  <div *ngIf="!waitingForResponse"  [innerHTML]="page?.content | safeHtml"> </div>
  <app-register *ngIf="guid=='rejestracja'"></app-register>
  <app-account *ngIf="guid=='moje_konto'"></app-account>
  <app-products *ngIf="guid=='produkty'"></app-products>
  <app-sales *ngIf="guid=='promocje'"></app-sales>
  <app-reset-request *ngIf="guid=='reset'"></app-reset-request>
</div>

