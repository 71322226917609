import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductsService } from 'src/app/services/products.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-variants',
  templateUrl: './variants.component.html',
  styleUrls: ['./variants.component.scss']
})
export class VariantsComponent implements OnInit {

  Object = Object
  product: any
  products: any = []
  selectedProductGuid = ""

  selects: any = {}
  waitingForResponse = false
  foundVariant = false
  productUnavaliable: boolean = false

  // Zmienne do utworzenia tabeli
  isTableLoading = false
  variantId: any
  variantObject: any = {}
  dataSource: any
  displayedColumns: string[] = ['naklad', 'cena_standard', 'cena_ekspres'];

  //Wybrany nakład
  selectedRow: any

  expressAvaliable: boolean = false
  expressSelected: boolean = false

  customWidth: 0;
  customHeight: 0;
  isCustomSelected = false;
  custom = ""
  formats: any = []

  constructor(private _snackBar: MatSnackBar, private productsService: ProductsService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.selectedProductGuid = params['guid']
      this.productsService.getProductsAsAdmin().subscribe(products => {
        this.products = products.body;

        this.productsService.getSingleProductAsAdmin(params['guid']).subscribe(res => {
          this.product = res
          if (this.product.error) {
            this.productUnavaliable = true
          } else {
            if (this.product.formaty) {
              this.customHeight = this.product.format.height.min;
              this.customWidth = this.product.format.width.min
              Object.keys(this.product.formaty).forEach(id => {
                this.formats.push(id)
              })
            }
            if (this.product.defaultSettings != "") {
              Object.keys(this.product.configs.rodzic).forEach((parent) => {
                Object.keys(this.product.configs.grupa[this.product.defaultSettings]).forEach((defSet) => {
                  if(Object.keys(this.product.configs.rodzic[parent]).includes(defSet)){
                    this.selects[parent] = defSet
                  }
                });
                // console.log(this.selects[parent])
              });
            } else{
              Object.keys(this.product.configs.rodzic).forEach(parent => {
                this.selects[parent] = Object.entries(this.product.configs.rodzic[parent])[0][0]
              })
            }

            this.onChange()
          }
          console.log(this.product)
          this.waitingForResponse = false

        })

      })
    })
  }

  onChange() {
    // czary mary
    let selectedVariants = {}
    this.custom = this.customWidth + "x" + this.customHeight;

    Object.keys(this.selects).forEach(item => {
      if (this.selects[item] == -1) {
        selectedVariants[Object.keys(this.product.configs.rodzic[item])[0]] = Object.keys(this.product.configs.rodzic[item])[0]
        this.isCustomSelected = true;
      } else {
        selectedVariants[this.selects[item]] = this.selects[item]
      }
    })
    this.foundVariant = false
    Object.keys(this.product.configs.grupa).forEach(group => {
      if (JSON.stringify(this.product.configs.grupa[group]) === JSON.stringify(selectedVariants)) {
        this.variantId = group
        this.dataSource = null
        this.isTableLoading = true
        this.foundVariant = true
      }
    })
    if (this.foundVariant) {

      // Pobranie nakładu na wariant
      this.productsService.getAmountForVariant(this.product.id_dk, this.variantId, this.isCustomSelected ? this.custom : null).subscribe(res => {
        this.Object.keys(res).forEach(key => {
          if (res[key].promocja) {
            res[key]["cena_standard_promocja"] = res[key].cena_standard * (1 + ((res[key].marza / 100) * (1 - res[key].promocjaWartosc / 100)))
            res[key]["cena_ekspres_promocja"] = res[key].cena_ekspres * (1 + ((res[key].marza / 100) * (1 - res[key].promocjaWartosc / 100)))
            res[key]["cena_standard_brutto_promocja"] = res[key]["cena_standard_promocja"] * (1 + (res[key].vat / 100))
            res[key]["cena_ekspres_brutto_promocja"] = res[key]["cena_ekspres_promocja"] * (1 + (res[key].vat / 100))
          }

          res[key].cena_standard_marzowane = res[key].cena_standard * (1 + (res[key].marza / 100))
          res[key].cena_ekspres_marzowane = res[key].cena_ekspres * (1 + (res[key].marza / 100))
          res[key].cena_standard_brutto = res[key].cena_standard * (1 + (res[key].vat / 100))
          res[key].cena_ekspres_brutto = res[key].cena_ekspres * (1 + (res[key].vat / 100))

        })
        this.dataSource = Object.entries(res)

        this.isTableLoading = false;
        if (this.dataSource[0][1]['cena_ekspres'] > 0) {
          this.expressAvaliable = true
        }
      })
    }
  }
  changeProduct(){
    window.location.href = './admin-panel/products/variants/'+this.selectedProductGuid
  }

  saveDefault(){
    // console.log(this.product)
    this.productsService.updateProduct(this.product.guid,{ guid: this.product.guid, defaultSettings: this.variantId}).subscribe(res => {
      this.openSnackBar("Zaktualizowano produkt pomyślnie", "OK", 3)
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
}
