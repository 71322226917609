import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  getProducts(limit?, page?): Observable<any> {
    let params = {}
    if (limit) {
      params['limit'] = limit
    }
    if (page) {
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl + "/api/products", { params, observe: 'response' });
  }
  getProductsAsAdmin(limit?, page?): Observable<any> {
    let params = {}
    if (limit) {
      params['limit'] = limit
    }
    if (page) {
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl + "/api/admin/products", { params, observe: 'response' });
  }

  getSingleProduct(guid): Observable<any> {
    return this.http.get(environment.apiBaseUrl + "/api/products/" + guid)
  }

  getSingleProductAsAdmin(guid): Observable<any> {
    return this.http.get(environment.apiBaseUrl + "/api/admin/products/" + guid)
  }

  getAmountForVariant(productId, variantId, custom?): Observable<any> {
    if (custom) {
      return this.http.get(environment.apiBaseUrl + "/api/products/" + productId + "/" + variantId + "/" + custom)
    } else {
      return this.http.get(environment.apiBaseUrl + "/api/products/" + productId + "/" + variantId)
    }
  }

  getParams(productId, variantId, custom?) {
    if (custom) {
      return this.http.get(environment.apiBaseUrl + "/api/products/params/" + productId + "/" + variantId + "/" + custom)
    } else {
      return this.http.get(environment.apiBaseUrl + "/api/products/params/" + productId + "/" + variantId)
    }
  }

  toggleActiveProduct(guid, active): Observable<any> {
    return this.http.put(environment.apiBaseUrl + '/api/admin/products/' + guid, {
      active
    })
  }

  updateProduct(guid, product): Observable<any> {
    return this.http.put(environment.apiBaseUrl + '/api/admin/products/' + guid, product)
  }
  updatePositions(groupId, products): Observable<any> {
    return this.http.put(environment.apiBaseUrl + "/api/admin/products/updatePositions/" + groupId, products)
  }
  addProductToGroup(object): Observable<any> {
    return this.http.put(environment.apiBaseUrl + "/api/admin/products/updatePositions/add", object)
  }
  removeProductFromGroup(object): Observable<any> {
    return this.http.put(environment.apiBaseUrl + "/api/admin/products/updatePositions/remove", object)
  }
}
