import {
  Component,
  OnInit,
  Inject,
  Renderer2,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { AccountService } from "src/app/services/account.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";

import { DOCUMENT } from "@angular/common";
import { get } from "scriptjs";
import { ProductsService } from "src/app/services/products.service";
import { OrdersService } from "src/app/services/orders.service";
import { SettingsService } from "src/app/services/settings.service";
import { PaymentsService } from "src/app/services/payments.service";
declare var $: any;
import { environment } from "../../../../environments/environment";
import { ReclamationsService } from 'src/app/services/reclamations.service';
export interface DialogData {
  id: string;
  firstName: string;
  lastName: string;
  companyName: string;
  address: string;
  postalCode: string;
  city: string;
  phone: string;
}

@Component({
  selector: "app-account",
  templateUrl: "./account.component.html",
  styleUrls: ["./account.component.scss"],
})
export class AccountComponent implements OnInit {
  account: any = {};
  panelOpenState = 0;
  newPassword = "";
  password2 = "";
  ordersArray: any = {
    oczekujace: [],
    wRealizacji: [],
    zrealizowane: [],
    o: 0,
    w: 0,
    z: 0,
  };
  invoices: any;
  invoicesLength: number;
  pageSizeInv = 10;
  pageSizeOptionsInv: number[] = [5, 10, 15, 20, 30];

  Object = Object;
  JSON = JSON;

  vat = 0;

  waitingForResponse = false;

  newAddress: any = {};

  openOrder: any = {
    oczekujace: 0,
    wRealizacji: 0,
    zrealizowane: 0,
  };
  apiBaseUrl = environment.apiBaseUrl;

  // MatPaginator Inputs
  // length = 100;
  pageSize = 5;
  pageSizeOptions: number[] = [3, 5, 10, 15, 20];
  pageIndexO: number = 0;
  pageIndexW: number = 0;
  pageIndexZ: number = 0;
  pageIndexInv: number = 0;
  // MatPaginator Output
  pageEvent: any;

  firstLoad = true;

  isPanelExpanded = false;
  environment = environment;
  constructor(
    private _router: Router,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog,
    private auth: AuthService,
    private ordersService: OrdersService,
    private accountService: AccountService,
    private settingsService: SettingsService
  ) { }

  loadO(event?) {
    this.pageSize = event ? event.pageSize : this.pageSize;
    this.pageIndexO = event ? event.pageIndex : this.pageIndexO;

    this.ordersService
      .getClientOrdersByStatus("o", this.pageSize, this.pageIndexO)
      .subscribe(
        (res) => {
          this.ordersArray.oczekujace = res.body;
          this.ordersArray.o = res.headers.get("X-Total-Count");
          if (this.ordersArray.o > 0) {
            this.isPanelExpanded = true;
          }
          //get miniaturki
          this.ordersArray.oczekujace.forEach((order, index) => {
            // console.log(order.order_dk_id)
            this.ordersService.getOrder(order.order_dk_id).subscribe(res => {

              this.ordersArray.oczekujace[index].result = res.result

            },err => {
              console.log("Nie można załadować miniaturki")
            })
          });
        },
        (err) => {
          this.openSnackBar(
            err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
            "OK",
            10
          );
        }
      );
  }
  loadW(event?) {
    this.pageSize = event ? event.pageSize : this.pageSize;
    this.pageIndexW = event ? event.pageIndex : this.pageIndexW;

    this.ordersService
      .getClientOrdersByStatus("w", this.pageSize, this.pageIndexW)
      .subscribe(
        (res) => {
          this.ordersArray.wRealizacji = res.body;
          this.ordersArray.w = res.headers.get("X-Total-Count");
          //get miniaturki
          this.ordersArray.wRealizacji.forEach((order, index) => {
            this.ordersService.getOrder(order.order_dk_id).subscribe(res => {
              this.ordersArray.wRealizacji[index].result = res.result
            })
          });
        },
        (err) => {
          this.openSnackBar(
            err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
            "OK",
            10
          );
        }
      );
  }
  couriers = {
    GLS: "https://gls-group.eu/PL/pl/sledzenie-paczek?match=",
    DPD: "https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=",
  };
  loadZ(event?) {
    this.pageSize = event ? event.pageSize : this.pageSize;
    this.pageIndexZ = event ? event.pageIndex : this.pageIndexZ;

    this.ordersService
      .getClientOrdersByStatus("z", this.pageSize, this.pageIndexZ)
      .subscribe(
        (res) => {
          this.ordersArray.zrealizowane = res.body;
          this.ordersArray.z = res.headers.get("X-Total-Count");
          //get miniaturki
          this.ordersArray.zrealizowane.forEach((order, index) => {
            this.ordersService.getOrder(order.order_dk_id).subscribe(res => {
              this.ordersArray.zrealizowane[index].result = res.result
              // console.log(this.ordersArray.zrealizowane[index])
              if (this.couriers[res.result.adresy[0].send_by]) {
                this.ordersArray.zrealizowane[index].sledzenie =
                  this.couriers[res.result.adresy[0].send_by] +
                  this.ordersArray.zrealizowane[index].result.adresy[0].package_number;
              }
            })
          });
        },
        (err) => {
          this.openSnackBar(
            err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
            "OK",
            10
          );
        }
      );
  }
  loadInvoices(event?) {
    this.pageSizeInv = event ? event.pageSize : this.pageSizeInv;
    this.pageIndexInv = event ? event.pageIndex : this.pageIndexInv;

    this.accountService
      .getClientInvoices(this.pageSizeInv, this.pageIndexInv)
      .subscribe(
        (res) => {
          this.invoices = res.body;
          this.invoicesLength = res.headers.get("X-Total-Count");
        },
        (err) => {
          this.openSnackBar(
            err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
            "OK",
            10
          );
        }
      );
  }
  ngOnInit(): void {
    if (!this.auth.isClientLoggedIn()) {
      this._router.navigate(["/"]);
    }
    this.waitingForResponse = true;
    this.loadO();
    this.loadW();
    this.loadZ();
    this.loadInvoices();
    this.accountService.getClient().subscribe((accountData) => {
      this.account = accountData;

      if (this.firstLoad == true) {
        $(document).on("click", ".jsPreflightPageRotateRight", function () {
          const $pageRotateOptions = $(this).parents(".options");

          if (
            $pageRotateOptions.find("input[name=rotate]:checked").length === 0
          ) {
            $pageRotateOptions.find("input[name=rotate]:nth(1)").click();
          } else if (
            $pageRotateOptions.find("input[name=rotate]")[3].checked === true
          ) {
            $pageRotateOptions.find("input[name=rotate]:nth(0)").click();
          } else {
            $pageRotateOptions
              .find("input[name=rotate]:checked")
              .next("input[name=rotate]")
              .click();
          }
        });

        $(document).on("click", ".jsPreflightPageRotateLeft", function () {
          const $pageRotateOptions = $(this).parents(".options");

          if (
            $pageRotateOptions.find("input[name=rotate]:checked").length === 0
          ) {
            $pageRotateOptions.find("input[name=rotate]:nth(3)").click();
          } else if (
            $pageRotateOptions.find("input[name=rotate]")[0].checked === true
          ) {
            $pageRotateOptions.find("input[name=rotate]:nth(3)").click();
          } else {
            $pageRotateOptions
              .find("input[name=rotate]:checked")
              .prev("input[name=rotate]")
              .click();
          }
        });

        $(document).on("mouseover", ".qq-upload-button", function () {
          $(this)[0].children[1].title = "Umieść pliki tutaj";
        });

        $(document).on("click", ".jsBleed", function () {
          const $pageRotateOptions = $(this).parents(".options");
          if ($pageRotateOptions.find("input[name=bleed]")[0].checked) {
            $(this).removeClass("checked");
            $(this).addClass("notChecked");
            $pageRotateOptions.find("input[name=bleed]")[0].click();
          } else {
            $(this).removeClass("notChecked");
            $(this).addClass("checked");
            $pageRotateOptions.find("input[name=bleed]")[0].click();
          }
        });

        $(document).on("click", ".jsScale", function () {
          const $pageRotateOptions = $(this).parents(".options");
          if ($pageRotateOptions.find("input[name=scale]")[0].checked) {
            $(this).removeClass("checked");
            $(this).addClass("notChecked");
            $pageRotateOptions.find("input[name=scale]")[0].click();
          } else {
            $(this).removeClass("notChecked");
            $(this).addClass("checked");
            $pageRotateOptions.find("input[name=scale]")[0].click();
          }
        });
        this.firstLoad = false;
      }
      this.waitingForResponse = false;
    });
  }

  openDeleteUserModal(): void {
    const dialogRef = this.dialog.open(DeleteUserModal, {
      width: '75%',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result)
        this.accountService.disableClient().subscribe(res => {
          this.openSnackBar("Usunięto konto pomyślnie", "OK", 3)
          this.auth.logoutClient()
        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
      }
    });
  }

  openDeleteOrderDialog(id) {
    const dialogRef = this.dialog.open(DeleteDialog, {
      width: "75%",
      data: { id: id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteOrder(result.id);
      }
    });
  }

  deleteOrder(id) {
    this.ordersService.deleteOrder(id).subscribe((res) => {
      if (res.message) {
        this.openSnackBar(res.message, "OK", 10);
      } else {
        this.ngOnInit();
        this.openSnackBar("Usunięto zamówienie", "OK", 3);
      }
    });
  }

  updateAccountData() {
    this.accountService.updateClient(this.account).subscribe(
      (res) => {
        if (res.message) {
          this.openSnackBar(res.message, "OK", 10);
        } else {
          this.ngOnInit();
          this.openSnackBar("Zapisano dane konta", "OK", 3);
        }
      },
      (err) => {
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      }
    );
  }

  updateAccountLoginAndPass() {
    this.accountService
      .updateClient({ email: this.account.email, password: this.newPassword })
      .subscribe(
        (res) => {
          if (res.message) {
            this.openSnackBar(res.message, "OK", 10);
          } else {
            this.ngOnInit();
            this.openSnackBar("Zapisano dane konta", "OK", 3);
          }
        },
        (err) => {
          this.openSnackBar(
            err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
            "OK",
            10
          );
        }
      );
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }

  openDialog(address): void {
    const dialogRef = this.dialog.open(AddressDialog, {
      width: "75%",
      data: address,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.waitingForResponse = true;
        this.accountService.putAddress(result).subscribe(
          (res) => {
            this.waitingForResponse = false;
            this.openSnackBar("Zaaktualizowano adres", "OK", 3);
          },
          (err) => {
            this.waitingForResponse = false;

            this.openSnackBar(
              err.status != 0
                ? err.error.message
                : "Serwer chwilowo niedostępny",
              "OK",
              10
            );
          }
        );
      }
    });
  }

  togglePanel(number) {
    if (this.panelOpenState == number) {
      this.panelOpenState = 0;
    } else {
      this.panelOpenState = number;
    }
  }

  createAddress() {
    this.accountService.postAddress(this.newAddress).subscribe(
      (res) => {
        this.ngOnInit();
        this.openSnackBar("Utworzono adres", "OK", 3);
      },
      (err) => {
        this.waitingForResponse = false;
        //TODO: zmienic na polski komunikat
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      }
    );
  }

  openDeleteDialog(id) {
    const dialogRef = this.dialog.open(DeleteDialog, {
      width: "85%",
      data: { id: id },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteAddress(result.id);
      }
    });
  }

  postpone(id) {
    this.ordersService.postponement(id).subscribe(
      (res) => {
        if (res.message) {
          this.ngOnInit();
          this.openSnackBar(res.message, "OK", 3);
        }
      },
      (err) => {
        this.waitingForResponse = false;
        //TODO: zmienic na polski komunikat
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      }
    );
  }

  deleteAddress(id) {
    this.accountService.deleteAddress(id).subscribe(
      (res) => {
        this.ngOnInit();
      },
      (err) => {
        this.waitingForResponse = false;
        //TODO: zmienic na polski komunikat
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      }
    );
  }

  openFileDialog(orderId, url, id, productId, variantId, naklad, fileStatus) {
    const dialogRef = this.dialog.open(FileDialog, {
      width: "85%",

      data: {
        orderId: orderId,
        url: url,
        id: id,
        productId: productId,
        variantId: variantId,
        naklad: naklad,
        fileStatus: fileStatus,
      },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.ngOnInit();
    });
  }

  openReclamationDialog(orderId, email) {
    const dialogRef = this.dialog.open(ReclamationDialog, {
      width: "85%",

      data: { orderId, email },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openSnackBar(result, "OK", 3);
      }
      this.ngOnInit();
    });
  }

  openDetailsDialog(order) {
    const dialogRef = this.dialog.open(OrderDetailsDialog, {
      width: "85%",

      data: { order: order },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

  openPaymentDialog(orders, id) {
    const dialogRef = this.dialog.open(PaymentDialog, {
      width: "85%",

      data: { orders: orders, selected: id, client: this.account },
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }
}

@Component({
  selector: "address-dialog",
  templateUrl: "address-dialog.html",
})
export class AddressDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AddressDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "delete-dialog",
  templateUrl: "delete-dialog.html",
})
export class DeleteDialog {
  constructor(
    public dialogRef: MatDialogRef<DeleteDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { id: string }
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "file-dialog",
  templateUrl: "file-dialog.html",
  styleUrls: ["./file-dialog.scss"],
})
export class FileDialog implements AfterViewInit {
  console = console;
  isLoading = true;

  bleed: boolean = false;
  scale = false;
  amountForVariant: any = {};
  url: any;

  isFileAccepted = false;
  environment = environment;
  constructor(
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<FileDialog>,
    private productsService: ProductsService,
    private ordersService: OrdersService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      orderId: string;
      url: string;
      id: string;
      productId: string;
      variantId;
      naklad: string;
      fileStatus: string;
    },
    @Inject(DOCUMENT) private _document: Document
  ) { }

  ngAfterViewInit(): void {
    this.productsService
      .getAmountForVariant(this.data.productId, this.data.variantId)
      .subscribe((res) => {
        Object.keys(res).forEach((element) => {
          if (res[element].naklad == this.data.naklad) {
            this.amountForVariant = res[element];
          }
        });
      });

    window["user"] = {
      admin_id: 1,
      name: "JZ",
      email: "cygi.net@gmail.com",
      shop: "api.drukarnia.j13.pl",
    };

    // Timer w tym momencie jest wyłączony!!
    if (typeof window["AjaxLoader"] != "function") {
      function AjaxLoader(contener) {
        function remove() {
          this.isLoading = false;
        }
        this.remove = remove;
        return this;
      }
      window["AjaxLoader"] = AjaxLoader;
    }

    get("https:" + this.data.url, () => {
      this.isLoading = false;
    });

    if (this.data.fileStatus == "Zaakceptowany") {
      setTimeout(() => (this.isFileAccepted = true), 1000);
    }

    this.checked();
  }

  checked() {
    setTimeout(() => {
      for (let i = 0; i < $("input[name='bleed']").length; i++) {
        if ($("input[name='bleed']")[i].checked) {
          $("button.jsBleed").eq(i).removeClass("notChecked");
          $("button.jsBleed").eq(i).addClass("checked");
        }
        if ($("input[name='scale']")[i].checked) {
          $("button.jsScale").eq(i).removeClass("notChecked");
          $("button.jsScale").eq(i).addClass("checked");
        }
      }
    }, 1000);
  }

  accepted() {
    this.ordersService.editOrderName({id: this.data.orderId, file_status: "Zaakceptowany"}).subscribe(res=>{

    })
    this.isFileAccepted = true;
  }
}

@Component({
  selector: "payment-dialog",
  templateUrl: "payment-dialog.html",
  styleUrls: ["./payment-dialog.scss"],
})
export class PaymentDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<PaymentDialog>,
    private paymentsService: PaymentsService,
    @Inject(MAT_DIALOG_DATA)
    public data: { orders: any; selected: any; client: any }
  ) { }
  // selectedOrders:any
  finalPrice: any = 0;
  hasError: boolean = false;
  errorMessage = "";

  ngOnInit(): void {
    // console.log(this.data.client)
    this.data.orders.forEach((element) => {
      if (element.id == this.data.selected) {
        element.selected = true;
      } else {
        element.selected = false;
      }
    });
    this.onChange();
  }

  onChange() {
    this.finalPrice = 0;
    this.data.orders.forEach((element) => {
      if (element.selected) {
        this.finalPrice +=
          element.cena * (1 + (element.vat > 0 ? element.vat / 100 : 23 / 100));
      }
    });
  }
  pay(): void {
    this.hasError = false;
    let client = {
      email: this.data.client.email,
      phone: this.data.client.phone,
      first_name: this.data.client.first_name,
      last_name: this.data.client.last_name,
    };
    let orders = [];
    this.data.orders.forEach((element) => {
      if (element.selected) {
        orders.push({
          id: element.id,
          main_product_name: element.main_product_name,
          cena: element.cena,
          naklad: element.naklad,
          vat: element.vat,
        });
      }
    });

    this.paymentsService.pay({ client, orders }).subscribe(
      (res) => {
        window.location.replace(res.redirectUri);
      },
      (err) => {
        console.log(err);
        this.hasError = true;
        this.errorMessage = err.error.status.statusDesc;
      }
    );
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "reclamation-dialog",
  templateUrl: "reclamation-dialog.html",
  styleUrls: ["./reclamation-dialog.scss"],
})
export class ReclamationDialog {
  defects = [
    "Błędna kolorystyka",
    "Nieprawidłowe klejenie",
    "Nieprawidłowa ilość lub kolejność stron",
    "Błędny format",
    "Nieprawidłowe uszlachetnianie",
    "Opóźnienie lub uszkodzenie przesyłki w transporcie",
    "Nieprawidłowa oprawa",
    "Nieprawidłowe sztancowanie",
    "Nieprawidłowe przycięcie",
    "Nieprawidłowe falcowanie",
    "Niewłaściwy surowiec",
    "Braki w nakładzie",
    "Inne",
  ];

  reason = "";
  description = "";

  constructor(
    public dialogRef: MatDialogRef<ReclamationDialog>,
    private ordersService: OrdersService,
    @Inject(MAT_DIALOG_DATA) public data: { orderId: any; email: any }
  ) { }

  sendReclamation() {
    this.ordersService
      .createReclamation(this.data.orderId, {
        reason: this.reason,
        description: this.description,
        email: this.data.email,
      })
      .subscribe(
        (response) => {
          if (!response.message) {
            this.dialogRef.close("Złożono reklamację");
          } else {
            this.dialogRef.close(response.message);
          }
        },
        (err) => {
          this.dialogRef.close(err.error.message);
        }
      );
  }
}

@Component({
  selector: "order-details-dialog",
  templateUrl: "order-details-dialog.html",
  styleUrls: ["./order-details-dialog.scss"],
})
export class OrderDetailsDialog implements OnInit {
  isLoadingComments = true;
  hasReclamation = false;
  isLoadingOrder = true;
  isLoadingInvoice = true
  params: any = {};
  Object = Object;
  JSON = JSON
  comments: any = [];
  orderDk: any;
  environment = environment;
  reclamation: any = {};
  invoice: any = {};
  couriers = {
    GLS: "https://gls-group.eu/PL/pl/sledzenie-paczek?match=",
    DPD: "https://tracktrace.dpd.com.pl/parcelDetails?typ=1&p1=",
  };
  defects = [
    "Błędna kolorystyka",
    "Nieprawidłowe klejenie",
    "Nieprawidłowa ilość lub kolejność stron",
    "Błędny format",
    "Nieprawidłowe uszlachetnianie",
    "Opóźnienie lub uszkodzenie przesyłki w transporcie",
    "Nieprawidłowa oprawa",
    "Nieprawidłowe sztancowanie",
    "Nieprawidłowe przycięcie",
    "Nieprawidłowe falcowanie",
    "Niewłaściwy surowiec",
    "Braki w nakładzie",
    "Inne",
  ];

  reason = "";
  description = "";
  newReclamationComment: any = {};
  apiBaseUrl = environment.apiBaseUrl;
  account:any

  isEditingName:boolean=false

  constructor(
    public dialogRef: MatDialogRef<OrderDetailsDialog>,
    private accountService: AccountService,
    private ordersService: OrdersService,
    private reclamationsService: ReclamationsService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: { order: any }
  ) { }

  toggleEditName(){
    this.isEditingName = !this.isEditingName
  }

  saveCustomName(){
    this.ordersService.editOrderName(this.data.order).subscribe((response) => {
      if (!response.message) {
        this.toggleEditName()
      } else {
        this.dialogRef.close(response.message);
      }
    },
    (err) => {
      this.dialogRef.close(err.error.message);
    })
  }

  ngOnInit() {
    // console.log(this.data)
    if (this.data.order.params) {
      this.params = JSON.parse(this.data.order.params);
      // console.log(this.params)
    }
    //Comments
    this.ordersService.getOrderComments(this.data.order.order_dk_id).subscribe(
      (res) => {
        if (res.state) {
          this.comments = res.result;
        } else {
          this.openSnackBar("Serwer chwilowo niedostępny", "OK", 10);
        }
        this.isLoadingComments = false;
      },
      (err) => {
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      }
    );
    //Reclamations
    this.ordersService.getReclamation(this.data.order.id).subscribe(res => {
      this.reclamation = res
      if (this.reclamation != null) {
        this.hasReclamation = true;
      }
    },
      (err) => {
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      })

    //Invoice
    this.ordersService.getInvoice(this.data.order.id).subscribe(res => {
      this.invoice = res

      this.isLoadingInvoice = false;

    },
      (err) => {
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      })
    //Account
    this.accountService.getClient().subscribe((accountData) => {
      this.account = accountData;
    })
    //Order
    this.ordersService.getOrder(this.data.order.order_dk_id).subscribe(
      (res) => {
        if (res.state) {
          // console.log(res.result);
          this.orderDk = res.result;
          if (this.couriers[res.result.adresy[0].send_by]) {
            this.orderDk.sledzenie =
              this.couriers[res.result.adresy[0].send_by] +
              this.orderDk.adresy[0].package_number;
          }
        } else {
          this.openSnackBar("Serwer chwilowo niedostępny", "OK", 10);
        }
        this.isLoadingOrder = false;
      },
      (err) => {
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      }
    );
  }
  reclamationStarted:boolean = false
  startReclamation(){
    this.reclamationStarted = true
  }
  sendReclamation() {
    this.ordersService
      .createReclamation(this.data.order.id, {
        reason: this.reason,
        description: this.description,
        email: this.account.email,
      })
      .subscribe(
        (response) => {
          if (!response.message) {
            this.dialogRef.close("Złożono reklamację");
            this.openSnackBar("Złożono reklamację", "OK", 3)
          } else {
            this.dialogRef.close(response.message);
          }
        },
        (err) => {
          this.dialogRef.close(err.error.message);
        }
      );
  }

  addReclamationComment() {
    this.newReclamationComment["author"] = "Klient";
    this.newReclamationComment["reclamationId"] = this.reclamation.id
    this.reclamationsService.addClientComment(this.newReclamationComment).subscribe(res => {
      this.ngOnInit()
    },
      (err) => {
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      })
  }

  addComment(content) {
    this.ordersService.addComment(this.orderDk.id, content).subscribe(
      (res) => {
        this.ngOnInit();
      },
      (err) => {
        this.openSnackBar(
          "Wystąpił problem przy wystawianiu komentarza",
          "OK",
          10
        );
        console.log(err);
      }
    );
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: 'delete-user-modal',
  templateUrl: 'deleteModal.html',
})
export class DeleteUserModal {

  constructor(
    public dialogRef: MatDialogRef<DeleteUserModal>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
