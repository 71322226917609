import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountService } from "src/app/services/account.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OrdersService } from "src/app/services/orders.service";
import { ProductsService } from "src/app/services/products.service";
import { setClassMetadata } from "@angular/core/src/r3_symbols";
import { AuthService } from "src/app/services/auth.service";
import {Location} from '@angular/common';

@Component({
  selector: "app-new-order",
  templateUrl: "./new-order.component.html",
  styleUrls: ["./new-order.component.scss"],
})
export class NewOrderComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private accountsService: AccountService,
    private _snackBar: MatSnackBar,
    private ordersService: OrdersService,
    private productsService: ProductsService,
    private auth: AuthService,
    public _location: Location
  ) {}
  order: any = {};
  client: any = {};
  isLoading: boolean;
  deliveryTypes: any;
  isLoadingDeliveryTypes: boolean = false;
  selectedDeliveryType: any;
  selectedAddress: any;
  errorDeliveryType: any;
  product: any = {};
  nadawca: any = {};
  customSender = false;
  Object = Object;

  kodPromocyjny: any = "";
  doZaplaty_netto: any;
  doZaplaty_brutto: any;
  codePercentageValue = 0;
  codeMoneyValue = 0;
  isCodeUsed = false;
  codeUsed:any= 0;
  bonusPoints = 0;
  priceBeforeBonus: any;
  clientBonus = 0;
  bonusPointsUsed = 0;
  koszt:any

  registeringOrder = false;
  niePyklo = false;

  isClientLoggedIn = false;
  loginData: any = {};

  ngOnInit(): void {
    this.isLoading = true;

    this.login;

    this.route.queryParams.subscribe((params) => {
      this.order["rodzaj"] = params["rodzaj"];
      this.order["product_id"] = params["product_id"];
      this.order["tryb"] = params["tryb"];
      this.order["variantId"] = params["variantId"];
      this.order["custom"] = params["custom"];
      this.product["name"] = params["name"];

      this.accountsService.getClient().subscribe(
        (res) => {
          this.client = res;
          this.clientBonus = parseFloat(this.client.bonus);
          this.isClientLoggedIn = true;
          this.getDeliveryTypes();
        },
        (err) => {
          // tu bedzie 401 gdy niezalogowanuy
        }
      );

      this.productsService
        .getAmountForVariant(
          this.order["rodzaj"],
          params["variantId"],
          params["custom"] ? params["custom"] : null
        )
        .subscribe((response) => {
          Object.keys(response).some((key) => {
            if (response[key].product == this.order["product_id"]) {
              this.product["variant"] = response[key];
            }
          });

          this.productsService
            .getParams(
              this.order["rodzaj"],
              params["variantId"],
              params["custom"] ? params["custom"] : null
            )
            .subscribe((params) => {
              this.product["params"] = params;

              this.isLoading = false;
            });
        });
    });
  }

  login() {
    this.auth.loginUser("/api/clients/login/", this.loginData).subscribe(
      (res) => {
        localStorage.setItem("clientToken", "bearer " + res.token);
        window.location.reload();
      },
      (err) => {
        //TODO: zmienic na polski komunikat
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      }
    );
  }

  onStepChange(event) {
    if (event.selectedIndex === 1) {
      this.calculateFinalPrice();
    }
  }

  getDeliveryTypes() {
    console.log(this.deliveryTypes);
    if (this.client.postal_code.length == 6) {
      this.isLoadingDeliveryTypes = true;
      this.ordersService
        .getListOfDeliveryTypes(this.client["postal_code"])
        .subscribe(
          (res) => {
            this.errorDeliveryType = null;
            if (res.state) {
              let tempRes = [];
              this.selectedDeliveryType = 0;
              Object.keys(res.result).forEach((key) => {
                tempRes.push(res.result[key]);
              });

              this.deliveryTypes = tempRes;

              this.isLoadingDeliveryTypes = false;
            } else {
              this.errorDeliveryType = res.error;
            }
          },
          (err) => {
            this.openSnackBar(
              err.status != 0
                ? err.error.message
                : "Serwer chwilowo niedostępny",
              "OK",
              10
            );
          }
        );
    } else {
      this.selectedDeliveryType = null;
      this.errorDeliveryType = null;
      this.deliveryTypes = null;
      this.isLoadingDeliveryTypes = false;
    }
  }

  selectAddress() {
    this.client["first_name"] = this.client.addresses[
      this.selectedAddress
    ].firstName;
    this.client["last_name"] = this.client.addresses[
      this.selectedAddress
    ].lastName;
    this.client["company_name"] = this.client.addresses[
      this.selectedAddress
    ].companyName;
    this.client["address"] = this.client.addresses[
      this.selectedAddress
    ].address;
    this.client["postal_code"] = this.client.addresses[
      this.selectedAddress
    ].postalCode;
    this.client["city"] = this.client.addresses[this.selectedAddress].city;
    this.client["phone"] = this.client.addresses[this.selectedAddress].phone;
    this.selectedDeliveryType = null;
    this.errorDeliveryType = null;
    this.deliveryTypes = null;
    this.isLoadingDeliveryTypes = false;
    this.getDeliveryTypes();
  }

  onSelectionChange(event) {
    //console.log(event);
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }

  calculateFinalPrice() {
    let discount = 0;

    if (this.isCodeUsed) {
      discount = this.codePercentageValue;
    } else {
      discount = this.product.variant.promocjaWartosc;
      if (this.client.discount) {
        discount += ((100 - discount) * this.client.discount) / 100;
      }
    }

    discount = discount / 100;

    if (this.order.tryb == "s") {

      this.koszt = this.product.variant.cena_standard

      this.doZaplaty_netto =
        this.product.variant.cena_standard *
        (1 + (this.product.variant.marza / 100) * (1 - discount));
    } else {

      this.koszt = this.product.variant.cena_ekspres

      this.doZaplaty_netto =
        this.product.variant.cena_ekspres *
        (1 + (this.product.variant.marza / 100) * (1 - discount));
    }

    if (this.selectedDeliveryType) {
      this.doZaplaty_netto += parseInt(
        this.deliveryTypes[this.selectedDeliveryType].price
      );
    }

    //bonusy
    this.doZaplaty_netto = this.doZaplaty_netto - this.bonusPointsUsed;
    this.doZaplaty_netto -= this.codeMoneyValue;
    //vat
    this.doZaplaty_brutto =
      this.doZaplaty_netto * (1 + this.product.variant.vat / 100);
    //parse
    this.priceBeforeBonus = parseFloat(this.doZaplaty_netto);

    this.doZaplaty_netto = parseFloat(this.doZaplaty_netto);
    this.doZaplaty_brutto = parseFloat(this.doZaplaty_brutto);
  }

  isCodeApplicable() {
    if (!this.isCodeUsed) {
      this.ordersService.getCode(this.kodPromocyjny).subscribe(
        (code) => {
          if (code.message) {
            this.openSnackBar(code.message, "OK", 10);
          }

          if (
            new Date(code._from).getTime() < new Date().getTime() &&
            new Date(code._to).getTime() > new Date().getTime()
          ) {
            //TUTAJ ZMIENIC na * marza
            let price =
              this.order.tryb == "s"
                ? this.product.variant.cena_standard *
                  (1 + this.product.variant.marza / 100)
                : this.product.variant.cena_ekspres *
                  (1 + this.product.variant.marza / 100);
            if (code.minimum < price && code.maximum > price) {
              if (code.type == "procent") {
                this.codePercentageValue = code.discount;
              } else {
                this.codeMoneyValue = code.discount;
              }

              this.codeUsed = code.id;
              this.isCodeUsed = true;
              this.openSnackBar("Użyto kod promocyjny", "OK", 3);
              this.calculateFinalPrice();
            } else {
              this.openSnackBar("Nie można zastosować podanego kodu", "OK", 3);
            }
          } else {
            this.openSnackBar("Podany kod stracił swoją ważność", "OK", 3);
          }
        },
        (err) => {
          this.openSnackBar(err.error.message, "OK", 10);
        }
      );
    } else {
      this.openSnackBar(
        "Tylko jeden kod promocyjny może zostać użyty",
        "OK",
        3
      );
    }
  }

  resetCode() {
    this.codePercentageValue = 0;
    this.codeMoneyValue = 0;
    this.isCodeUsed = false;
    this.calculateFinalPrice();
  }

  applyBonusPoints() {
    this.bonusPointsUsed = this.bonusPoints;
    this.calculateFinalPrice();
  }

  registerOrder() {
    this.registeringOrder = true;
    let order = {
      userId: this.client.id,
      email: this.client.email,
      rodzaj: this.order.rodzaj,
      product_id: this.order.product_id,
      custom: this.order.custom ? this.order.custom : null,
      productName: this.product.name,
      variantId: this.order.variantId,
      naklad: this.product.variant.naklad,
      dostawa: this.deliveryTypes[this.selectedDeliveryType].id,
      doZaplaty: this.doZaplaty_netto,
      tryb: this.order.tryb,
      adres_imie: this.client.first_name,
      adres_nazwisko: this.client.last_name,
      adres_ulica: this.client.address,
      adres_kod_pocztowy: this.client.postal_code,
      adres_miasto: this.client.city,
      adres_telefon: this.client.phone,
      adres_firma: this.client.company_name,
      nadawca_imie: this.nadawca.imie,
      nadawca_nazwisko: this.nadawca.nazwisko,
      nadawca_firma: this.nadawca.firma,
      nadawca_ulica: this.nadawca.ulica,
      nadawca_kod_pocztowy: this.nadawca.kod_pocztowy,
      nadawca_miasto: this.nadawca.miasto,
      nadawca_telefon: this.nadawca.telefon,
      params: this.product.params,
      codeUsed: this.codeUsed,
      bonusUsed: this.bonusPointsUsed,
      koszt: this.koszt,
    };
    // jesli uzyto bonus punktow
    if (this.bonusPointsUsed > 0) {
      let newBonusVal = this.clientBonus - this.bonusPointsUsed;

      this.accountsService.updateClient({ bonus: newBonusVal }).subscribe(
        (res) => {
          this.ordersService.registerOrder(order).subscribe((res) => {
            this.registeringOrder = false;
            if (res.message) {
              // to jest nie w porządku
              this.niePyklo = true;
              this.openSnackBar(res.message, "OK", 10);
            } else {
              //to jest w porządku

              this.router.navigate(["/strona/moje_konto"]);
              this.openSnackBar("Zapisano zamówienie", "OK", 3);
            }
          });
        },
        (err) => {
          this.niePyklo = true;
          this.openSnackBar(
            err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
            "OK",
            10
          );
        }
      );
      //jesli nie uzyto bonus punktow
    } else {
      this.ordersService.registerOrder(order).subscribe(
        (res) => {
          this.registeringOrder = false;
          if (res.message) {
            // to jest nie w porządku
            this.niePyklo = true;
            this.openSnackBar(res.message, "OK", 10);
          } else {
            //to jest w porządku
            this.router.navigate(["/strona/moje_konto"]);
            this.openSnackBar("Zapisano zamówienie", "OK", 3);
          }
        },
        (err) => {
          this.niePyklo = true;
          this.openSnackBar(
            err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
            "OK",
            10
          );
        }
      );
    }
  }
}
