<div *ngIf="!paymentCanceled" class="box paymentCompleted">
  <div class="left">
    <div class="centerIcon">
      <mat-icon>thumb_up_alt</mat-icon>
    </div>
  </div>
  <div class="right">
    <h1>Dziękujemy za dokonanie zakupu w naszym serwisie</h1>
    <button mat-raised-button style="color: white" routerLink="/strona/moje_konto">Przejdź do zamówień</button>
  </div>
</div>

<div *ngIf="paymentCanceled" class="box paymentCanceled">
  <div class="left">
    <div class="centerIcon">
      <mat-icon>warning</mat-icon>
    </div>
  </div>
  <div class="right">
    <h1>Wystąpił błąd podczas autoryzacji płatności, proszę spróbować ponownie.</h1>
    <button mat-raised-button style="color: white" routerLink="/strona/moje_konto">Przejdź do zamówień</button>
  </div>
</div>
