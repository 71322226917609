import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AdminsService } from 'src/app/services/admins.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  id: any
  name: string
  pass: string
  pass2: string
}

@Component({
  selector: 'app-admins',
  templateUrl: './admins.component.html',
  styleUrls: ['./admins.component.scss']
})
export class AdminsComponent implements OnInit {

  constructor(private _snackBar: MatSnackBar, private adminsService: AdminsService, public dialog: MatDialog) { }
  admins: any
  isLoadingAdmins: boolean
  newPass: string
  ngOnInit(): void {
    this.isLoadingAdmins = true
    this.adminsService.getAdmins().subscribe(res => {
      this.admins = res
      this.isLoadingAdmins = false
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  openChangePassModal(id, name): void {
    const dialogRef = this.dialog.open(ChangePassModal, {
      width: '75%',
      data: { id: id, name: name }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.pass == result.pass2) {
          this.adminsService.updatePassword(id, result.pass).subscribe(res => {
            this.openSnackBar("Hasło zmienione pomyślnie", "OK", 3)
          }, err => {
            this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
          })
        } else {
          this.openSnackBar("Hasła się nie zgadzają", "OK", 5)
        }
      }
    });
  }
  openDeleteModal(id): void {
    const dialogRef = this.dialog.open(DeleteModal, {
      width: '75%',
      data: { id: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result)
        this.adminsService.deleteAdmin(id).subscribe(res => {
          this.openSnackBar("Usunięto admina pomyślnie", "OK", 3)
          this.ngOnInit()
        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
      }
    });
  }
  openAddAdminModal(): void {
    const dialogRef = this.dialog.open(AddAdminModal, {
      width: '75%',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result)
        this.adminsService.addAdmin({name: result.name, email: result.email, password: result.pass}).subscribe(res => {
          this.openSnackBar("Dodano admina pomyślnie", "OK", 3)
          this.ngOnInit()
        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
      }
    });
  }
  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
}

@Component({
  selector: 'change-pass-modal',
  templateUrl: 'changePassModal.html',
})
export class ChangePassModal {

  constructor(
    public dialogRef: MatDialogRef<ChangePassModal>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'delete-modal',
  templateUrl: 'deleteModal.html',
})
export class DeleteModal {

  constructor(
    public dialogRef: MatDialogRef<DeleteModal>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}

@Component({
  selector: 'addAdminModal',
  templateUrl: 'addAdminModal.html',
})
export class AddAdminModal {

  constructor(
    public dialogRef: MatDialogRef<AddAdminModal>,
    @Inject(MAT_DIALOG_DATA) public data: {name:string, email:string, pass:string}) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
