<div id="space">
  <form id="flexable" #registerForm="ngForm">

    <h2>Dane konta</h2>
    <br />
    <mat-form-field appearance="fill">
      <mat-label>Imię</mat-label>
      <input matInput placeholder="Imię" [(ngModel)]="registerData.first_name" name="first_name" required
        autocomplete="given-name">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Nazwisko</mat-label>
      <input matInput placeholder="Nazwisko" [(ngModel)]="registerData.last_name" name="last_name" required
        autocomplete="new-password" autocomplete="family-name">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>E-mail</mat-label>
      <input matInput email #email="ngModel" placeholder="E-mail" [(ngModel)]="registerData.email" id="username"
        name="email" required autocomplete="email">
    </mat-form-field>
    <mat-error *ngIf="email.touched && email.errors?.email">
      Proszę podać poprawny adres e-mail.
    </mat-error>

    <mat-form-field appearance="fill">
      <mat-label>Telefon</mat-label>
      <input matInput placeholder="Telefon" [(ngModel)]="registerData.phone" name="phone" required autocomplete="tel">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Hasło</mat-label>
      <input matInput type="password" placeholder="Hasło" [(ngModel)]="registerData.password" name="password" required
        autocomplete="new-password">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Powtórz hasło</mat-label>
      <input matInput type="password" placeholder="Powtórz hasło" #cpassword="ngModel" [(ngModel)]="password2"
        name="password2" required autocomplete="new-password">
    </mat-form-field>
    <mat-error *ngIf="cpassword.touched && password2 != registerData.password">
      Hasła się nie zgadzają.
    </mat-error>

    <h2>Dane do faktury</h2>
    <br />
    <mat-form-field appearance="fill">
      <mat-label>Pełna nazwa firmy</mat-label>
      <input matInput placeholder="Pełna nazwa firmy" [(ngModel)]="registerData.company_name" name="company_name"
        required>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Nip</mat-label>
      <input matInput placeholder="Nip" [(ngModel)]="registerData.nip" name="nip" required>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Kod pocztowy</mat-label>
      <input matInput placeholder="Kod pocztowy" [(ngModel)]="registerData.postal_code" name="postal_code" required>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Miasto</mat-label>
      <input matInput placeholder="Miasto" [(ngModel)]="registerData.city" name="city" required>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Adres</mat-label>
      <input matInput placeholder="Adres" [(ngModel)]="registerData.address" name="address" required>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Dodatkowy adres e-mail na który zostaną wysłane faktury</mat-label>
      <input matInput placeholder="E-mail" [(ngModel)]="registerData.email_invoice" name="email_invoice">
    </mat-form-field>

    <mat-checkbox color="primary" [(ngModel)]="regulamin" name="regulamin"> Akceptuję <a
        routerLink="/strona/regulamin">regulamin</a> serwisu oraz <a routerLink="/strona/polityka_prywatnosci">politykę
        prywatności</a> firmy. </mat-checkbox>
    <mat-checkbox color="primary" [(ngModel)]="registerData.marketing_consent" name="marketing_consent"> Wyrażam zgodę
      na otrzymywanie e-mailem informacji o promocjach i ofertach specjalnych. </mat-checkbox>

    <button mat-raised-button (click)="register()"
      [disabled]="!registerForm.valid || password2 != registerData.password || !regulamin" color="primary">
      <mat-spinner diameter=38 color='primary' *ngIf=waitingForResponse></mat-spinner>
      {{waitingForResponse ? '' : 'Zarejestruj'}}
    </button>
  </form>
</div>
