<div>
  <h2 mat-dialog-title>Złóż reklamację</h2>
  <div mat-dialog-content>

    <mat-form-field style="margin-top: 1rem;" class="full-width">
      <mat-label>Powód reklamacji</mat-label>
      <mat-select [(ngModel)]="reason" name="sale.productId">
        <mat-option [value]="defect" *ngFor="let defect of defects">
          {{defect}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="standard" class="full-width">
      <mat-label>Opis problemu</mat-label>
      <textarea rows="2" matInput placeholder="Opis" [(ngModel)]="description" name="description" required></textarea>
    </mat-form-field>


  </div>
  <div mat-dialog-actions>
    <button mat-raised-button style="float:right" color="primary" (click)="sendReclamation()">Wyślij Reklamacje</button>
  </div>
</div>
