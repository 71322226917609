import { Component, OnInit, Inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClientsService } from 'src/app/services/clients.service';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.scss']
})
export class EditClientComponent implements OnInit {

  constructor(public dialog: MatDialog, private route: ActivatedRoute, private clientsService: ClientsService, private _snackBar: MatSnackBar,public _location: Location) { }
  clientDetails: any
  clientOrders: any
  isLoadingClient: boolean
  isLoadingOrders: boolean
  hasActiveOrders: boolean = true
  ngOnInit(): void {
    this.isLoadingClient = true
    this.isLoadingOrders = true
    this.route.params.subscribe(params => {
      this.loadCLient(params['id'])
      this.loadOrders(params['id'])
      this.checkIfHasActiveOrders(params['id'])
    })

  }
  update(){
    console.log(this.clientDetails)
    this.clientsService.updateClient(this.clientDetails).subscribe(res => {
      this.openSnackBar("Pomyślnie zaktualizowano klienta", "OK", 3)
    },err=>{
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  checkIfHasActiveOrders(id){
    this.clientsService.getActiveOrders(id).subscribe(res => {
      // console.log(res)
      if(res.body.length > 0){
        this.hasActiveOrders = true;
      }else{
        this.hasActiveOrders = false
      }

    }, err => {
      this.isLoadingOrders = false
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  loadCLient(id) {
    this.clientsService.getClient(id).subscribe(res => {
      this.clientDetails = res
      this.isLoadingClient = false
    }, err => {
      this.isLoadingClient = false
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  loadOrders(id) {
    this.clientsService.getOrders(id).subscribe(res => {
      this.clientOrders = res.body
      this.isLoadingOrders = false
    }, err => {
      this.isLoadingOrders = false
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
  parseParams(params: string) {
    if (params) {
      let parsedString = ''
      let specs = JSON.parse(params)
      Object.keys(specs).forEach(key => {
        parsedString += key + ": " + specs[key] + "\n"
      })
      return parsedString
    }
    return "Brak parametrów"
  }

  openDeleteModal(id): void {
    const dialogRef = this.dialog.open(DeleteClientDialog, {
      width: '75%',
      data: { id: id }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log(result)
        this.clientsService.deleteClient(id).subscribe(res => {
          this.openSnackBar("Usunięto klienta pomyślnie", "OK", 3)
          this.ngOnInit()
        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
      }
    });
  }
}

@Component({
  selector: 'delete-banner',
  templateUrl: './delete-client.component.html',
})
export class DeleteClientDialog {
  constructor(
    public dialogRef: MatDialogRef<DeleteClientDialog>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
