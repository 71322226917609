import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PagesRestService } from 'src/app/services/pages-rest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SettingsService } from 'src/app/services/settings.service';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PageComponent implements OnInit {


  constructor(private route:ActivatedRoute, private pagesService:PagesRestService, private titleService: Title, private metaTagService: Meta, private settingsService: SettingsService, private _snackBar: MatSnackBar) { }

  waitingForResponse=true;

  guid = "";

  page:any;

  ngOnInit(): void {
      this.route.params.subscribe(params=>{
         this.guid = params["guid"]
         this.pagesService.getSinglePage(this.guid).subscribe(res=>{
          this.page=res

            this.settingsService.getSettingsGroup("seo").subscribe(response=>{


              let title = this.page.seo_title
              title = title.replace("##SEO_TITLE##", response[0].value)

              let keywords = this.page.seo_keywords
              keywords = keywords.replace("##SEO_KEYWORDS##", response[1].value)

              let description = this.page.seo_description
              description = description.replace("##SEO_DESCRIPTION##", response[1].value)

              this.titleService.setTitle(title);
              this.metaTagService.updateTag({ name: 'keywords', content: keywords })
              this.metaTagService.updateTag({ name: 'description', content: description })
            })

            this.waitingForResponse=false;
         }, err=>{
          this.waitingForResponse=false;
           //TODO: zmienic na polski komunikat
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
         })
      })
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action,{
      duration: sbDuration * 1000,
    });
  }

}
