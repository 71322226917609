<mat-spinner style="margin: 0px auto;" *ngIf="loadingProducts"></mat-spinner>
<div *ngIf="!loadingProducts" style="padding: 2rem;">
  <table style="width: 100%;">
    <tr>
      <th></th>
      <th>Dostępny w drukomacie</th>
      <th>Aktywny</th>
      <th>Edycja</th>
    </tr>
    <tr *ngFor="let product of products; let i = index" [attr.data-index]="i" >
      <td class="productName">
          {{ product.name }}
      </td>
      <td align="center">
        <button
        mat-button
        disabled
        [ngStyle]="{ color: product.availableDk ? 'orange' : '#F8665E' }"
      >
        {{ product.availableDk ? "Tak" : "Nie" }}
      </button>
      </td>
      <td align="center">
        <button
          mat-stroked-button
          (click)="toggleActive(product.guid, !product.active, i)"
          [ngStyle]="{ color: product.active == true ? '#74D774' : '#F8665E' }"
        >
          {{ product.active == true ? "Tak" : "Nie" }}
        </button>
      </td>
      <td align="center">
        <div class="icons">
          <button
            routerLink="/admin-panel/products/{{ product.guid }}"
            mat-button
          >
            <mat-icon>create</mat-icon>
          </button>
          <button
          routerLink="/admin-panel/products/variants/{{ product.guid }}"
          mat-button
        >
          <mat-icon>tune</mat-icon>
        </button>
        </div>
      </td>
    </tr>
  </table>
</div>
