import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leadWithZeros'
})
export class LeadWithZerosPipe implements PipeTransform {

  transform(number, size = 5): any {
    var s = String(number);
    while (s.length < size) {
      s = "0" + s;
    }
    return s
  }

}
