<mat-spinner style="margin: 0px auto;" *ngIf="isLoadingAdmins"></mat-spinner>
<div *ngIf="!isLoadingAdmins" style="padding: 2rem;">
  <table style="width: 100%;">
    <tr *ngFor="let admin of admins; let i = index" [attr.data-index]="i">
      <td style="width: 35%; text-align: center;">{{ admin.email }}</td>
      <td style="width: 35%; text-align: center;">{{ admin.name }}</td>
      <td style="text-align: right;">
        <button mat-stroked-button (click)="openChangePassModal(admin.id, admin.email)">Zmień hasło</button>&nbsp;
        <button mat-flat-button color="warn" (click)="openDeleteModal(admin.id)">Usuń</button>
      </td>
    </tr>
  </table><br>
  <button mat-stroked-button color="primary" (click)="openAddAdminModal()">Dodaj admina</button>
</div>
