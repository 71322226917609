<mat-spinner style="margin: 0px auto;" *ngIf="isLoading"></mat-spinner>
<div style="margin: 1rem;" *ngIf="!isLoading">
<mat-accordion class="settings-accordion">
  <mat-expansion-panel *ngFor="let year of summary; let i = index">
    <mat-expansion-panel-header>
        <mat-panel-title>
            {{year.rok}}
        </mat-panel-title>
    </mat-expansion-panel-header>
    <table style="width: 97%; margin: 1rem;">
      <tr>
        <th>Miesiąc</th>
        <th>Płatność klientów</th>
        <th>Koszt</th>
        <th>Zysk</th>
      </tr>
      <tr *ngFor="let month of summary[i].miesiace">
        <td class="cell">{{moment(month.miesiac, 'MM').format('MMMM')}}</td>
        <td class="cell">{{month.cena | currency:"PLN"}}</td>
        <td class="cell">{{month.koszt | currency:"PLN"}}</td>
        <td class="cell" [ngStyle]="{'color': Math.round(month.cena - month.koszt)<0?'#FF6961':'black', 'font-weight': Math.round(month.cena - month.koszt)<0?'500':'normal'}">{{(month.cena - month.koszt) | currency:"PLN"}}</td>
      </tr>

      <tr style="border-top: 2px solid black; text-align: right;">
        <td> </td>
        <td style="padding: 8px;">{{summary[i].cena | currency:"PLN"}}</td>
        <td style="padding: 8px;">{{summary[i].koszt | currency:"PLN"}}</td>
        <td style="padding: 8px;">{{(summary[i].cena - summary[i].koszt) | currency:"PLN"}}</td>
      </tr>
    </table>
  </mat-expansion-panel>
</mat-accordion>
</div>
