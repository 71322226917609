import { Component, OnInit } from "@angular/core";
import { SummaryService } from "src/app/services/summary.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import * as moment from 'moment';

@Component({
  selector: "app-summary",
  templateUrl: "./summary.component.html",
  styleUrls: ["./summary.component.scss"],
})
export class SummaryComponent implements OnInit {
  isLoading = true;
  summary: any = [];
  Object=Object;
  moment:any = moment;
  Math = Math;

  constructor(
    private summaryService: SummaryService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    moment.locale('pl')
    this.summaryService.getSummary().subscribe(
      (res) => {
        // console.log(res)
        let i = 0
        let checkYear = res[i].rok;
        this.summary[i] = {rok: res[i].rok , cena: 0, koszt: 0, miesiace: []}

        res.forEach(element => {
          if(element.rok!=checkYear){
            checkYear = element.rok
            i += 1;
            this.summary[i] = {rok: checkYear, cena: 0, koszt: 0, miesiace: []}
          }
          this.summary[i].cena += element.cena
          this.summary[i].koszt += element.koszt
          this.summary[i].miesiace.push(element)
        });
        // console.log(this.summary)
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.openSnackBar(
          err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny",
          "OK",
          10
        );
      }
    );
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
}
