<mat-spinner style="margin: 0px auto;" *ngIf="isLoading"></mat-spinner>
<div *ngIf="!isLoading" style="flex: 1; width: 90%; padding: 1rem; margin: 1rem auto;">

  <!-- Pola: Nazwa, nakład, od, do, rabat %-->

  <mat-form-field class="full-width">
    <mat-label>Nazwa</mat-label>
    <input matInput [(ngModel)]="sale.name" />
  </mat-form-field>
  <mat-form-field class="full-width">
    <mat-label>Nakłady</mat-label>
    <input matInput [(ngModel)]="sale.amounts" />
    <mat-hint align="start">-1 oznacza nieograniczony nakład</mat-hint>
  </mat-form-field>
  <div class="full-width" style="display: flex;">
    <mat-form-field style="flex: 1; margin-right: 0.5rem;">
      <mat-label>Ważna od</mat-label>
      <input matInput [matDatepicker]="_fromPicker" [(ngModel)]="sale._from" name="_from" />
      <mat-datepicker-toggle matSuffix [for]="_fromPicker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #_fromPicker></mat-datepicker>
    </mat-form-field>
    <mat-form-field style="flex: 1; margin-left: 0.5rem;">
      <mat-label>Ważna do</mat-label>
      <input matInput [matDatepicker]="_toPicker" [(ngModel)]="sale._to" name="_to" />
      <mat-datepicker-toggle matSuffix [for]="_toPicker"></mat-datepicker-toggle>
      <mat-datepicker touchUi #_toPicker></mat-datepicker>
    </mat-form-field>
  </div>
  <mat-form-field class="full-width">
    <mat-label>Rabat w %</mat-label>
    <input matInput [(ngModel)]="sale.discount" />
  </mat-form-field>

  <!-- Opis -->

  <h2>Opis promocji</h2>
  <ckeditor [editor]="Editor" [(ngModel)]="sale.description"></ckeditor>

  <!-- Wybór produktu -->

  <mat-form-field style="margin-top: 1rem;" class="full-width">
    <mat-label>Produkt</mat-label>

    <!-- Jak nastąpi zmiana produktu resetujemy warianty wybrane, po kliknięciu w produkt przekazujemy jaki produkt został wybrany po to by uzyskać jego guid do API-->

    <mat-select [(ngModel)]="sale.productId" name="sale.productId" (selectionChange)="resetVariants()">
      <mat-option [value]="product.id_dk" *ngFor="let product of products" (click)="getVariants(product)">
        {{product.name}}</mat-option>
    </mat-select>
  </mat-form-field>

  <!-- Wybór parametrów-->
  <mat-spinner style="margin: 0px auto;" *ngIf="selectedProduct && isLoadingVariants"></mat-spinner>
  <div *ngIf="selectedProduct && !isLoadingVariants">
    <h2>Parametry:</h2>

    <!-- Za każdego rodzica (grupe) parametru tworzymy nowy div-->

    <div *ngFor="let parent of Object.keys(selectedProduct?.configs.rodzic)">

      <!-- Nazwa rodzica -->

      <p style="font-weight: 500;">{{selectedProduct.names[parent]}}: </p>
      <button mat-stroked-button color="primary" (click)="selectAll(parent)">Zaznacz wszystkie</button><br>

      <!-- Za każde dziecko znajdujące się w grupie parametrów tworzymy checkbox -->

      <mat-checkbox color="primary" *ngFor="let child of Object.keys(selectedProduct?.configs.rodzic[parent])"
        [(ngModel)]="checkedBoxes[child]">{{selectedProduct.names[child]}} &nbsp;</mat-checkbox>
      <br>
      <br>
    </div>
  </div>

  <div style="margin-top: 1rem;">
    <div *ngIf="selectedProduct" style="padding: 1rem;">
      <p *ngIf="!sale.name">Nie podano nazwy promocji</p>
      <p *ngIf="!sale.discount">Nie podano wartości rabatu</p>
      <p *ngIf="!sale.amounts">Nie podano obowiązującego nakładu</p>
      <p *ngIf="!sale._to || !sale._from">Nie podano daty ważności</p>
    </div>

    <button mat-raised-button color="primary"
      [disabled]="!selectedProduct || !sale.name || !sale.discount || !sale.amounts || !sale._to || !sale._from"
      (click)="create()">
      Zapisz
    </button>
    &nbsp;
    <button mat-raised-button color="accent" routerLink="/admin-panel/sales">
      Powrót do promocji
    </button>
  </div>
</div>
