<div style="padding: 1rem;">
  <h2 mat-dialog-title>Dodawanie grupy</h2>
  <div mat-dialog-content>
    <mat-form-field style="width: 100%;">
      <mat-label>Nazwa</mat-label>
      <input  matInput [(ngModel)]="data.name">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>Opis</mat-label>
      <input  matInput [(ngModel)]="data.description">
    </mat-form-field>
    <mat-form-field style="width: 100%;">
      <mat-label>Guid</mat-label>
      <input  matInput [(ngModel)]="data.guid">
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="accent" (click)="onNoClick()">Anuluj</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="{name: data.name, description: data.description,guid: data.guid}" cdkFocusInitial>Zatwierdź</button>
  </div>
</div>

