import { Component, OnInit } from '@angular/core';
import { InvoicesService } from 'src/app/services/invoices.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from "../../../../../environments/environment";
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-admin-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class AdminInvoicesComponent implements OnInit {
  // MatPaginator Inputs
  length = 100;
  pageSize = 15;
  pageSizeOptions: number[] = [5, 10, 15, 20, 30];
  pageIndex: number = 0
  // MatPaginator Output
  pageEvent: any;
  constructor(private invoicesService: InvoicesService, private _snackBar: MatSnackBar, private router: Router,private route:ActivatedRoute) { }
  apiBaseUrl = environment.apiBaseUrl
  paidCf: any
  unpaidCf: any
  invoices:any
  isLoadingPaid:boolean
  isLoadingUnPaid:boolean
  isLoadingNormal:boolean
  selectedTabIndex:Number = 0

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if(params['from'] == "cf"){
        this.selectedTabIndex = 1
      }
    })
    this.loadPaid()
    this.loadNormal()
    this.isLoadingUnPaid = true
    this.invoicesService.getUnPaidCF().subscribe(res => {
      this.unpaidCf = res.body
      this.length = res.headers.get('X-Total-Count')
      this.isLoadingUnPaid = false
    }, err => {
      this.isLoadingUnPaid = false
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  loadNormal(event?){
    this.isLoadingNormal = true
    this.pageSize = event ? event.pageSize : this.pageSize
    this.pageIndex = event ? event.pageIndex : this.pageIndex
    //get all invoices, but skip client with id 5 (marketingserwis.pl)
    this.invoicesService.getAllInvoices(this.pageSize, this.pageIndex, 5).subscribe(res => {
      this.invoices = res.body
      this.length = res.headers.get('X-Total-Count')
      this.isLoadingNormal = false
    }, err => {
      this.isLoadingNormal = false
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  loadPaid(event?){
    this.isLoadingPaid = true
    this.pageSize = event ? event.pageSize : this.pageSize
    this.pageIndex = event ? event.pageIndex : this.pageIndex

    this.invoicesService.getPaidCF(this.pageSize, this.pageIndex).subscribe(res => {
      this.paidCf = res.body
      this.length = res.headers.get('X-Total-Count')
      this.isLoadingPaid = false
    }, err => {
      this.isLoadingPaid = false
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  toggleSeenInvoice(event, id, index){
    event.stopPropagation();
    this.invoices[index].seen = !this.invoices[index].seen
    this.invoicesService.toggleSeenInvoice(id).subscribe(res => {

    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  toggleSeenPaid(event, id, index){
    event.stopPropagation();
    this.paidCf[index].seen = !this.paidCf[index].seen
    this.invoicesService.toggleSeen(id).subscribe(res => {

    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  toggleSeenUnPaid(event, id, index){
    event.stopPropagation();
    this.unpaidCf[index].seen = !this.unpaidCf[index].seen
    this.invoicesService.toggleSeen(id).subscribe(res => {

    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }
  onTabChange(event){
    let param = event == 1 ? "cf" : "ms"
    this.router.navigate(
      [],
      {
        queryParams: { from: param },
      });
  }
  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
}
