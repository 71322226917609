import { Component, OnInit, AfterViewInit } from "@angular/core";
import { ProductsService } from "src/app/services/products.service";
import { environment } from "src/environments/environment";
import { get } from "scriptjs";
import { Location } from "@angular/common";
import { OrdersService } from "src/app/services/orders.service";
import { ActivatedRoute } from "@angular/router";
declare var $: any;

@Component({
  selector: "app-client-js",
  templateUrl: "./client-js.component.html",
  styleUrls: ["./client-js.component.scss"],
})
export class ClientJSComponent implements AfterViewInit {
  console = console;
  isLoading = true;
  isPreloading = true;
  bleed: boolean = false;
  scale = false;
  amountForVariant: any = {};
  url: any;
  data: any;
  isFileAccepted = false;
  environment = environment;
  firstLoad = true;
  constructor(
    private route: ActivatedRoute,
    private productsService: ProductsService,
    private ordersService: OrdersService,
    public _location: Location
  ) {}

  ngAfterViewInit(): void {
    if (this.firstLoad == true) {
      $(document).on("click", ".jsPreflightPageRotateRight", function () {
        const $pageRotateOptions = $(this).parents(".options");

        if (
          $pageRotateOptions.find("input[name=rotate]:checked").length === 0
        ) {
          $pageRotateOptions.find("input[name=rotate]:nth(1)").click();
        } else if (
          $pageRotateOptions.find("input[name=rotate]")[3].checked === true
        ) {
          $pageRotateOptions.find("input[name=rotate]:nth(0)").click();
        } else {
          $pageRotateOptions
            .find("input[name=rotate]:checked")
            .next("input[name=rotate]")
            .click();
        }
      });

      $(document).on("click", ".jsPreflightPageRotateLeft", function () {
        const $pageRotateOptions = $(this).parents(".options");

        if (
          $pageRotateOptions.find("input[name=rotate]:checked").length === 0
        ) {
          $pageRotateOptions.find("input[name=rotate]:nth(3)").click();
        } else if (
          $pageRotateOptions.find("input[name=rotate]")[0].checked === true
        ) {
          $pageRotateOptions.find("input[name=rotate]:nth(3)").click();
        } else {
          $pageRotateOptions
            .find("input[name=rotate]:checked")
            .prev("input[name=rotate]")
            .click();
        }
      });

      $(document).on("mouseover", ".qq-upload-button", function () {
        $(this)[0].children[1].title = "Umieść pliki tutaj";
      });

      $(document).on("click", ".jsBleed", function () {
        const $pageRotateOptions = $(this).parents(".options");
        if ($pageRotateOptions.find("input[name=bleed]")[0].checked) {
          $(this).removeClass("checked");
          $(this).addClass("notChecked");
          $pageRotateOptions.find("input[name=bleed]")[0].click();
        } else {
          $(this).removeClass("notChecked");
          $(this).addClass("checked");
          $pageRotateOptions.find("input[name=bleed]")[0].click();
        }
      });

      $(document).on("click", ".jsScale", function () {
        const $pageRotateOptions = $(this).parents(".options");
        if ($pageRotateOptions.find("input[name=scale]")[0].checked) {
          $(this).removeClass("checked");
          $(this).addClass("notChecked");
          $pageRotateOptions.find("input[name=scale]")[0].click();
        } else {
          $(this).removeClass("notChecked");
          $(this).addClass("checked");
          $pageRotateOptions.find("input[name=scale]")[0].click();
        }
      });
      this.firstLoad = false;
    }

    this.route.params.subscribe((params) => {
      this.ordersService.getOrderAsAdmin(params["id"]).subscribe((res) => {
        this.data = {
          url: res.jsUrl,
          id: res.jsId,
          productId: res.main_product_id,
          variantId: res.group_id,
          naklad: res.naklad,
          fileStatus: res.file_status,
        };
        this.isPreloading = false;
        this.productsService
          .getAmountForVariant(this.data.productId, this.data.variantId)
          .subscribe((res) => {
            Object.keys(res).forEach((element) => {
              if (res[element].naklad == this.data.naklad) {
                this.amountForVariant = res[element];
              }
            });
          });

        window["user"] = {
          admin_id: 1,
          name: "JZ",
          email: "cygi.net@gmail.com",
          shop: "api.drukarnia.j13.pl",
        };

        // Timer w tym momencie jest wyłączony!!
        if (typeof window["AjaxLoader"] != "function") {
          function AjaxLoader(contener) {
            function remove() {
              this.isLoading = false;
            }
            this.remove = remove;
            return this;
          }
          window["AjaxLoader"] = AjaxLoader;
        }

        get("https:" + this.data.url, () => {
          this.isLoading = false;
        });

        if (this.data.fileStatus == "Zaakceptowany") {
          setTimeout(() => (this.isFileAccepted = true), 500);
        }

        this.checked();
      });
    });
  }

  checked() {
    setTimeout(() => {
      for (let i = 0; i < $("input[name='bleed']").length; i++) {
        if ($("input[name='bleed']")[i].checked) {
          $("button.jsBleed").eq(i).removeClass("notChecked");
          $("button.jsBleed").eq(i).addClass("checked");
        }
        if ($("input[name='scale']")[i].checked) {
          $("button.jsScale").eq(i).removeClass("notChecked");
          $("button.jsScale").eq(i).addClass("checked");
        }
      }
    }, 1000);
  }

  accepted() {
    this.isFileAccepted = true;
  }
}
