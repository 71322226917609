import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ClientsService {
  constructor(private http: HttpClient) {}

  getClients(limit?, page?, sort?): Observable<any> {
    let params = {};
    if (limit) {
      params["limit"] = limit;
    }
    if (page) {
      params["page"] = page;
    }
    if (sort != null) {
      params["sortName"] = sort[0][0];
      params["sortOrder"] = sort[0][1];
    } else {
      params["sortName"] = "createdAt";
      params["sortOrder"] = "DESC";
    }

    return this.http.get(environment.apiBaseUrl + "/api/admin/clients", {
      params,
      observe: "response",
    });
  }
  search(searchTerm): Observable<any> {
    let params = {};
    params["search"] = searchTerm;
    return this.http.get(environment.apiBaseUrl + "/api/admin/clients", {
      params,
      observe: "response",
    });
  }

  toggleActiveClient(id, active): Observable<any> {
    return this.http.put(environment.apiBaseUrl + "/api/admin/clients/" + id, {
      active,
    });
  }

  getClient(clientId): Observable<any> {
    return this.http.get(
      environment.apiBaseUrl + "/api/admin/clients/" + clientId
    );
  }

  getOrders(clientId, limit?, page?): Observable<any> {
    let params = {};
    if (limit) {
      params["limit"] = limit;
    }
    if (page) {
      params["page"] = page;
    }
    return this.http.get(
      environment.apiBaseUrl + "/api/admin/clients/" + clientId + "/orders",
      { params, observe: "response" }
    );
  }
  getActiveOrders(clientId, limit?, page?): Observable<any> {
    let params = {};
    if (limit) {
      params["limit"] = limit;
    }
    if (page) {
      params["page"] = page;
    }
    return this.http.get(
      environment.apiBaseUrl + "/api/admin/clients/" + clientId + "/activeOrders",
      { params, observe: "response" }
    );
  }

  updateClient(clientData): Observable<any> {
    return this.http.put(
      environment.apiBaseUrl + "/api/admin/clients/" + clientData.id,
      clientData
    );
  }
  deleteClient(id):Observable<any>{
    return this.http.delete(environment.apiBaseUrl+'/api/admin/clients/' + id)
  }
}
