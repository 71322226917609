<mat-tab-group selectedIndex={{selectedTabIndex}} (selectedIndexChange)="onTabChange($event)">
  <mat-tab label="marketingserwis.pl">
    <mat-spinner style="margin: 0px auto;" *ngIf="isLoadingNormal"></mat-spinner>
    <mat-paginator style="flex: 1;" [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
      [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = loadNormal($event)">
    </mat-paginator>
    <div *ngIf="!isLoadingNormal" style="padding: 2rem;">
      <table style="width: 100%;">
        <tr>
          <th>Klient</th>
          <th >Nr</th>
          <th align="left">Pliki</th>
          <th>Data wystawienia</th>
          <th>Wartość Netto</th>
          <th></th>
        </tr>
        <tr *ngFor="let invoice of invoices; let i = index" [attr.data-index]="i">
          <td>
            <a style="color:black" target="_blank" href="/admin-panel/clients/{{invoice.client_id}}">
              {{ invoice.kupujacy_firma }}
            </a>
          </td>
          <td>
            {{ invoice.sygnatura }}
          </td>
          <td>
            <a target="_blank" href="{{apiBaseUrl}}/invoices/{{invoice.sygnatura}}.pdf"
              (click)="!invoice.seen ? toggleSeenInvoice($event, invoice.id, i) : null">
              <mat-icon color="primary">description</mat-icon>
            </a>
          </td>
          <td align="center">
            {{ invoice.createdAt | date: "yyyy-MM-d" }}
          </td>
          <td align="center">
            {{ invoice.pozycja_wartosc_netto | currency:"PLN"}}
          </td>
          <td>
            <mat-icon class="clickable" (click)="toggleSeenInvoice($event, invoice.id, i)" *ngIf="invoice.seen"
              color="primary">visibility</mat-icon>
            <mat-icon class="clickable" (click)="toggleSeenInvoice($event, invoice.id, i)" *ngIf="!invoice.seen"
              color="accent">visibility_off</mat-icon>
          </td>
        </tr>
      </table>
    </div>
  </mat-tab>
  <mat-tab label="Colours Factory">
    <!-- NIEZAPŁACONE -->
    <mat-spinner style="margin: 0px auto;" *ngIf="isLoadingUnPaid"></mat-spinner>
    <div *ngIf="!isLoadingUnPaid" style="padding: 2rem;">
      <table style="width: 100%;">
        <tr>
          <th align="left">Nazwa</th>
          <th align="left">Pliki</th>
          <th>Termin płatności </th>
          <th>Brutto</th>
          <th>Netto</th>
          <th></th>
        </tr>
        <tr *ngFor="let invoice of unpaidCf; let i = index" [attr.data-index]="i">
          <td>
            {{ invoice.file2_name || invoice.name }}
          </td>
          <td>
            <a *ngIf="invoice.file1_url" target="_blank" href="{{apiBaseUrl}}{{invoice.file1_url}}">
              <mat-icon color="primary">receipt</mat-icon>
            </a>
            <mat-icon *ngIf="!invoice.file1_url" color="accent">receipt</mat-icon>
            &nbsp;
            <a *ngIf="invoice.file2_url" target="_blank" href="{{apiBaseUrl}}{{invoice.file2_url}}"
              (click)="!invoice.seen ? toggleSeenUnPaid($event, invoice.id, i) : null">
              <mat-icon color="primary">description</mat-icon>
            </a>
            <mat-icon *ngIf="!invoice.file2_url" color="accent">description</mat-icon>
          </td>
          <td align="center">
            {{ invoice.termin_platnosci | date: "yyyy-MM-d" }}
          </td>
          <td align="center">
            {{ invoice.brutto | currency:"PLN"}}
          </td>
          <td align="center">
            {{ invoice.netto | currency:"PLN"}}
          </td>
          <td>
            <mat-icon class="clickable" (click)="toggleSeenUnPaid($event, invoice.id, i)" *ngIf="invoice.seen"
              color="primary">visibility</mat-icon>
            <mat-icon class="clickable" (click)="toggleSeenUnPaid($event, invoice.id, i)" *ngIf="!invoice.seen"
              color="accent">visibility_off</mat-icon>
          </td>
        </tr>
      </table>
    </div>
    <!-- ZAPŁACONE -->
    <mat-paginator style="flex: 1;" [length]="length" [pageSize]="pageSize" [pageIndex]="pageIndex"
      [pageSizeOptions]="pageSizeOptions" (page)="pageEvent = loadPaid($event)">
    </mat-paginator>
    <mat-spinner style="margin: 0px auto;" *ngIf="isLoadingPaid"></mat-spinner>
    <div *ngIf="!isLoadingPaid" style="padding: 2rem;">
      <table style="width: 100%;">
        <tr>
          <th align="left">Nazwa</th>
          <th align="left">Pliki</th>
          <th>Termin płatności </th>
          <th>Brutto</th>
          <th>Netto</th>
          <th></th>
        </tr>
        <tr *ngFor="let invoice of paidCf; let i = index" [attr.data-index]="i">
          <td>
            {{ invoice.file2_name || invoice.name }}
          </td>
          <td>
            <a *ngIf="invoice.file1_url" target="_blank" href="{{apiBaseUrl}}{{invoice.file1_url}}">
              <mat-icon color="primary">receipt</mat-icon>
            </a>
            <mat-icon *ngIf="!invoice.file1_url" color="accent">receipt</mat-icon>
            &nbsp;
            <a *ngIf="invoice.file2_url" target="_blank" href="{{apiBaseUrl}}{{invoice.file2_url}}"
              (click)="!invoice.seen ? toggleSeenPaid($event, invoice.id, i) : null">
              <mat-icon color="primary">description</mat-icon>
            </a>
            <mat-icon *ngIf="!invoice.file2_url" color="accent">description</mat-icon>
          </td>
          <td align="center">
            {{ invoice.termin_platnosci | date: "yyyy-MM-d" }}
          </td>
          <td align="center">
            {{ invoice.brutto | currency:"PLN"}}
          </td>
          <td align="center">
            {{ invoice.netto | currency:"PLN"}}
          </td>
          <td>
            <mat-icon class="clickable" (click)="toggleSeenPaid($event, invoice.id, i)" *ngIf="invoice.seen"
              color="primary">visibility</mat-icon>
            <mat-icon class="clickable" (click)="toggleSeenPaid($event, invoice.id, i)" *ngIf="!invoice.seen"
              color="accent">visibility_off</mat-icon>
          </td>
        </tr>
      </table>
    </div>
  </mat-tab>
  <mat-tab label="Podsumowanie">
    <app-summary></app-summary>
  </mat-tab>
</mat-tab-group>
