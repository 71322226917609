<div style="padding: 0 2rem;">
  <div style="display: flex;">
    <div style="flex: 1">
      <mat-form-field appearance="standard">
        <mat-label>Wyszukaj</mat-label>
        <input [(ngModel)]="searchTerm" (ngModelChange)="onSearch()" matInput/>
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <mat-paginator style="flex: 1"
      [length]="length"
      [pageSize]="pageSize"
      [pageIndex]="pageIndex"
      [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent = loadClients($event)"
    >
    </mat-paginator>
  </div>
</div>

<mat-spinner style="margin: 0px auto;" *ngIf="loadingClients"></mat-spinner>
<div *ngIf="!loadingClients" style="padding: 2rem;">
  <table style="width: 100%;">
    <tr>
      <th *ngFor="let header of headers; let i = index" style="cursor: pointer;" (click)="sortClick(i)">{{header.text}}<mat-icon style="font-size: 14px;" *ngIf="header.sortingState==1">arrow_upward</mat-icon><mat-icon style="font-size: 14px;" *ngIf="header.sortingState==2">arrow_downward</mat-icon></th>
    </tr>
    <tr *ngFor="let client of clients; let i = index" [attr.data-index]="i" class="clickable" (click)="goToClient(client.id)">
      <td class="cell">
        {{ client.company_name }}
      </td>
      <td class="cell">{{ client.first_name }} {{ client.last_name }}</td>
      <td>
        {{ client.nip }}
      </td>
      <td>
        {{ client.createdAt | date: "yyyy-MM-d HH:mm" }}
      </td>
      <td align="center">
        <div class="icons">
          <button
            mat-stroked-button
            (click)="toggleActive($event ,client.id, !client.active, i)"
            [ngStyle]="{ color: client.active == true ? '#74D774' : '#F8665E' }"
          >
            {{ client.active == true ? "Tak" : "Nie" }}
          </button>
        </div>
      </td>
      <td align="center">
        {{ client.discount }}
      </td>
      <td align="center">
        <mat-icon
          *ngIf="client.ordersAmount > 0"
          matBadge="{{ client.ordersAmount }}"
          matBadgeColor="primary"
          >insert_drive_file</mat-icon
        >
        <mat-icon *ngIf="client.ordersAmount == 0" color="accent"
          >insert_drive_file</mat-icon
        >
      </td>
      <td align="center">
        <button
          mat-button
          disabled
          [ngStyle]="{
            color: client.marketing_consent ? '#74D774' : '#F8665E'
          }"
        >
          {{ client.marketing_consent ? "Tak" : "Nie" }}
        </button>
      </td>
    </tr>
  </table>
</div>
