<mat-spinner style="margin: 0px auto;" *ngIf="isLoading"></mat-spinner>
<div *ngIf="!isLoading" style="display: flex; flex-direction: row; flex-wrap: wrap; ">
  <!-- produkty w grupie -->
  <div style="flex: 5;min-width: 300px; padding: 1rem;" cdkDropList (cdkDropListDropped)="drop($event)" >
    <h1>Produkty w grupie</h1>
    <div *ngFor="let product of productsHere" cdkDrag>
      <mat-card class="nz-card">
        <mat-card-content class="card-content">
          <div class="text">
            {{ product.name }}
          </div>

          <div class="icons">
            <button class="draggable" cdkDragHandle mat-button>
              <mat-icon>unfold_more</mat-icon>
            </button>

            <button (click)="remove(product.group_product.position)" mat-button>
              <mat-icon >delete</mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
  <!-- dodawanie produktow -->
  <div style="flex: 3; min-width: 300px; padding: 1rem;">
    <h2>Dodaj produkt</h2>
    <div *ngFor="let product of productsNotHere">
      <mat-card class="nz-card">
        <mat-card-content class="card-content">
          <div class="text">
            {{ product.name }}
          </div>

          <div class="icons">
            <button (click)="add(product.id)" mat-button>
              <mat-icon >add</mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>

