import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin-login',
  templateUrl: './admin-login.component.html',
  styleUrls: ['./admin-login.component.scss']
})
export class AdminLoginComponent implements OnInit {

    loginData = {
        email: "",
        password: ""
    }

  constructor(private auth:AuthService, private router:Router, private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
    if(this.auth.isAdminLoggedIn()){
      this.router.navigate(["/admin-panel"])
    }
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action,{
      duration: sbDuration * 1000,
    });
  }

  login(){
    this.auth.loginUser("/api/admin/login/", this.loginData).subscribe(res=>{
        localStorage.setItem('adminToken', "bearer " + res.token)
        this.router.navigate(["/admin-panel"])
    }, err=> {
        this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

}
