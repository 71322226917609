<mat-spinner
  style="margin: 0px auto;"
  *ngIf="isLoadingClient || isLoadingOrders"
></mat-spinner>
<div *ngIf="!isLoadingClient && !isLoadingOrders">
  <mat-card class="nz-card">
    <mat-card-header>
      <mat-card-title>{{ clientDetails.company_name }}</mat-card-title>
      <mat-card-subtitle
        >{{ clientDetails.first_name }}
        {{ clientDetails.last_name }}</mat-card-subtitle
      >
    </mat-card-header>
    <mat-card-content
      style="display: flex; flex-direction: row; flex-wrap: wrap;"
    >
      <div style="flex: 1; min-width: 180px;">
        <p>
          NIP:<br />
          <span style="font-weight: 500;">{{ clientDetails.nip }}</span>
        </p>
        <p>
          Adres:<br />
          <span style="font-weight: 500;"
            >{{ clientDetails.address }}
            {{ clientDetails.postal_code }}
            {{ clientDetails.city }}
          </span>
        </p>
      </div>
      <div style="flex: 1; min-width: 180px;">
        <p>
          Tel:<br />
          <span style="font-weight: 500;">{{ clientDetails.phone }}</span>
        </p>
        <p>
          Email:<br />
          <span style="font-weight: 500;">{{ clientDetails.email }}</span>
        </p>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-accordion>
    <mat-expansion-panel style="margin: 1rem;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Zamówienia
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngIf="!isLoadingOrders" style="padding: 2rem;">
        <table style="width: 100%;">
          <tr>
            <th>Miniaturka</th>
            <th>Data zamówienia</th>
            <th>Zamówienie</th>
            <th>Zamówienie dk</th>
            <th>Klient</th>
            <th>Produkt</th>
            <th>Wartość</th>
            <th>Status</th>
            <th>Status płatności</th>
          </tr>
          <tr *ngFor="let order of clientOrders; let i = index" [attr.data-index]="i">
            <td align="center">
              <img style="width: 100px" src="{{order.thumbnail}}"/>
            </td>
            <td align="center">
              {{ order.createdAt | date: "yyyy-MM-d HH:mm" }}
            </td>
            <td align="center" class="cell">
              <a
                style="color: black;"
                routerLink="/admin-panel/orders/{{ order.id }}"
              >
                {{ order.nr }}/{{ order.client_id | leadWithZeros: 3 }}
              </a>
            </td>
            <td align="center">
              {{ order.order_dk_id }}
            </td>
            <td>
              <a
                style="color: black;"
                routerLink="/admin-panel/clients/{{ order.client_id }}"
              >
                <span style="font-weight: 500;"
                  >{{ clientDetails.company_name }}
                </span>
                {{ clientDetails.first_name }}
                {{ clientDetails.last_name }}
              </a>
            </td>
            <td>
              <mat-icon
                color="primary"
                [matTooltipClass]="'my-tooltip'"
                matTooltip="{{ this.parseParams(order.params) }}"
              >
                info
              </mat-icon>
              <span class="product_span">
                {{ order.main_product_name }}
              </span>
            </td>
            <td align="center">{{ order.cena | currency:"PLN"}}</td>
            <td align="center">
              {{ order.status }}
            </td>
            <td align="center">
              {{ order.status_platnosci }}
            </td>
          </tr>
        </table>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-accordion>
    <mat-expansion-panel style="margin: 1rem;">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Edycja klienta
        </mat-panel-title>
        <mat-panel-description>
          Ostatnia edycja:
          {{ clientDetails.updatedAt | date: "yyyy-MM-d HH:mm" }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <!-- FORM -->
      <h2>Dane do faktury</h2>
      <mat-form-field class="full-width">
        <mat-label>Nazwa firmy</mat-label>
        <input matInput [(ngModel)]="clientDetails.company_name" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Nip</mat-label>
        <input matInput [(ngModel)]="clientDetails.nip" required />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Adres</mat-label>
        <input matInput [(ngModel)]="clientDetails.address" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Miasto</mat-label>
        <input matInput [(ngModel)]="clientDetails.city" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Kod pocztowy</mat-label>
        <input matInput [(ngModel)]="clientDetails.postal_code" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Telefon</mat-label>
        <input matInput [(ngModel)]="clientDetails.phone" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Dodatkowy adres e-mail na który zostaną wysłane faktury</mat-label>
        <input matInput [(ngModel)]="clientDetails.email_invoice" />
      </mat-form-field>

      <h2>Dane konta</h2>
      <mat-form-field class="full-width">
        <mat-label>Imię</mat-label>
        <input matInput [(ngModel)]="clientDetails.first_name" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Nazwisko</mat-label>
        <input matInput [(ngModel)]="clientDetails.last_name" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Email</mat-label>
        <input matInput [(ngModel)]="clientDetails.email" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Telefon</mat-label>
        <input matInput [(ngModel)]="clientDetails.phone" />
      </mat-form-field>

      <mat-checkbox color="primary" [(ngModel)]="clientDetails.marketing_consent" name="marketing_consent"> Zgoda na otrzymywanie e-mailem informacji o promocjach i ofertach specjalnych. </mat-checkbox>
      <br>
      <mat-checkbox color="primary" [(ngModel)]="clientDetails.postponement" style="margin-right: 1rem">Możliwość odroczenia płatności</mat-checkbox>
      <br><br>
      <mat-form-field class="full-width">
        <mat-label>Ilość dni odroczenia</mat-label>
        <input matInput [(ngModel)]="clientDetails.postponement_days" />
      </mat-form-field>

      <h2>Bonusy</h2>
      <mat-form-field class="full-width">
        <mat-label>Rabat %</mat-label>
        <input matInput [(ngModel)]="clientDetails.discount" />
      </mat-form-field>
      <mat-form-field class="full-width">
        <mat-label>Wirtualna waluta</mat-label>
        <input matInput [(ngModel)]="clientDetails.bonus" />
      </mat-form-field>
      <button mat-raised-button color="primary" (click)="update()">Zapisz</button>
      <!-- FORM END  -->
    </mat-expansion-panel>
  </mat-accordion>
  <button mat-raised-button color="accent" style="margin: 1rem;" (click)="_location.back();">
    Powrót do klientów
  </button>
  <button *ngIf="!hasActiveOrders" mat-raised-button color="warn" style="margin: 1rem; float: right" (click)="openDeleteModal(clientDetails.id);">
    Usuń
  </button>
</div>
