import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(private http: HttpClient) { }

  pay(payload):Observable<any>{
    return this.http.post(environment.apiBaseUrl + "/api/clients/orders/payment", payload);
  }
}
