import { Component, OnInit } from '@angular/core';
import { PagesRestService } from 'src/app/services/pages-rest.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-admin-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class AdminPagesComponent implements OnInit {

  constructor(private pagesService: PagesRestService, private _snackBar: MatSnackBar) { }
  public Editor = ClassicEditor;
  pages:any
  isLoading:boolean
  test:any = "ABC"
  ngOnInit(): void {
    this.isLoading = true
    this.pagesService.getAllPages().subscribe(res=>{
      this.pages = res
      this.isLoading = false
    },err=>{
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  update(page){
    this.pagesService.updatePage(page).subscribe(res => {
      this.openSnackBar("Zaktualizowano stronę pomyślnie", "OK", 3)
    },err=>{
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }
}
