import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLoginComponent } from './components/admin/admin-login/admin-login.component';
import { AdminPanelComponent } from './components/admin/admin-panel/admin-panel.component';
import { HomeComponent } from './components/home/home.component';
import { PageComponent } from './components/page/page.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminGuard } from './guards/admin.guard';
import { AdminDashboardComponent } from './components/admin/admin-panel/dashboard/dashboard.component';
import { AdminSettingsComponent } from './components/admin/admin-panel/settings/settings.component';
import { AdminGroupsComponent } from './components/admin/admin-panel/groups/groups.component';
import { AdminOrdersComponent } from './components/admin/admin-panel/orders/orders.component';
import { OrderDetailsComponent } from './components/admin/admin-panel/orders/order-details/order-details.component';
import { AdminSalesComponent } from './components/admin/admin-panel/sales/sales.component';
import { AdminClientsComponent } from './components/admin/admin-panel/clients/clients.component';
import { AdminPagesComponent } from './components/admin/admin-panel/pages/pages.component';
import { AdminInvoicesComponent } from './components/admin/admin-panel/invoices/invoices.component';
import { AdminsComponent } from './components/admin/admin-panel/admins/admins.component';
import { AdminProductsComponent } from './components/admin/admin-panel/products/products.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SitemapComponent } from './components/admin/admin-panel/sitemap/sitemap.component';
import { MainComponent } from './components/home/main/main.component';
import { GroupComponent } from './components/home/group/group.component';
import { ProductComponent } from './components/home/product/product.component';
import { ResetComponent } from './components/home/reset/reset.component';
import { EditGroupComponent } from './components/admin/admin-panel/groups/edit-group/edit-group.component';
import { EditSaleComponent } from './components/admin/admin-panel/sales/edit-sale/edit-sale.component';
import { EditProductComponent } from './components/admin/admin-panel/products/edit-product/edit-product.component';
import { CreateSaleComponent } from './components/admin/admin-panel/sales/create-sale/create-sale.component';
import { EditClientComponent } from './components/admin/admin-panel/clients/edit-client/edit-client.component';
import { NewOrderComponent } from './components/home/new-order/new-order.component';
import { GroupProductsComponent } from './components/admin/admin-panel/groups/group-products/group-products.component';
import { VariantsComponent } from './components/admin/admin-panel/products/variants/variants.component';
import { BannersComponent } from './components/admin/admin-panel/banners/banners.component';
import { PurchaseComponent } from './components/purchase/purchase.component';
import { ReclamationsComponent } from './components/admin/admin-panel/reclamations/reclamations.component';
import { ClientJSComponent } from './components/admin/admin-panel/orders/order-details/client-js/client-js.component';
import { SummaryComponent } from './components/admin/admin-panel/summary/summary.component';


const routes: Routes = [
  { path: "", redirectTo: "/", pathMatch: "full" },
  {
    path: "", component: HomeComponent, children: [
      { path: "strona/:guid", component: PageComponent },
      { path: "", component: MainComponent, pathMatch: "full"},
      { path: "grupa/:guid", component: GroupComponent },
      { path: "produkt/:guid", component: ProductComponent },
      { path: "nowe_zamowienie", component: NewOrderComponent },
      { path: "reset/:token", component: ResetComponent },
      { path: "zakup", component: PurchaseComponent },
    ]
  },
  { path: "admin", component: AdminLoginComponent },
  {
    path: "admin-panel", component: AdminPanelComponent, canActivate: [AdminGuard],
    children: [
      {path: "", component: AdminDashboardComponent},
      {path: "settings", component: AdminSettingsComponent},
      {path: "summary", component: SummaryComponent},
      {path: "groups", component: AdminGroupsComponent},
      {path: "group/products/:guid", component: GroupProductsComponent},
      {path: "group/:guid", component: EditGroupComponent},
      {path: "products", component: AdminProductsComponent},
      {path: "products/:guid", component: EditProductComponent},
      {path: "products/variants/:guid", component: VariantsComponent},
      {path: "sales", component: AdminSalesComponent},
      {path: "create-sale", component: CreateSaleComponent},
      {path: "sale/:id", component: EditSaleComponent},
      {path: "orders", component: AdminOrdersComponent},
      {path: "orders/:id", component: OrderDetailsComponent},
      {path: "orders/:id/files", component: ClientJSComponent},
      {path: "reclamations", component: ReclamationsComponent},
      {path: "clients", component: AdminClientsComponent},
      {path: "clients/:id", component: EditClientComponent},
      {path: "pages", component: AdminPagesComponent},
      {path: "invoices", component: AdminInvoicesComponent},
      {path: "admins", component: AdminsComponent},
      {path: "sitemap", component: SitemapComponent},
      {path: "banners", component: BannersComponent}
    ]
  },
  { path: "404", component: NotFoundComponent },
  { path: "**", redirectTo:'/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
