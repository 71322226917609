<mat-spinner style="margin: 0px auto" *ngIf="waitingForResponse"></mat-spinner>
<div *ngIf="!waitingForResponse">
    <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div *ngFor="let group of groups" cdkDrag>
            <!-- {{group.name}} -->
            <mat-card class="nz-card">
                <mat-card-content class="card-content">
                    <div class="text">
                        {{group.name}}
                    </div>

                    <button mat-stroked-button (click)="toggleActive(group.id, !group.active, group.position)" [ngStyle]="{color: group.active==true?'#74D774':'#F8665E'}">
                        Aktywna: {{group.active==true?"Tak":"Nie"}}
                    </button>
                    <div class="icons">
                        <button class="draggable" cdkDragHandle mat-button>
                            <mat-icon>unfold_more</mat-icon>
                        </button>
                        <button routerLink="/admin-panel/group/products/{{group.guid}}" matTooltip="Przypisz produkty" mat-button>
                          <mat-icon>shopping_basket</mat-icon>
                      </button>
                        <button routerLink="/admin-panel/group/{{group.guid}}" matTooltip="Edytuj" mat-button>
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button *ngIf="isDeleteButtonsActive" (click)="delete(group.id)" mat-button>
                            <mat-icon color='warn'>delete</mat-icon>
                        </button>
                    </div>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <div class="buttons">
        <button mat-raised-button color="primary" (click)="openDialog()">
            Nowa grupa
        </button>
        <br><br>
        <button (click)="toggleDeleteButtons()" mat-raised-button color="accent">
            {{isDeleteButtonsActive ? 'Ukryj' : 'Pokaż'}} przyciski usuwania
        </button>
    </div>
</div>
