<h1>{{group.name}}</h1>
<div [innerHtml]="group.description | safeHtml"></div>
<div class="card-container">
    <mat-card class="product-card clickable" *ngFor="let product of group.products" routerLink="/produkt/{{product.guid}}">
        <img mat-card-image [src]="product?.icon ? imgUrl + product.icon : 'assets/img/no_photo.png'" >
        <mat-card-actions class="product-name">
            <span>{{product.name}}</span>
        </mat-card-actions>
    </mat-card>
</div>
