import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-admin-panel',
  templateUrl: './admin-panel.component.html',
  styleUrls: ['./admin-panel.component.scss'],
  host: { "(window:resize)": "onWindowResize($event)" }
})
export class AdminPanelComponent implements OnInit {

  isMobile: boolean = false;
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  mobileWidth: number = 1200;

  pages = [
    // {
    //   title: "Podsumowanie",
    //   guid: "/admin-panel/summary",
    //   icon: "leaderboard"
    // },
    {
      title: "Ustawienia",
      guid: "/admin-panel/settings",
      icon: "settings"
    }, {
      title: "Grupy",
      guid: "/admin-panel/groups",
      icon: "group"
    }, {
      title: "Produkty",
      guid: "/admin-panel/products",
      icon: "shopping_basket"
    }, {
      title: "Promocje",
      guid: "/admin-panel/sales",
      icon: "monetization_on"
    }, {
      title: "Zamówienia",
      guid: "/admin-panel/orders",
      icon: "receipt"
    },{
      title: "Reklamacje",
      guid: "/admin-panel/reclamations",
      icon: "mood_bad"
    }, {
      title: "Klienci",
      guid: "/admin-panel/clients",
      icon: "recent_actors"
    }, {
      title: "Strony",
      guid: "/admin-panel/pages",
      icon: "web"
    },
    // {
    //   title: "Banery",
    //   guid: "/admin-panel/banners",
    //   icon: "view_carousel"
    // },
    {
      title: "Faktury",
      guid: "/admin-panel/invoices",
      icon: "description"
    },
    //  {
    //   title: "Sitemap",
    //   guid: "/admin-panel/sitemap",
    //   icon: "language"
    // },
    // {
    //   title: "Użytkownicy",
    //   guid: "/admin-panel/admins",
    //   icon: "supervisor_account"
    // }
  ]

  constructor(public auth: AuthService) { }

  ngOnInit(): void {
    this.isMobile = this.width < this.mobileWidth;
  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    this.isMobile = this.width < this.mobileWidth;
  }

}
