import { Component, OnInit } from '@angular/core';
import { AdminsService } from 'src/app/services/admins.service';

@Component({
  selector: 'app-sitemap',
  templateUrl: './sitemap.component.html',
  styleUrls: ['./sitemap.component.scss']
})
export class SitemapComponent implements OnInit {

  constructor(private adminsService: AdminsService) { }
  isLoading:boolean=true
  hasError:boolean=false
  ngOnInit(): void {
    this.adminsService.sitemap().subscribe(res => {
      this.isLoading = false
    },err => {
      this.isLoading = false
      this.hasError = false
    })
  }

}
