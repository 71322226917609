<mat-spinner style="margin: 0px auto;" *ngIf="isLoading"></mat-spinner>
<div *ngIf="!isLoading" style="margin: 1rem;">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let page of pages">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ page.title }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ckeditor [editor]="Editor" [(ngModel)]="page.content"></ckeditor>
      <br>
      <button mat-raised-button color="accent" (click)="update(page)">
        Zapisz
      </button>
    </mat-expansion-panel>
  </mat-accordion>
</div>
