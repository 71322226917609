<h1 mat-dialog-title>Edycja</h1>
<div mat-dialog-content>
  <mat-form-field class="full-width" appearance="standard">
    <mat-label>Imię</mat-label>
    <input matInput placeholder="Imię" [(ngModel)]="data.firstName" name="first_name" required>
  </mat-form-field>

  <mat-form-field class="full-width" appearance="standard">
    <mat-label>Nazwisko</mat-label>
    <input matInput placeholder="Nazwisko" [(ngModel)]="data.lastName" name="last_name" required>
  </mat-form-field>

  <mat-form-field class="full-width" appearance="standard">
    <mat-label>Firma</mat-label>
    <input matInput placeholder="Firma" [(ngModel)]="data.companyName" name="company_name" required>
  </mat-form-field>

  <mat-form-field class="full-width" appearance="standard">
    <mat-label>Adres</mat-label>
    <input matInput placeholder="Adres" [(ngModel)]="data.address" name="address" required>
  </mat-form-field>

  <mat-form-field class="full-width" appearance="standard">
    <mat-label>Miasto</mat-label>
    <input matInput placeholder="Miasto" [(ngModel)]="data.city" name="city" required>
  </mat-form-field>

  <mat-form-field class="full-width" appearance="standard">
    <mat-label>Kod pocztowy</mat-label>
    <input matInput placeholder="Kod pocztowy" [(ngModel)]="data.postalCode" name="postal_code"
      required>
  </mat-form-field>

  <mat-form-field class="full-width" appearance="standard">
    <mat-label>Numer telefonu</mat-label>
    <input matInput placeholder="Numer telefonu" [(ngModel)]="data.phone" name="phone" autocomplete="tel"
      required>
  </mat-form-field>
</div>
<div mat-dialog-actions>
  <button mat-button (click)="onNoClick()">Przerwij</button>
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Zapisz</button>
</div>
