<!-- NAVBAR -->
<mat-toolbar color="primary">
  <!-- DESKTOP -->
  <mat-toolbar-row *ngIf="!isMobile">
    <a mat-button class="nav-link" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active-link"
      routerLink="/admin-panel">
      <mat-icon class="icon">dashboard</mat-icon>Panel
    </a>

    <a mat-button *ngFor="let page of pages" class="nav-link" routerLinkActive="active-link" routerLink="{{page.guid}}">
      <mat-icon class="icon">{{page.icon}}</mat-icon>{{page.title}}
    </a>

    <span class="spacer"></span>
    <a mat-stroked-button target="_blank" routerLink="/">Przejdź do serwisu</a>&nbsp;
    <button mat-raised-button color="accent" (click)="auth.logoutAdmin()"> Wyloguj </button>
  </mat-toolbar-row>
  <!-- MOBILE  -->
  <mat-toolbar-row *ngIf="isMobile">
    <a mat-button class="nav-link" [routerLinkActiveOptions]="{exact:true}" routerLinkActive="active-link"
      routerLink="/admin-panel">
      <mat-icon class="icon">dashboard</mat-icon>Panel
    </a>
    <span class="spacer"></span>
    <button mat-icon-button [matMenuTriggerFor]="navMenu" aria-label="Example icon-button with a menu"
      *ngIf="isMobile && auth.isAdminLoggedIn()">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #navMenu="matMenu" class="myMenu">
      <button mat-menu-item *ngFor="let page of pages" routerLink="{{page.guid}}">
        <mat-icon class="icon">{{page.icon}}</mat-icon>{{page.title}}
      </button>
      <a mat-menu-item target="_blank" routerLink="/">
        <mat-icon class="icon">web_asset</mat-icon>Przejdź do serwisu
      </a>
      <button mat-menu-item (click)="auth.logoutAdmin()">
        <mat-icon class="icon">exit_to_app</mat-icon>Wyloguj
      </button>
    </mat-menu>
  </mat-toolbar-row>

</mat-toolbar>
<!-- NAVBAR END -->
<div class="scrollable">
  <div class="scrollable-limiter">
    <router-outlet></router-outlet>
  </div>
</div>
