import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ReclamationsService {

  constructor(private http: HttpClient) { }

  getAll(limit?, page?):Observable<any>{
    let params = {}
    if (limit) {
      params['limit'] = limit
    }
    if (page) {
      params['page'] = page
    }
    return this.http.get(environment.apiBaseUrl + "/api/admin/reclamations", { params, observe: 'response' });
  }

  edit(reclamation):Observable<any>{
    return this.http.put(environment.apiBaseUrl + "/api/admin/reclamations/"+reclamation.id, reclamation)
  }

  addClientComment(comment){
    return this.http.post(environment.apiBaseUrl + "/api/clients/reclamationComments/", comment)
  }

  addAdminComment(comment){
    return this.http.post(environment.apiBaseUrl + "/api/admin/reclamationComments/", comment)
  }
}
