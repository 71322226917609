<mat-spinner
    style="margin: 0px auto;"
    *ngIf="loadingImages || loadingGroup"
  ></mat-spinner>
<div style="display: flex; flex-direction: row; flex-wrap: wrap;">
  <div *ngIf="!loadingImages && !loadingGroup" style="flex: 1;  padding: 1rem;">
    <mat-form-field class="full-width">
      <mat-label>Nazwa</mat-label>
      <input matInput [(ngModel)]="group.name" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>guid (Uproszczona nazwa używana w URL)</mat-label>
      <input matInput [(ngModel)]="group.guid" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>SEO tytuł</mat-label>
      <input matInput [(ngModel)]="group.seo_title" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>SEO słowa kluczowe</mat-label>
      <input matInput [(ngModel)]="group.seo_keywords" />
    </mat-form-field>
    <mat-form-field class="full-width">
      <mat-label>SEO opis</mat-label>
      <input matInput [(ngModel)]="group.seo_description" />
    </mat-form-field>
    <div style="width: 99%;">
      <ckeditor [editor]="Editor" [(ngModel)]="group.description"></ckeditor>
    </div>

    <br />
    <button mat-raised-button color="primary" (click)="update()">Zapisz</button>
    &nbsp;
    <button mat-raised-button color="accent" routerLink="/admin-panel/groups">
      Powrót do grup
    </button>
  </div>

  <!-- ZDJECIA -->
  <div *ngIf="!loadingImages && !loadingGroup" style="flex: 1; padding: 1rem;">
    <p>Zdjęcie grupy</p>
    <img
      style="width: 128px; height: 128px;"
      [src]="imgUrl + this.group.icon"
    />

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Zdjęcia produktów w grupie
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="productIcons">
          <div
            *ngFor="let product of group.products"
            (click)="selectIcon(product.icon)"
            [ngStyle]="{
              border:
                this.group.icon == product.icon
                  ? '2px solid orange'
                  : '2px solid white'
            }"
          >
            <img
              [src]="imgUrl + product.icon"
              style="width: 64px; height: 64px;"
            />
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Zdjęcia na serwerze
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="productIcons">
          <div
            *ngFor="let image of images"
            (click)="selectIcon('/assets/media/glowne_grupy/' + image)"
            [ngStyle]="{
              border:
                this.group.icon == '/assets/media/glowne_grupy/' + image
                  ? '2px solid orange'
                  : '2px solid white'
            }"
          >
            <img
              [src]="imgUrl + '/assets/media/glowne_grupy/' + image"
              style="width: 96px; height: 96px;"
              /><br>
              <div style="width: 96px; font-size: 15px; line-height: 14px; overflow-wrap: break-word;">{{image}}</div>
          </div>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
    <br />
    <button color="primary" mat-raised-button (click)="fileInput.click()">
      Dodaj zdjęcie
    </button>
    <br />
    <div *ngIf="uploadUrl">
      <img
        class="preview"
        style="width: 128px; height: 128px;"
        [src]="uploadUrl"
      />
      <br>
      <button color="primary" mat-raised-button (click)="uploadPhoto()">
        Prześlij
      </button>
    </div>
    <input
      hidden
      (change)="onFileSelected($event)"
      #fileInput
      type="file"
      id="file"
    />
  </div>
</div>
