import { Component, OnInit, Inject } from "@angular/core";
import { ReclamationsService } from "src/app/services/reclamations.service";
import { Router } from "@angular/router";
import { PageEvent } from '@angular/material/paginator';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: "app-reclamations",
  templateUrl: "./reclamations.component.html",
  styleUrls: ["./reclamations.component.scss"],
})
export class ReclamationsComponent implements OnInit {
  reclamations = [];
  isLoading = true;

  length = 100;
  pageSize = 15;
  pageSizeOptions = [5, 10, 15, 20, 30, 50, 100];
  pageIndex = 0;
  pageEvent: any;

  constructor(
    private reclam: ReclamationsService,
    private router: Router,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.loadReclamations();
  }

  loadReclamations(event?) {
    this.pageSize = event ? event.pageSize : this.pageSize;
    this.pageIndex = event ? event.pageIndex : this.pageIndex;

    this.isLoading = true;
    this.reclam.getAll(this.pageSize, this.pageIndex).subscribe((res) => {
      this.reclamations = res.body;
      let i = 0;
      this.reclamations.forEach(el=>{
        this.reclamations[i]["recCommentsLength"] = el.reclamation_comments.length;
        i++
      })
      this.length = res.headers.get("X-Total-Count");
      this.isLoading = false;
      console.log(this.reclamations);
    });

    this.router.navigate([], {
      queryParams: { pageSize: this.pageSize, pageIndex: this.pageIndex },
    });
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action,{
      duration: sbDuration * 1000,
    });
  }

  openRecComment(id) {
    const dialogRef = this.dialog.open(RecComment, {
      width: "75%",
      data: { reclamation: this.reclamations[id]},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        let newReclamationComment = {};
        newReclamationComment["author"] = "Serwis";
        newReclamationComment["reclamationId"] = this.reclamations[id].id
        newReclamationComment["comment"] = result
        this.reclam.addAdminComment(newReclamationComment).subscribe(res=>{
          this.openSnackBar("Zapisano", "OK", 10)
          this.ngOnInit()
        }, err=>{
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        });
      }
    });
  }
}

@Component({
  selector: "rec-comment",
  templateUrl: "rec-comment.html",
})
export class RecComment implements OnInit {
  isThereAComment = false;
  newComment = "";
  constructor(
    public dialogRef: MatDialogRef<RecComment>,
    @Inject(MAT_DIALOG_DATA) public data: { reclamation: any }
  ) {}
  ngOnInit() {
    // console.log(this.data)
    if(this.data.reclamation.reclamation_comments.length>0){
     this.isThereAComment = true;
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
