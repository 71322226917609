import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BannersService } from 'src/app/services/banners.service';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    host: { "(window:resize)": "onWindowResize($event)" }
})
export class HomeComponent implements OnInit {

    loginData = {
        email: "",
        password: ""
    }
    environment=environment
    isMobile: boolean = false;
    isSmallLogoShown: boolean = false;
    width: number = window.innerWidth;
    height: number = window.innerHeight;
    mobileWidth: number = 760;
    waitingForResponse = false;
    carouselBanner = {}
    areBannersLoading = true;

    banners: any = []

    pages = [
        {
            title: "Produkty",
            guid: "/strona/produkty"
        }, {
            title: "Regulamin",
            guid: "/strona/regulamin"
        }, {
            title: "Promocje",
            guid: "/strona/promocje"
        }, {
            title: "Pomoc",
            guid: "/strona/pomoc"
        }, {
            title: "Kontakt",
            guid: "/strona/kontakt"
        }
    ]

    constructor(public auth: AuthService, private router: Router, private _snackBar: MatSnackBar, private bannersService:BannersService) { }

    ngOnInit(): void {
        this.isMobile = this.width < this.mobileWidth;
        this.isSmallLogoShown = this.width < 400;
        this.carouselBanner = {
            grid: { xs: 1, sm: 1, md: 1, lg: 1, all: 0 },
            load: 4,
            interval: { timing: 3000, initialDelay: 0 },
            loop: true,
            touch: true,
            point: {visible: false},
            velocity: 0.2,
        };
        this.bannersService.getBanners().subscribe(res=>{
          this.banners = res;
          this.areBannersLoading = false;
        })
    }

    openSnackBar(message: string, action: string, sbDuration?: number) {
        this._snackBar.open(message, action, {
            duration: sbDuration * 1000,
        });
    }

    login() {
        this.waitingForResponse = true;
        this.auth.loginUser("/api/clients/login/", this.loginData).subscribe(res => {
            localStorage.setItem('clientToken', "bearer " + res.token)
            this.router.navigate(["/strona/moje_konto"])
            this.waitingForResponse = false;
        }, err => {
            this.waitingForResponse = false;
            //TODO: zmienic na polski komunikat
            this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
    }

    onWindowResize(event) {
        this.width = event.target.innerWidth;
        this.height = event.target.innerHeight;
        this.isMobile = this.width < this.mobileWidth;
        this.isSmallLogoShown = this.width < 400;
    }

}
