import { Component, OnInit } from '@angular/core';
import { SettingsService } from 'src/app/services/settings.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class AdminSettingsComponent implements OnInit {

  constructor(private settingsService: SettingsService, private _snackBar: MatSnackBar) { }

  //dane ustawień
  settings: any = {
    ogolne: [],
    seo: [],
    mail: [],
    faktura: [],
    nadawca: [],
  }
  margins: any
  shippings: any
  templates: any
  //widok
  newMarginActive: boolean = false
  newShippingActive: boolean = false
  deleteButtonsActive: boolean = false
  //nowe progi
  newMargin: any = {
    rate: '',
    min: '',
    max: ''
  }
  newShipping: any = {
    threshold: '',
    price: ''
  }

  toggleNewMargin() {
    this.newMarginActive = !this.newMarginActive
  }
  toggleNewShipping() {
    this.newShippingActive = !this.newShippingActive
  }
  toggleDeleteButtons() {
    this.deleteButtonsActive=!this.deleteButtonsActive
  }

  ngOnInit(): void {
    this.settingsService.getSettingsGroup('ogolne').subscribe(res => {
      this.settings.ogolne = res
    })
    this.settingsService.getSettingsGroup('seo').subscribe(res => {
      this.settings.seo = res
    })
    this.settingsService.getSettingsGroup('mail').subscribe(res => {
      this.settings.mail = res
    })
    this.settingsService.getSettingsGroup('faktura').subscribe(res => {
      this.settings.faktura = res
    })
    this.settingsService.getSettingsGroup('nadawca').subscribe(res => {
      this.settings.nadawca = res
    })

    this.settingsService.getMargins().subscribe(res => {
      this.margins = res
    })
    this.settingsService.getShippings().subscribe(res => {
      this.shippings = res
    })
    this.settingsService.getTemplates().subscribe(res => {
     // console.log(res)
      this.templates = res
    })

  }

  updateGroup(group) {
    this.settingsService.updateGroup(this.settings[group]).subscribe(res => {
      this.openSnackBar("Ustawienia zaktualizowane pomyślnie", "OK", 3)
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  updateMargins() {

    this.settingsService.updateMargins(this.margins).subscribe(res => {
      if (this.newMarginActive) {
        this.settingsService.postMargin(this.newMargin).subscribe(res => {
          this.openSnackBar("Dodano nowy próg oraz zaktualizowano ustawienia pomyślnie", "OK", 3)
          this.toggleNewMargin()
          this.ngOnInit();

        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
      } else {
        this.openSnackBar("Ustawienia zaktualizowane pomyślnie", "OK", 3)
      }

    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  updateShippings() {
    this.settingsService.updateShippings(this.shippings).subscribe(res => {

      if (this.newShippingActive) {
        this.settingsService.postShipping(this.newShipping).subscribe(res => {
          this.openSnackBar("Dodano nowy próg oraz zaktualizowano ustawienia pomyślnie", "OK", 3)
          this.toggleNewShipping()
          this.ngOnInit();

        }, err => {
          this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
        })
      } else {
        this.openSnackBar("Ustawienia zaktualizowane pomyślnie", "OK", 3)
      }
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  updateTemplate(template){
    this.settingsService.updateTemplate(template).subscribe(res => {
      this.openSnackBar("Szablon zaktualizowany pomyślnie", "OK", 3)
    }, err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  removeMargin(id) {
    this.settingsService.deleteMargin(id).subscribe(res => {
      this.openSnackBar("Próg usunięty", "OK", 3)
      this.ngOnInit();
    },err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  removeShipping(id) {
    this.settingsService.deleteShipping(id).subscribe(res => {
      this.openSnackBar("Próg usunięty", "OK", 3)
      this.ngOnInit();
    },err => {
      this.openSnackBar(err.status != 0 ? err.error.message : "Serwer chwilowo niedostępny", "OK", 10)
    })
  }

  openSnackBar(message: string, action: string, sbDuration?: number) {
    this._snackBar.open(message, action, {
      duration: sbDuration * 1000,
    });
  }

}
