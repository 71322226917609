<div *ngIf="isLoadingComments || isLoadingOrder">
  <mat-spinner style="margin: 0px auto;"></mat-spinner>
</div>
<div *ngIf="!isLoadingComments && !isLoadingOrder && hasError">
  <h2>Nie można załadować zamówienia</h2>
</div>
<div *ngIf="!isLoadingComments && !isLoadingOrder && !hasError" style="padding: 1rem;">
  <h2 mat-dialog-title>
    Szczegóły zamówienia numer: {{order.nr}}/{{order.client_id| leadWithZeros: 3}}
  </h2>

  <div mat-dialog-content>
    <div class="flexable">
      <!--  -->
      <div style="flex: 1; ">
        <p style="font-weight: 500;">
          Produkt: {{order.main_product_name}}<br /> Nakład: {{order.naklad}} szt.
        </p>

        <p>
          <span *ngFor="let param of paramsArray">
            {{param}}: {{parmezan[param]}}<br /></span>
        </p>
        <p style="font-weight: 500;">Daty:</p>
        <p>Zamówienia: {{order.createdAt | date:'yyyy-MM-d HH:mm'}}</p>
        <p>
          Ostatniej aktualizacji: {{order.updatedAt | date:'yyyy-MM-d HH:mm'}}
        </p>
        <p>
          Termin realizacji: {{order.result.daty["termin realizacji"] | date:'yyyy-MM-d HH:mm'}}
        </p>
        <p>
          Planowana data wysyłki: {{order.result.daty["Planowana data wysylki"] | date:'yyyy-MM-d HH:mm'}}
        </p>
        <p *ngIf="order.result.daty['akceptacja cf']">
          Akceptacja CF: {{ order.result.daty["akceptacja cf"] | date:'yyyy-MM-d HH:mm'}}
        </p>
        <p *ngIf="order.result.daty['akceptacja partner']">
          Akceptacja partner: {{order.result.daty["akceptacja partner"] | date:'yyyy-MM-d HH:mm'}}
        </p>
        <p>
          Akceptacja pliku (klienta): {{order.result.daty["akceptacja pliku"] | date:'yyyy-MM-d HH:mm'}}
        </p>

        <br />

      </div>
      <!--  -->
      <div style="flex: 1;">
        <p style="font-weight: 500;">Zamawiający:</p>
        <p>{{order.client.company_name}}<br>{{order.client.first_name}} {{order.client.last_name}}</p>
        <p>email: {{order.client.email}}</p>
        <p>tel: {{order.client.phone}}</p>
        <p style="font-weight: 500;">Wysyłka:</p>
        <p>
          Adres dostawy:<br /> {{order.adresat_firma}}
          <br /> {{order.adresat_nazwisko}} {{order.adresat_imie}}<br /> {{order.adresat_ulica}},
          {{order.adresat_kod_pocztowy}} {{order.adresat_miasto}}
          <br /> Telefon: {{order.adresat_telefon}}
        </p>

        <p>Status: {{order.result.adresy[0].status}}</p>
        <p>
          Planowana data wysylki: {{order.result.daty["Planowana data wysylki"] | date:'yyyy-MM-d HH:mm'}}
        </p>
        <p>
          Data nadania: {{order.result.adresy[0].data_nadania | date:'yyyy-MM-d HH:mm'}}
        </p>
        <p>Nr paczki: {{order.result.adresy[0].package_number}}</p>
        <p *ngIf="order.sledzenie"><a href="{{order.sledzenie}}">Śledź paczkę</a></p>

      </div>
      <!--  -->
      <div style="flex: 1; ">

        <p style="font-weight: 500;">ID w DK: {{order.order_dk_id}}</p>
        <p style="font-weight: 500;">Koszt:</p>
        <p>Cena produktu: {{order.result.koszty['cena produktu'] | currency:"PLN"}}</p>
        <p>Koszt dostawy: {{order.result.koszty['koszt_dostawy'] | currency:"PLN"}}</p>
        <p style="font-weight: 500;">Cena:</p>
        <p>Wartość netto: {{order.cena | currency:"PLN"}}</p>
        <p>
          Wartość brutto: {{(order.cena * (1 + (order.vat>0?(order.vat/100):(23/100)))) | currency:"PLN"}}
        </p>
        <p>Zysk:
          {{order.cena - order.result.koszty['cena produktu'] - order.result.koszty['koszt_dostawy'] | currency:"PLN"}}
        </p>
        <p style="font-weight: 500;">Statusy:</p>
        <p>Status zamówienia: {{order.status}}</p>
        <p>Status pliku: {{order.result.status_pliku}} </p>
        <p>Status płatności: {{order.status_platnosci}} </p>
        <p>Status druku: {{order.result.status.name}}</p>
        <p>Status wysyłki: {{order.result.adresy[0].status}}</p>
      </div>
    </div>
    <button mat-raised-button color="primary" style="margin-bottom: 1rem;" (click)="goToClientJS()">
      Pliki zamówienia
    </button>
    <br>
    <button *ngIf="order.reclamation.id" mat-raised-button color="accent" style="margin-bottom: 1rem;"
      (click)="openRecComment()">
      Reklamacja
    </button>
    <!-- Sprawdzanie czy są previewy -->
    <div *ngIf="order.result?.previews.Strony">
      <div *ngIf="order.result?.previews.length==undefined && order.result?.previews">
        <div *ngFor="let key of Object.keys(order.result.previews.Strony)">
          <a href="{{environment.apiBaseUrl}}/printApi{{order.result.previews.Strony[key].img}}" target="_blank"><img
              src="{{environment.apiBaseUrl}}/printApi{{order.result.previews.Strony[key].thumb}}" /></a>
        </div>
      </div>
    </div>
    <div *ngIf="order.result?.previews['Okładka']">
      <!-- Sprawdzanie czy są previewy -->
      <div>
        <div>
          <a href="{{environment.apiBaseUrl}}/printApi{{order.result.previews['Okładka']['1'].img}}"
            target="_blank"><img
              src="{{environment.apiBaseUrl}}/printApi{{order.result.previews['Okładka']['1'].thumb}}" /></a>
        </div>
      </div>
    </div>
    <!-- Surowe dane -->
    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Surowe dane z drukomatu
        </mat-expansion-panel-header>
        <pre>

            {{(this.order.result | json).trim()}}

        </pre>
      </mat-expansion-panel>
    </mat-accordion>
    <br>
    <!-- Komentarze -->
    <div style="margin: 1rem;">
      <h2>Komentarze do zamówienia ({{comments.length}})</h2>
      <hr />
      <div style="display: flex; flex-direction: column;">
        <div *ngFor="let comment of comments">
          <div>
            <p>
              {{comment.time | date:'yyyy-MM-d HH:mm'}}<br /> {{comment.comment}}
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>

  <button mat-raised-button color="accent" (click)="_location.back();">
    Powrót do zamówień
  </button>
</div>
